import React from "react";

const FilterChips = ({ selectedFilters }) => {
  return (
    <div className="filter-chips d-flex gap-2 flex-wrap">
      {selectedFilters.country_type?.trim() && (
        <div className="filter-chip d-flex gap-2 mb-2">
          {selectedFilters.country_type?.map((filter) => (
            <div key={filter} className="chip tag-chip">
              {filter}
            </div>
          ))}
        </div>
      )}
      {selectedFilters.category?.trim() && (
        <div className="filter-chip d-flex gap-2 mb-2">
          {selectedFilters.category?.map((filter) => (
            <div key={filter} className="chip tag-chip">
              {filter}
            </div>
          ))}
        </div>
      )}
      {selectedFilters.subcategorylevel2?.trim() && (
        <div className="filter-chip d-flex gap-2 mb-2">
          {selectedFilters.subcategorylevel2?.map((filter) => (
            <div key={filter} className="chip tag-chip">
              {filter}
            </div>
          ))}
        </div>
      )}

      {selectedFilters.subcategorylevel1?.trim() && (
        <div className="filter-chip d-flex gap-2 mb-2">
          {selectedFilters.subcategorylevel1?.map((filter) => (
            <div key={filter} className="chip tag-chip">
              {filter}
            </div>
          ))}
        </div>
      )}

      {selectedFilters.subcategory?.trim() && (
        <div className="filter-chip d-flex gap-2 mb-2">
          {selectedFilters.subcategory?.map((filter) => (
            <div key={filter} className="chip tag-chip">
              {filter}
            </div>
          ))}
        </div>
      )}

      {selectedFilters.searchTerm?.trim() && (
        <div className="filter-chip d-flex gap-2 mb-2">
          <div className="chip tag-chip">{selectedFilters.searchTerm}</div>
        </div>
      )}
    </div>
  );
};

export default FilterChips;
