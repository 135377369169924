import Masonry from "@mui/lab/Masonry";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import React from "react";

const Label = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  background: "transparent",
  color: "#fff",
  border: "none",
  boxShadow: "none",
  backdropFilter: "blur(3px)",
}));

const isEmbedUrl = (url) => {
  // Example check for YouTube embed URL
  return url.includes("youtube.com/embed") || url.includes("vimeo.com");
};

const VideoGallery = ({ videoData }) => {
  const hasVideo = videoData?.some(item => item?.url);

  return (
    <Box sx={{ minHeight: 400 }}>
      {hasVideo ? (
        <Masonry columns={2} spacing={2}>
          {videoData?.map((item, index) => (
            <div key={index} className="position-relative">
              {isEmbedUrl(item?.url) ? (
                <iframe
                  src={item?.url}
                  title={item?.field_name || "Video"}
                  loading="lazy"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="d-block w-100 h-100 rounded-1 border-secondary border-3 border"
                  style={{ aspectRatio: '16/9' }} // Maintain aspect ratio
                />
              ) : (
                <video
                  src={item?.url}
                  alt={item?.field_name || "Video"}
                  title={item?.field_name}
                  loading="lazy"
                  controls
                  className="d-block w-100 h-100 rounded-1 border-secondary border-3 border"
                />
              )}
              {item?.field_name && <Label>{item?.field_name}</Label>}
            </div>
          ))}
        </Masonry>
      ) : (
        <p>No Videos Found</p>
      )}
    </Box>
  );
};

export default VideoGallery;
