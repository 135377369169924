import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Check2Circle } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../API/API";
import { NetworkErrorAlert } from "../infoPopup/infoPopup";

const ConfigCloneModal = ({ show, onHide, configData }) => {
  const navigate = useNavigate();
  const [reqLoading, setReqLoading] = useState(false);
  const [formData, setFormData] = useState({
    host: "",
    logo_url: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setReqLoading(true);

    // Extract necessary data from the original config
    const { _id, host, ...originalData } = configData;

    // Create new config data with updated host and logo
    const { show_hero_slider, section_html, ...commonAliases } =
      originalData?.hostsettings?.common?.nameAlias || {};

    const data = {
      ...originalData,
      host: formData.host,
      type: originalData.type,
      userId: originalData.userId,
      appsettings: {},
      hostsettings: {
        title: commonAliases?.title,
        name: commonAliases?.name,
        logo: formData.logo_url, // Use the new logo URL
        link: commonAliases?.link,
        favicon: commonAliases?.favicon,
        header: {
          logo: formData.logo_url, // Use the new logo URL
          navItems: originalData?.hostsettings?.header?.navItems || [],
        },
        common: {
          defaultRegisterForm: commonAliases?.defaultRegisterForm,
          nameAlias: {
            ...commonAliases,
            logo: formData.logo_url, // Use the new logo URL
          },
          dynamicUrls: originalData?.hostsettings?.common?.dynamicUrls || [],
          customfields: originalData?.hostsettings?.common?.customfields || [],
          enums: originalData?.hostsettings?.common?.enums || {},
        },
        footer: {
          logo: formData.logo_url, // Use the new logo URL
          fromEmail: commonAliases?.fromEmail,
          adminEmail: commonAliases?.adminEmail,
          supportEmail: commonAliases?.supportEmail,
        },
      },
      pagesettings: originalData?.pagesettings || {},
    };

    API("post", `configs/new`, data)
      .then(() => {
        successAlert();
        onHide();
        navigate("/super-admin-dashboard/config-management");
      })
      .catch((error) => {
        if (error?.message === "Network Error") {
          NetworkErrorAlert(error?.message);
        } else {
          toast.error(error?.response?.data || "Error cloning config", {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
        }
      })
      .finally(() => setReqLoading(false));
  };

  const successAlert = () => {
    confirmAlert({
      customUI: () => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center my-2 text-capitalize">
              Config cloned successfully
            </h5>
          </div>
        );
      },
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="h4 theme-text-2"
        >
          Clone Config
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {reqLoading ? (
          <>
            <span className="register-loader"> </span>
            <span className="spinnerOverlay" />
          </>
        ) : null}

        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label className="mb-0">
              Host/Domain Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="host"
              value={formData.host}
              onChange={handleInputChange}
              placeholder="Enter host/domain name"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">
              Logo URL <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="logo_url"
              value={formData.logo_url}
              onChange={handleInputChange}
              placeholder="Enter logo URL"
              required
            />
          </Form.Group>

          <div className="d-flex justify-content-end gap-2 mt-4">
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button type="submit" className="add-button">
              Clone Config
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ConfigCloneModal;
