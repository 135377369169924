import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwtDecode from "jwt-decode";
import * as Actions from "../../constants/baseUrl";
import { getCookie } from "../../utils/cookies";

export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async () => {
    const token = getCookie("token");
    const tokenDetails = token ? jwtDecode(token) : null;
    const myUserId = tokenDetails?.Email;

    if (!myUserId) throw new Error("User ID is not available");

    const response = await axios.get(
      `${Actions.BASE_URL}users/email/${myUserId}`
    );
    return response?.data || {};
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: {},
    status: "idle", // or "loading", "succeeded", "failed"
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchUserData.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectUserData = (state) => state.user.data;

export default userSlice.reducer;
