import axios from "axios";
import React, { useState } from "react";
import { Button, Form, FormLabel, Offcanvas } from "react-bootstrap";
import { Send } from "react-bootstrap-icons";
import ThemeInpGroup from "../homeSegments/ThemeInpGroup";
import "./codeOffcanvas.css";
import { v4 as uuid } from "uuid";

const CodeGenerationOffcanvas = ({
  show,
  onHide,
  onInsert,
  generateJson = false,
}) => {
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e, input) => {
    e.preventDefault();

    const data = {
      id: uuid(),
      name: "prompt",
      host: window.location.hostname,
      prompt: input,
    };

    setLoading(true);
    setResponse("");
    try {
      const res = await axios.post(
        generateJson
          ? "https://intellide-openai.onrender.com/api/pages/schema"
          : "https://intellide-openai.onrender.com/api/pages",
        data
      );
      setResponse(
        typeof res.data === "object"
          ? JSON.stringify(res.data, null, 2)
          : res.data
      );
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleInsert = () => {
    if (!response || loading) return;
    onInsert(response);
    // setResponse()
    onHide();
  };

  return (
    <Offcanvas
      show={show}
      onHide={onHide}
      placement="end"
      className="code-generation-offcanvas chatAI"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="h3">Code Generation</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="chat-container pt-3">
        <ThemeInpGroup
          textarea
          handleSubmit={handleSubmit}
          icon={<Send />}
          inputPlaceholder={"Enter prompt to generate"}
          loading={loading}
        />
        <FormLabel className="mt-3">Response</FormLabel>
        <Form.Control
          value={loading ? "Generating..." : response}
          onChange={(e) => setResponse(e.target.value)}
          style={{ height: "400px", backgroundColor: "transparent" }}
          rows={16}
          readOnly={!response || loading}
          disabled={loading}
          className="mb-3"
          as={"textarea"}
        />
        <div className="d-flex justify-content-end gap-2">
          <Button
            className="px-4 d-block bg-white text-danger text-danger border-danger rounded-0"
            onClick={onHide}
          >
            Close
          </Button>
          <Button
            className="add-button px-4 d-block bg-white theme-text"
            onClick={handleInsert}
            readOnly={!response || loading}
          >
            Insert Response
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CodeGenerationOffcanvas;
