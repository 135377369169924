import jwt from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useLocation } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import HttpHelper from "../../helper/httphelper";
import { setCookie } from "../../utils/cookies";

function LoginSuccess() {
  const auth = useAuth();

  const [claimsIndo, setClaimsInfo] = useState("claim info not received");
  const [greeting, setGreeting] = useState("not logged in");
  const [userinfo, setUserInfo] = useState("user info not set");
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const jwtVal = searchParams.get("token");

    const helper = new HttpHelper();
    const checkAuthUrl = `${Actions.AUTH_URL}identity`;

    if (jwtVal != null && jwtVal !== "" && jwtVal !== undefined) {
      const user = jwt(jwtVal);
      // Set token in cookie instead of localStorage
      setCookie("token", jwtVal, { expires: 7, secure: true });
      localStorage.setItem("isValidUser", true);
      setUserName(user.FullName);
      if (user != null && user !== undefined) {
        setIsLoggedIn(true);
        setLoading(false);
        window.location.href = "/my-profile";
      }
    }
    if (
      (jwtVal === undefined || jwtVal === "undefined") &&
      auth.isAuthenticated
    ) {
      document.cookie = `IdentiTeamToken=${auth.user?.access_token}`;
      document.cookie = `IdentiTeamRefreshToken=${auth.user?.refresh_token}`;
    }

    helper
      .fetchJson(checkAuthUrl, "GET", "", document.cookie)
      .then((checkAuthResponse) => {
        if (!checkAuthResponse?.ok) {
          return;
        }
        setIsLoggedIn(true);
        setGreeting("You are logged in");
        checkAuthResponse.json().then((jsr) => {
          setUserInfo(JSON.stringify(jsr));
          setClaimsInfo(JSON.stringify(jsr));
        });
      });
  }, []);

  return (
    <div className="text-center pt-5 mt-5">
      {loading ? (
        <span className="register-loader position-relative d-block mb-5" />
      ) : (
        <>
          {isLoggedIn ? (
            <>
              <span className="register-loader position-relative d-block mb-5" />
              <h5 className="theme-text text-capitalize">Hi {userName},</h5>
              <p>Please wait until we navigate you...</p>
            </>
          ) : (
            <>
              <h5 className="theme-danger text-capitalize">login failed</h5>
              <p>Please try after sometime.</p>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default LoginSuccess;
