import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import {
  Facebook,
  Globe,
  Instagram,
  Link45deg,
  Linkedin,
  Twitter
} from "react-bootstrap-icons";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TextFormatter from "../../components/textFormat/textFormat";

const ItemInfo = ({ itemData }) => {
  const startupsAlias = SubDomainRouter("startupsAlias");

  return (
    <Card className="border-top-0 rounded-top-0">
      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2 text-capitalize">
          {startupsAlias ? startupsAlias : "demo"} Details:
        </h5>
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              Company Name
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.company_name || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              Registered Company Name
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.registered_company_name || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              Description
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.description ? (
                <TextFormatter text={itemData?.description} />
              ) : (
                "Not Available"
              )}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              year of establishment
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.year_of_establishment || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              Address
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.state || "not Available"},{" "}
              {itemData?.city || "not Available"},&nbsp;
              {itemData?.country || "not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              company stage
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.company_stage || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              industry
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.industry || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              sector
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.sector || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              team size
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.team_size || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              principle currency
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.principle_currency || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              sales channels
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 d-flex align-items-center gap-2">
              {itemData?.sales_channels !== "" &&
                itemData?.sales_channels?.map((channel, index) => (
                  <h3 className="tag-chip p-1" key={index}>
                    {channel}
                  </h3>
                ))}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              marketing activities
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 d-flex align-items-center gap-2">
              {itemData?.marketing_activities !== "" &&
                itemData?.marketing_activities?.map((activity, index) => (
                  <h3 className="tag-chip p-1" key={index}>
                    {activity}
                  </h3>
                ))}
            </Card.Text>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="card-text text-muted mb-0 d-flex align-items-center gap-2">
              {itemData?.tags !== "" &&
                itemData?.tags?.map((tag, index) => (
                  <h3 className="tag-chip" key={index}>
                    #{tag}
                  </h3>
                ))}
            </div>
          </Col>
        </Row>
        <hr />
      </Card.Body>
      {itemData?.problem_description && (
        <Card.Body>
          <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2 text-capitalize">
            Problems Tackled
          </h5>
          <hr />
          <Row>
            <Col md={3}>
              <Card.Text className="mb-0 fw-bold text-capitalize">
                Problem
              </Card.Text>
            </Col>
            <Col md={9}>
              <Card.Text className="text-muted mb-0 d-flex align-items-center gap-2">
                {itemData?.problem_description || "not Available"}
              </Card.Text>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={3}>
              <Card.Text className="mb-0 fw-bold text-capitalize">
                Solution
              </Card.Text>
            </Col>
            <Col md={9}>
              <Card.Text className="text-muted mb-0 d-flex align-items-center gap-2">
                {itemData?.problem_solution || "not Available"}
              </Card.Text>
            </Col>
          </Row>
        </Card.Body>
      )}
      {itemData?.social_links?.length > 0 && (
        <Card.Body>
          <Row>
            <Col md={3}>
              <h6 className="theme-text-2 text-capitalize">Follow us:</h6>
            </Col>
            <Col
              md={9}
              className="social d-flex gap-md-3 gap-2 flex-wrap align-items-center"
            >
              {itemData?.social_links?.map((social, index) => (
                <>
                  {social.linkedIn && (
                    <a
                      key={index}
                      target="_blank"
                      href={`${social.linkedIn}`}
                      rel="noreferrer"
                    >
                      <Linkedin />
                    </a>
                  )}
                  {social.facebook && (
                    <a
                      key={index}
                      target="_blank"
                      href={`${social.facebook}`}
                      rel="noreferrer"
                    >
                      <Facebook />
                    </a>
                  )}
                  {social.instagram && (
                    <a
                      key={index}
                      target="_blank"
                      href={`${social.instagram}`}
                      rel="noreferrer"
                    >
                      <Instagram />
                    </a>
                  )}
                  {social.twitter && (
                    <a
                      key={index}
                      target="_blank"
                      href={`${social.twitter}`}
                      rel="noreferrer"
                    >
                      <Twitter />
                    </a>
                  )}
                  {social.website && (
                    <a
                      key={index}
                      target="_blank"
                      href={`${social.website}`}
                      rel="noreferrer"
                    >
                      <Globe />
                    </a>
                  )}
                  {social.others && (
                    <a
                      key={index}
                      target="_blank"
                      href={`${social.others}`}
                      rel="noreferrer"
                    >
                      <Link45deg />
                    </a>
                  )}
                </>
              ))}
            </Col>
          </Row>
        </Card.Body>
      )}
    </Card>
  );
};

export default ItemInfo;
