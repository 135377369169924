import React from "react";
import { Accordion, Container, Nav } from "react-bootstrap";
import {
  Bezier2,
  FileText,
  Link45deg,
  PeopleFill,
  PersonVcardFill,
  Robot,
  ShieldFillCheck,
  ShieldLockFill,
  Upload,
} from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { BiLastPage, BiSolidCameraHome } from "react-icons/bi";
import { Link, Outlet, useLocation } from "react-router-dom";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import useUserData from "../../components/useUserDetails/useUserDetails";
import "./admin.css";

const AdminPage = () => {
  const location = useLocation();

  const siteName = SubDomainRouter("title");
  const userData = useUserData();
  const role = userData.role;

  return (
    <>
      <Helmet>
        <title>Admin | {siteName ? siteName : window.location.host}</title>
      </Helmet>

      <div className="mt-4 pt-3 dashboardSec admin">
        <div className="d-flex flex-md-row flex-column">
          <div className="dashboard-nav px-0 py-md-5">
            <Accordion defaultActiveKey={["0"]} className="nav-item" flush>
              <Accordion.Item eventKey="0" className="rounded-0 border-end-0">
                <Accordion.Header className="p-0 rounded-0">
                  <span className="nav-link my-0">Config Management</span>
                </Accordion.Header>
                <Accordion.Body className="px-0 pt-0">
                  <Nav.Item>
                    <Link
                      to="/admin/configs"
                      className={`nav-link d-flex text-capitalize align-items-center gap-2 ${
                        location.pathname === "/admin/configs" && "active"
                      }`}
                    >
                      <ShieldLockFill /> Config
                    </Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Link
                      to="/admin/step-admin-configs"
                      className={`nav-link d-flex text-capitalize align-items-center gap-2 ${
                        location.pathname === "/admin/step-admin-configs" &&
                        "active"
                      }`}
                    >
                      <ShieldFillCheck /> Edit Config Wizard
                    </Link>
                  </Nav.Item>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1" className="rounded-0 border-end-0">
                <Accordion.Header className="p-0 rounded-0">
                  <span className="nav-link my-0">Users Management</span>
                </Accordion.Header>
                <Accordion.Body className="px-0 pt-0">
                  <Nav.Item>
                    <Link
                      to="/admin/users"
                      className={`nav-link d-flex text-capitalize align-items-center gap-2 ${
                        location.pathname === "/admin/users" && "active"
                      }`}
                    >
                      <PeopleFill /> Users
                    </Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Link
                      to="/admin/registrants"
                      className={`nav-link d-flex text-capitalize align-items-center gap-2 ${
                        location.pathname === "/admin/registrants" && "active"
                      }`}
                    >
                      <PersonVcardFill /> Registrants
                    </Link>
                  </Nav.Item>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2" className="rounded-0 border-end-0">
                <Accordion.Header className="p-0 rounded-0">
                  <span className="nav-link my-0">Tools</span>
                </Accordion.Header>
                <Accordion.Body className="px-0 pt-0">
                  <Nav.Item>
                    <Link
                      to="/admin/content-generator"
                      className={`nav-link d-flex text-capitalize align-items-center gap-2 ${
                        location.pathname === "/admin/content-generator" &&
                        "active"
                      }`}
                    >
                      <Robot /> Content Generator
                    </Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Link
                      to="/admin/upload"
                      className={`nav-link d-flex text-capitalize align-items-center gap-2 ${
                        location.pathname === "/admin/upload" && "active"
                      }`}
                    >
                      <Upload /> Uploader
                    </Link>
                  </Nav.Item>
                </Accordion.Body>
              </Accordion.Item>

              {["siteadmin", "superadmin"].includes(role) && (
                <Accordion.Item eventKey="3" className="rounded-0 border-end-0">
                  <Accordion.Header className="p-0 rounded-0">
                    <span className="nav-link my-0">Site Admin</span>
                  </Accordion.Header>
                  <Accordion.Body className="px-0 pt-0">
                    <Nav.Item>
                      <Link
                        to="/admin/pages"
                        className={`nav-link d-flex text-capitalize align-items-center gap-2 ${
                          location.pathname === "/admin/pages" && "active"
                        }`}
                      >
                        <BiLastPage /> Pages
                      </Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Link
                        to="/admin/homepages"
                        className={`nav-link d-flex text-capitalize align-items-center gap-2 ${
                          location.pathname === "/admin/homepages" && "active"
                        }`}
                      >
                        <BiSolidCameraHome /> Homepages
                      </Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Link
                        to="/admin/schemas"
                        className={`nav-link d-flex text-capitalize align-items-center gap-2 ${
                          location.pathname === "/admin/schemas" && "active"
                        }`}
                      >
                        <Bezier2 /> Schemas
                      </Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Link
                        to="/admin/forms"
                        className={`nav-link d-flex text-capitalize align-items-center gap-2 ${
                          location.pathname === "/admin/forms" && "active"
                        }`}
                      >
                        <FileText /> Forms
                      </Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Link
                        to="/admin/api-integrations"
                        className={`nav-link d-flex text-capitalize align-items-center gap-2 ${
                          location.pathname === "/admin/api-integrations" &&
                          "active"
                        }`}
                      >
                        <Link45deg /> API Integrations
                      </Link>
                    </Nav.Item>
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>
          </div>
          <Container fluid="lg" className="px-lg-4 pb-5 pt-5 flex-grow-1">
            <Outlet />
          </Container>
        </div>
      </div>
    </>
  );
};

export default AdminPage;
