import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import * as Actions from "../../constants/baseUrl";
import { chosenCities, mainTimeZones } from "../../constants/extras";
import API from "../API/API";
import { ErrorAlert, SuccessAlert } from "../generalMsgModal/Alerts";
import TokenDetails from "../useUserDetails/useTokenDetails";

const RequestAppointment = (props) => {
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [reqLoading, setReqLoading] = useState(false);
  const [formData, setFormData] = useState({
    duration: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    whatsapp: "",
    meeting_title: "",
    start_date: "",
    start_time: "",
  });
  // check values
  const [hasValue, setHasValue] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const [eventTime, setEventTime] = useState("");
  const [eventDate, setEventDate] = useState("");

  const [support, setSupport] = useState({
    first_name: "",
    last_name: "",
    email: "",
    participant_type: "support",
  });

  useEffect(() => {
    setSupport({
      ...support,
      first_name: props?.itemData?.data?.first_name ?? "Support",
      last_name: props?.itemData?.data?.last_name ?? window.location.host,
      email:
        props?.itemData?.data?.email ?? `support@${window.location.host}.com`,
      type: formData.type,
    });
  }, [
    formData.type,
    props?.itemData?.data?.email,
    props?.itemData?.data?.first_name,
    props?.itemData?.data?.last_name,
  ]);

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...formData,
      [fieldName]: fieldValue,
    };
    setFormData(newState);
  };

  const modalFields = [
    "start_date",
    "start_time",
    "duration",
    "first_name",
    "last_name",
    "email",
    "phone",
    "whatsapp",
    "meeting_title",
  ];

  const areFieldsFilled = (fieldNames, formData) => {
    return fieldNames.every((fieldName) => formData[fieldName]);
  };

  const isModalFieldsFilled = areFieldsFilled(modalFields, formData);

  //  convert to UTC
  useEffect(() => {
    // Set the default timezone to the user's current timezone
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimeZone({ label: userTimezone, value: userTimezone });
  }, []);

  const timeZones = mainTimeZones.map((tz) => ({ label: tz, value: tz }));

  const handleDateChange = (newValue) => {
    setEventDate(newValue);
    const formattedDate = moment(newValue).format("YYYY/MM/DD");
    setFormData((prevData) => ({
      ...prevData,
      start_date: formattedDate,
    }));
  };

  const handleTimeZoneChange = (selectedOption) => {
    setSelectedTimeZone(selectedOption);
    convertToUTC(eventTime, selectedOption?.value);
  };

  const handleEventTimeChange = (newTime) => {
    setEventTime(newTime);

    if (selectedTimeZone) {
      convertToUTC(newTime, selectedTimeZone.value);
    }
  };

  const convertToUTC = (givenTime, timeZone) => {
    const utcTime = moment.tz(givenTime, timeZone).utc().format("HH:mm");
    setFormData((prevData) => ({
      ...prevData,
      start_time: utcTime,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isModalFieldsFilled) {
      setHasValue(false);
      setIsValid(true);
      setReqLoading(true);

      const reqData = {
        participants: [
          support,
          {
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            whatsApp: formData.whatsapp,
            phone: formData.phone,
            linkedIn: formData.linkedIn,
            job_title: formData.job_title,
            bio: formData.bio,
            participant_type: formData.participant_type || "participant",
          },
        ],
        meeting_title: formData.meeting_title,
        meeting_description: formData.meeting_details,
        meeting_id: props.itemData?.meeting_id,
        start_date: formData.start_date,
        start_time: formData.start_time,
        online_join_link: props.itemData?.online_join_link,
        duration_in_min: formData.duration,
        userId: myUserId,
        type: "appointment",
        comments: formData?.comments,
      };

      API("POST", `${Actions.BASE_URL}appointments`, reqData)
        .then((res) => {
          SuccessAlert("Appointment Requested");
          onHide();
        })
        .catch((error) => {
          console.error("API request failed:", error);
          ErrorAlert("Something went wrong");
        })
        .finally(() => {
          setReqLoading(false);
        });
    } else {
      setHasValue(true);
      setIsValid(true);
    }
  };

  const onHide = () => {
    setFormData({
      duration: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      whatsapp: "",
      meeting_title: "",
      start_date: "",
      start_time: "",
    });
    props.onHide();
  };

  useEffect(() => {
    setFormData({ type: props.fieldAlias });
  }, [props.fieldAlias]);

  return (
    <>
      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-startup-popup"
        onHide={onHide}
        show={props.show}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-capitalize h5"
          >
            Book an appointment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mx-0 align-items-end">
              <Col md={6}>
                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating mx-auto mb-3 appointment-modal">
                  <ReactDatePicker
                    placeholderText="Appointment Date"
                    className={`form-control form-select w-100 ${
                      isValid && hasValue && !formData?.start_date
                        ? "border-danger"
                        : ""
                    }`}
                    selected={eventDate}
                    onChange={handleDateChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={6} className="position-relative">
                <Form.Group className="form-floating my-3 mx-auto">
                  <Select
                    className="timezone form-control z-3"
                    required
                    id="timezone"
                    options={timeZones}
                    value={selectedTimeZone}
                    onChange={handleTimeZoneChange}
                    placeholder="Select your time zone"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating mx-auto mb-3">
                  <ReactDatePicker
                    placeholderText="Appointment Time:"
                    className={`form-control form-select w-100 ${
                      isValid && hasValue && !formData?.start_time
                        ? "border-danger"
                        : ""
                    }`}
                    selected={eventTime}
                    onChange={handleEventTimeChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={1}
                    dateFormat="h:mm aa"
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating position-relative mb-3">
                  <Form.Select
                    aria-label="event type"
                    required
                    className={`form-control ${
                      isValid && hasValue && !formData?.duration
                        ? "border-danger"
                        : ""
                    }`}
                    onChange={(e) =>
                      handleFieldChange("duration", e.target.value)
                    }
                    id="duration"
                    name="duration"
                  >
                    <option value="">Duration</option>
                    <option value="10">10 Minutes</option>
                    <option value="20">20 Minutes</option>
                    <option value="30">30 Minutes</option>
                    <option value="30">40 Minutes</option>
                    <option value="30">50 Minutes</option>
                    <option value="60">1 Hour</option>
                    <option value="120">2 Hours</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={6}>
                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    className={`${
                      isValid && hasValue && !formData?.first_name
                        ? "border-danger"
                        : ""
                    }`}
                    id="first_name"
                    required
                    value={formData?.first_name}
                    placeholder="name"
                    name="first_name"
                    autoFocus
                    onChange={(e) =>
                      handleFieldChange("first_name", e.target.value)
                    }
                  />
                  <Form.Label className="formLabel" htmlFor="first_name">
                    First Name
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    className={`${
                      isValid && hasValue && !formData?.last_name
                        ? "border-danger"
                        : ""
                    }`}
                    id="last_name"
                    required
                    value={formData?.last_name}
                    placeholder="name"
                    name="last_name"
                    onChange={(e) =>
                      handleFieldChange("last_name", e.target.value)
                    }
                  />
                  <Form.Label className="formLabel" htmlFor="last_name">
                    Last Name
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="email"
                    className={
                      isValid && hasValue && !formData?.email
                        ? "border-danger"
                        : ""
                    }
                    id="email"
                    required
                    value={formData?.email}
                    placeholder="email"
                    name="email"
                    onChange={(e) => handleFieldChange("email", e.target.value)}
                  />
                  <Form.Label className="formLabel" htmlFor="email">
                    Email
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="phone"
                    className={
                      isValid && hasValue && !formData?.phone
                        ? "border-danger"
                        : ""
                    }
                    id="phone"
                    required
                    value={formData?.phone}
                    placeholder="phone"
                    name="phone"
                    onChange={(e) => handleFieldChange("phone", e.target.value)}
                  />
                  <Form.Label className="formLabel" htmlFor="phone">
                    Phone
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="tel"
                    className={
                      isValid && hasValue && !formData?.whatsapp
                        ? "border-danger"
                        : ""
                    }
                    id="whatsapp"
                    required
                    value={formData?.whatsapp}
                    placeholder="whatsapp"
                    name="whatsapp"
                    onChange={(e) =>
                      handleFieldChange("whatsapp", e.target.value)
                    }
                  />
                  <Form.Label className="formLabel" htmlFor="whatsapp">
                    Whatsapp
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col md={12}>
                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    as={"textarea"}
                    className={
                      isValid && hasValue && !formData?.meeting_title
                        ? "border-danger"
                        : ""
                    }
                    id="meeting_title"
                    rows={1}
                    required
                    value={formData?.meeting_title}
                    placeholder="meeting_title"
                    name="meeting_title"
                    onChange={(e) =>
                      handleFieldChange("meeting_title", e.target.value)
                    }
                  />
                  <Form.Label className="formLabel" htmlFor="meeting_title">
                    Meeting Title
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col md={12}>
                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    as={"textarea"}
                    id="meeting_details"
                    rows={4}
                    value={formData?.meeting_details}
                    placeholder="meeting_details"
                    name="meeting_details"
                    onChange={(e) =>
                      handleFieldChange("meeting_details", e.target.value)
                    }
                  />
                  <Form.Label className="formLabel" htmlFor="meeting_details">
                    Please provide detailed context of your appointment
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col md={12}>
                <h5 className="pt-1">Optional Details</h5>
                <hr className="mb-3" />
              </Col>
              <Col md={12}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="job_title"
                    value={formData?.job_title}
                    placeholder="job_title"
                    name="job_title"
                    onChange={(e) =>
                      handleFieldChange("job_title", e.target.value)
                    }
                  />
                  <Form.Label className="formLabel" htmlFor="job_title">
                    Job Title
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Select
                    id="city"
                    value={formData?.city}
                    name="city"
                    onChange={(e) => handleFieldChange("city", e.target.value)}
                  >
                    <option value="">city</option>
                    {chosenCities?.map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="url"
                    id="linkedin"
                    value={formData?.linkedin}
                    placeholder="linkedin"
                    name="linkedin"
                    onChange={(e) =>
                      handleFieldChange("linkedin", e.target.value)
                    }
                  />
                  <Form.Label className="formLabel" htmlFor="linkedin">
                    LinkedIn
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    as={"textarea"}
                    id="bio"
                    rows={4}
                    value={formData?.bio}
                    placeholder="bio"
                    name="bio"
                    onChange={(e) => handleFieldChange("bio", e.target.value)}
                  />
                  <Form.Label className="formLabel" htmlFor="bio">
                    Brief Bio
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    as={"textarea"}
                    id="comments"
                    rows={4}
                    value={formData?.comments}
                    placeholder="comments"
                    name="comments"
                    onChange={(e) =>
                      handleFieldChange("comments", e.target.value)
                    }
                  />
                  <Form.Label className="formLabel" htmlFor="comments">
                    Your Comments
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>

            <Button
              className="btn add-button float-end mt-3 px-4"
              type="submit"
            >
              Request Appointment
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RequestAppointment;
