import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import {
  ArrowLeftCircleFill,
  PencilSquare,
  PlusCircle,
  Trash3Fill,
  Upload,
} from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import { getCookie } from "../../utils/cookies";
import UploadModal from "../fileUploadFieldSet/listUploadPopup";
import CustomLoader from "../loader/loader";
import TokenDetails from "../useUserDetails/useTokenDetails";

const EmailContacts = (props) => {
  const myToken = getCookie("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const listID = props?.params?.id;

  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contactsData, setContactsData] = useState({});

  const [uploadListID, setUploadListID] = useState("");

  const getEmailContacts = () => {
    setLoading(true);

    axios
      .get(`${Actions.BASE_URL}contacts/${myUserId}/emaillist/${listID}`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setContactsData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        // setLoading(false);
        console.log(error.response);
      });
  };

  useEffect(() => {
    getEmailContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listID]);

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            axios
              .delete(`${Actions.BASE_URL}contacts/${id}`, {
                headers: {
                  Authorization: `Bearer ${myToken}`,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                getEmailContacts();
                console.log(res.data);
              });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg" className="formMain create my-5 py-5">
          <div className="wizard-fieldset show demosAddForm py-4">
            <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap pb-3">
              <h4 className="theme-text-2 mb-0 d-flex align-items-center gap-2">
                <Link
                  to="/my-contacts/email-lists"
                  className="btn-link theme-text text-center"
                >
                  <ArrowLeftCircleFill />
                </Link>
                Contacts
              </h4>
              <div className="d-flex gap-3">
                <Button
                  className="btn add-button d-flex align-items-center"
                  onClick={() => {
                    setModalShow(true);
                    setUploadListID(listID);
                  }}
                >
                  <Upload /> &nbsp; Upload
                </Button>
                <Link
                  to={`/my-contacts/email-list/contact/${listID}/add`}
                  className="btn add-button d-flex align-items-center"
                >
                  <PlusCircle />
                  &nbsp; Add Contact
                </Link>
              </div>
            </div>
            {contactsData.length > 0 ? (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th className="bg-theme bg-opacity-50 text-light">
                      First Name
                    </th>
                    <th className="bg-theme bg-opacity-50 text-light">
                      Last Name
                    </th>
                    <th className="bg-theme bg-opacity-50 text-light">Email</th>
                    <th className="bg-theme bg-opacity-50 text-light">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {contactsData?.map((contact, index) => (
                    <tr key={index}>
                      <td>{contact.first_name}</td>
                      <td>{contact.last_name}</td>
                      <td>{contact.email}</td>
                      <td className="d-flex align-items-center justify-content-center gap-3">
                        <Link
                          to={`/my-contacts/email-list/contact/${listID}/${contact.id}/edit`}
                          className="btn-link theme-text text-center"
                        >
                          <PencilSquare />
                        </Link>
                        <Link
                          to={"#"}
                          className="btn-link text-danger text-center p-0"
                          onClick={() => deleteItem(contact.id)}
                        >
                          <Trash3Fill />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>No Contacts Found...</p>
            )}
          </div>
        </Container>
      )}
      <UploadModal
        listtype="emaillist"
        uploadlistid={uploadListID}
        show={modalShow}
        setshow={setModalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default withRouter(EmailContacts);
