import axios from "axios";
import React, { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import * as Actions from "../../constants/baseUrl";
import { toast } from "react-toastify";

const ForgotModal = (props) => {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    axios
      .get(
        `${Actions.AUTH_URL}Users/ForgotPassword?userId=${event.currentTarget["user-email"].value}`
      )
      .then((res) => {
        console.log(res.data.Data);
        setLoading(false);
        setMessage(res.data.Data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong. Please Try again later.", {
          autoClose: 3000,
          hideProgressBar: true,
          draggable: true,
        });
        setLoading(false);
      });
  };

  return (
    <Modal centered {...props} size="md">
      {loading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}

      <Modal.Header closeButton className="py-2">
        <Modal.Title>Forgot Password?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Please enter your email associated with your account to reset your
          password.
        </p>
        <form onSubmit={handleSubmit}>
          <Form.Group className="form-floating mb-3">
            <Form.Control
              type="email"
              id="user-email"
              name="email"
              placeholder="Enter your email"
              required
            />
            <Form.Label htmlFor="user-email">Enter your email</Form.Label>
          </Form.Group>
          {message !== null &&
            (message.toLowerCase() === "doesnotexist" ? (
              <Alert className="alert-danger p-2">Account Does Not Exist</Alert>
            ) : (
              <Alert className="alert-success p-2">Email Sent</Alert>
            ))}
          <Button
            className="actionBtnMore ms-auto w-auto d-block"
            type="submit"
          >
            Submit
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ForgotModal;
