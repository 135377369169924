import { CopyAllOutlined } from "@mui/icons-material";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import {
  Back,
  PencilSquare,
  PlusCircle,
  Search,
  Trash3Fill,
} from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../../components/API/API";
import ConfigCloneModal from "../../../components/configCloneModal/configCloneModal";
import CustomLoader from "../../../components/loader/loader";
import SubDomainRouter from "../../../components/subDomainRouter/subDomainRouter";
import * as Actions from "../../../constants/baseUrl";
import { getCookie } from "../../../utils/cookies";

const ConfigManagement = () => {
  const myToken = getCookie("token");

  const [loading, setLoading] = useState(true);
  const [reqLoading, setReqLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderBy] = useState("host");
  const [order, setOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [configs, setConfigs] = useState([]);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState(null);
  // Get data
  const getConfigs = () => {
    setLoading(true);
    axios
      .get(`${Actions.BASE_URL}configs/all`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setConfigs(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    getConfigs();
  }, []);

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            setReqLoading(true);
            API("delete", `configs/${id}`)
              .then((res) => {
                getConfigs();
              })
              .catch((error) => {
                console.log(error);
                toast.error(error?.response?.data || "Error deleting config");
              })
              .finally(() => {
                setReqLoading(false);
              });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const siteName = SubDomainRouter("siteName");

  const filteredConfigs = configs.filter((config) =>
    config?.host?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const sortedConfigs = filteredConfigs.sort((a, b) => {
    const isAsc = order === "asc";
    if (a[orderBy] && b[orderBy]) {
      return isAsc
        ? a[orderBy].localeCompare(b[orderBy])
        : b[orderBy].localeCompare(a[orderBy]);
    }
    return 0;
  });

  return (
    <>
      <Helmet>
        <title>
          Config Management | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      <div className="mb-4 all-contacts">
        {reqLoading ? (
          <>
            <span className="register-loader"> </span>
            <span className="spinnerOverlay" />
          </>
        ) : null}

        {loading ? (
          <CustomLoader />
        ) : (
          <Row className="align-items-center mx-0">
            <Col
              md={12}
              className="heading-text d-flex justify-content-between gap-1 align-items-start mb-3"
            >
              <div>
                <h4 className="theme-text-2">Configurations</h4>
              </div>
              <div className="d-flex justify-content-md-end px-0 gap-2 text-capitalize">
                <Link
                  className="add-button btn d-flex align-items-center gap-2"
                  to="/super-admin-dashboard/config/add"
                >
                  <PlusCircle />
                  <span className="d-lg-block text-nowrap d-none">
                    &nbsp; Create config
                  </span>
                </Link>

                <InputGroup className="w-100 tableSearch ms-auto rounded-pill position-relative">
                  <Form.Control
                    className="rounded-2"
                    onChange={handleSearch}
                    placeholder="Search by host"
                  />
                  <InputGroup.Text
                    id="basic-addon1"
                    className="border-0 bg-transparent position-absolute top-0 h-100 end-0"
                  >
                    <Search />
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </Col>
            <Col md={12}>
              {filteredConfigs?.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 768 }}
                    aria-label="simple table"
                    className="striped rounded-0"
                  >
                    <TableHead className="bg-theme">
                      <TableRow>
                        <TableCell className="text-nowrap text-white fw-bold h5 py-3">
                          <TableSortLabel
                            active={orderBy === "host"}
                            direction={orderBy === "host" ? order : "asc"}
                            onClick={() => handleSortRequest("host")}
                          >
                            Host/Domain Name
                          </TableSortLabel>
                        </TableCell>
                        <TableCell className="text-nowrap text-white fw-bold text-center h5 py-3">
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedConfigs
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell className="py-2">
                              <a
                                href={`https://${item?.host}`}
                                target="_blank"
                                rel="noreferrer"
                                className="theme-text h6"
                              >
                                {item.host}
                              </a>
                            </TableCell>
                            <TableCell className="py-2">
                              <div className="d-flex align-items-baseline justify-content-center gap-2">
                                <Link
                                  to={`/super-admin-dashboard/config/edit/${
                                    item?.id || item._id.$oid
                                  }`}
                                  className="btn-link theme-text text-center me-1 h6"
                                  title="Edit"
                                >
                                  <PencilSquare />
                                </Link>
                                <Link
                                  to={`/super-admin-dashboard/config/clone/${
                                    item?.id || item._id.$oid
                                  }`}
                                  className="btn-link theme-text text-center me-1"
                                  title="Copy"
                                >
                                  <CopyAllOutlined />
                                </Link>
                                <Button
                                  className="btn-link text-danger text-center p-0"
                                  title="Delete"
                                  onClick={() =>
                                    deleteItem(item?.id || item._id.$oid)
                                  }
                                >
                                  <Trash3Fill />
                                </Button>
                                <Button
                                  onClick={() => {
                                    setSelectedConfig(item);
                                    setShowCloneModal(true);
                                  }}
                                  className="btn-link theme-text text-center me-1 p-0"
                                  title="Clone"
                                >
                                  <Back />
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    className="d-flex align-items-center justify-content-end"
                    count={filteredConfigs?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </TableContainer>
              ) : (
                <p>No Configs Founds...</p>
              )}
            </Col>
          </Row>
        )}
      </div>

      {/* Clone Config Modal */}
      <ConfigCloneModal
        show={showCloneModal}
        onHide={() => setShowCloneModal(false)}
        configData={selectedConfig}
      />
    </>
  );
};

export default ConfigManagement;
