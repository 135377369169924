import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { ShareFill } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import API from "../../components/API/API";
import CustomLoader from "../../components/loader/loader";
import SocialShare from "../../components/share/socialShare";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import withRouter from "../../constants/withRouter";

import userImg from "../../img/user.png";
import ItemInfo from "./itemInfo";

function NRIStartupDetails(props) {
  const entities = "items";
  const entityType = "NRI-startup";
  const currentDomain = window.location.hostname;

  const [loading, setLoading] = useState(true);
  const [itemData, setItemData] = useState({});

  const populateList = async (id) => {
    try {
      const response = await API("get", entities + `/${entityType}/` + id);
      setItemData(response);
    } catch (error) {
      console.log("There was an error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    populateList(props.params.id);
  }, [props.params.id]);

  const siteName = SubDomainRouter("title");
  const internAlias = SubDomainRouter("internAlias");

  return (
    <>
      <Helmet>
        <title>
          {itemData
            ? `${itemData?.data?.first_name + " " + itemData?.data?.last_name} `
            : `${internAlias || "Item"} Details`}{" "}
          | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      <Container fluid="lg" className="my-5 pt-5">
        {loading ? (
          <CustomLoader />
        ) : (
          <div className="demosViewMoreSection demoProfile overflow-hidden mx-auto">
            <div className="coverImg p-2 position-relative w-100">
              <Image
                src={
                  itemData?.data?.hero_image_url ||
                  itemData?.data?.listing_image_url ||
                  "/assets/images/SWIPERIMG3-min.jpg"
                }
                loading="lazy"
                width="100%"
                alt=""
              />
            </div>

            <Row className="profileHeader align-items-center position-relative py-2 mx-4">
              <Col lg={2} className="mr-3">
                <Image
                  src={
                    itemData?.data?.listing_image_url ||
                    itemData?.data?.hero_image_url ||
                    userImg
                  }
                  alt="..."
                  className="profileImg"
                />
              </Col>
              <Col md={5} className="mt-lg-0 mt-5 pt-2 ps-4">
                <h4 className="trainerName mb-2">
                  {itemData?.data?.startup_name} <br />
                  <span className="industry">
                    {itemData?.data?.founder_name
                      ? `~ by ${itemData?.data?.founder_name}`
                      : "Not Available"}
                  </span>
                </h4>
              </Col>
              <Col lg={5} md={6}>
                <div className="d-flex align-items-center justify-content-center gap-2 flex-wrap w-100">
                  <SocialShare
                    url={`https://${currentDomain}/${entityType}/items/details/${itemData?.id}/`}
                    iconsSize={30}
                    title={
                      itemData?.data?.first_name +
                      " " +
                      itemData?.data?.last_name
                    }
                    image={itemData?.data?.listing_image_url}
                    description={itemData?.data?.description}
                  />
                  <Link
                    to={`/share/items_${entityType}/${itemData.id}`}
                    className="entity-share-btn d-flex align-items-center cursor-pointer"
                    title="Share profile"
                  >
                    <ShareFill />
                  </Link>
                </div>
              </Col>
            </Row>

            <div className="m-4 mt-0">
              <ItemInfo itemData={itemData?.data} />
            </div>
          </div>
        )}
      </Container>
    </>
  );
}
export default withRouter(NRIStartupDetails);
