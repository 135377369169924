import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { DynamicForm } from "../../components/JsonSchemaDynamicForm";
import { confirmAlert } from "react-confirm-alert";
import { Check2Circle } from "react-bootstrap-icons";

const JSONEntityAddEdit = () => {
  const params = useParams();
  const entityType = params.entityType;
  const entityId = params.id;

  const navigate = useNavigate();
  const currentHost = window.location.hostname;

  const [schemas, setSchemas] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [reqLoading, setReqLoading] = useState(false);

  useEffect(() => {
    const fetchSchema = async () => {
      setLoading(true);
      try {
        await axios
          .get(`https://api.ameerpetit.com/api/entities/forms/${entityType}/`)
          .then((response) => {
            setSchemas(response?.data);
            if (entityType && entityId) populateForm();
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (entityType) fetchSchema();

    const populateForm = async () => {
      setLoadingData(true);
      try {
        const response = await axios.get(
          `https://api.ameerpetit.com/api/entities/items/${entityType}/${entityId}`
        );
        setSchemas((prev) => ({
          ...prev,
          data: response?.data.data,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingData(false);
      }
    };
  }, [entityType, entityId]);

  const handleSubmit = (formData) => {
    setReqLoading(true);

    const reqData = {
      type: entityType,
      name: "submitted_data",
      data: formData,
      host: schemas?.data?.host ?? currentHost,
    };

    if (entityId) {
      axios
        .put(
          `https://api.ameerpetit.com/api/entities/items/${entityType}/${entityId}`,
          reqData,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
            },
          }
        )
        .then((response) => {
          successAlert(schemas?.redirect_url);
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        })
        .finally(() => {
          setReqLoading(false);
        });
    } else {
      axios
        .post(`https://api.ameerpetit.com/api/entities/items/`, reqData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        })
        .then((response) => {
          successAlert(schemas?.redirect_url);
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        })
        .finally(() => {
          setReqLoading(false);
        });
    }
  };

  const successAlert = (redirect_url) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        setTimeout(() => {
          onClose();
          navigate(redirect_url ?? "/");
        }, 2000);

        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center my-2 text-capitalize">
              Thank YOu for Showing Interest.
            </h5>
          </div>
        );
      },
    });
  };

  return (
    <>
      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}

      <Container fluid="lg" className="py-5 my-5">
        <Row className="shadow-lg py-4 px-5 my-4">
          <Col md={12}>
            <h1 className="text-center text-theme text-capitalize">
              {schemas?.display_name ?? schemas?.name ?? ""}
            </h1>
          </Col>
          <Col md={12}>
            {loadingData || loading ? (
              <p className="text-center p-3">Loading Data Please wait...</p>
            ) : (
              <DynamicForm schemas={schemas} onSubmit={handleSubmit} />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default JSONEntityAddEdit;
