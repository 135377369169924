import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { BiEdit, BiEnvelope, BiTrash } from "react-icons/bi";
import { FaLinkedin } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import useUserData from "../../components/useUserDetails/useUserDetails";

const JSONEntityListing = () => {
  const params = useParams();
  const entityType = params.entityType;
  const userData = useUserData();
  const role = userData.role?.toLowerCase();

  const [entityData, setEntityData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.ameerpetit.com/api/entities/items/${entityType}`
      );
      const jsonResponse = await response.json();
      setEntityData(jsonResponse);
    } catch (error) {
      console.error("Error fetching Data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [entityType]);

  // function to delete item from database
  const deleteItem = async (id) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (confirm) {
      axios
        .delete(
          `https://api.ameerpetit.com/api/entities/items/${entityType}/${id}`
        )
        .then(() => {
          alert("Item deleted successfully");
          fetchData();
        })
        .catch((error) => {
          console.error("Error deleting item:", error);
        });
    }
  };

  return (
    <Container fluid="lg" className="py-5 my-5">
      <div className="d-flex justify-content-between align-items-center">
        {" "}
        <h1 className="text-theme mb-3 text-capitalize">{entityType}s</h1>
        {role === "admin" || role === "siteadmin" || role === "superadmin" ? (
          <Link
            to={`/forms/${entityType}/add`}
            className="add-button btn text-capitalize"
          >
            Add {entityType}
          </Link>
        ) : null}
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <Row className="d-flex justify-content-center align-items-center">
          {entityData?.length > 0 ? (
            entityData?.map((data) => (
              <Col sm={6} md={4} xl={3} className="py-2">
                <Card className="text-center" style={{ borderRadius: "15px" }}>
                  <Card.Body>
                    <div className="mt-3 mb-4">
                      <img
                        src={
                          data?.data?.profile_img ??
                          "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp"
                        }
                        className="rounded-circle img-fluid"
                        style={{ width: "100px" }}
                        alt="Profile"
                      />
                    </div>
                    <h4 className="mb-2">
                      {data?.data?.name ||
                        `${data?.data?.firstname} ${data?.data?.lastname}`}
                    </h4>
                    <p className="text-muted mb-4">
                      {data?.data?.designation} | {data?.data?.age}
                    </p>

                    <div className="  d-flex justify-content-center gap-2">
                      {data?.data?.linkedin && (
                        <Link
                          to={data?.data?.linkedin}
                          target="_blank"
                          className="btn add-button-outline px-2 py-1 mb-4"
                        >
                          <FaLinkedin className="fs-6" />
                        </Link>
                      )}

                      {data?.data?.email && (
                        <Link
                          to={data?.data?.email}
                          target="_blank"
                          className="btn add-button-outline px-2 py-1 mb-4"
                        >
                          <BiEnvelope className="fs-6" />
                        </Link>
                      )}
                    </div>

                    <div className="d-flex gap-2 justify-content-center">
                      <Button
                        variant="primary"
                        className="btn-rounded py-1 d-flex align-items-center justify-content-center btn add-button"
                      >
                        View Details
                      </Button>
                      {role === "admin" ||
                      role === "siteadmin" ||
                      role === "superadmin" ? (
                        <>
                          <Button
                            onClick={() => deleteItem(data?.id)}
                            className="btn-rounded py-1 d-flex align-items-center justify-content-center btn btn-danger px-2"
                          >
                            <BiTrash className="fs-6" />
                          </Button>
                          <Link
                            to={`/forms/${entityType}/edit/${data?.id}`}
                            className="btn-rounded py-1 d-flex align-items-center justify-content-center btn add-button w-auto px-2"
                          >
                            <BiEdit className="fs-6" />
                          </Link>
                        </>
                      ) : null}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <p>No data Found</p>
          )}
        </Row>
      )}
    </Container>
  );
};

export default JSONEntityListing;
