import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Filter, Share } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import RevImg from "../../img/rew.webp";
import FilterChips from "../../components/filterSidebar/filterChips";
import FilterSidebar from "../../components/filterSidebar/filterSidebar";
import CustomLoader from "../../components/loader/loader";
import StarRating from "../../components/starRating/starRating";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import useUserData from "../../components/useUserDetails/useUserDetails";
import "./reviews.css";
import { toast } from "react-toastify";
import { getCookie } from "../../utils/cookies";

export default function Reviews() {
  const entities = "reviews";

  const myToken = getCookie("token");
  const userData = useUserData();
  const role = userData.role?.toLowerCase();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [smFilterMenu, setSmFilterMenu] = useState(false);
  const [displayedCards, setDisplayedCards] = useState(6);
  const [categoryKeys, setCategoryKeys] = useState([]);
  const [subCategoryKeys_2, setSubCategoryKeys_2] = useState([]);
  const [subCategoryKeys_1, setSubCategoryKeys_1] = useState([]);
  const [subCategoryKeys, setSubCategoryKeys] = useState([]);
  const [starRatingKeys, setStarRatingKeys] = useState([]);
  const [entityObj, setEntityObj] = useState({
    type: "demo",
    searchTerm: "",
    subcategory: [],
    star_rating_type: [],
    category: [],
    subcategorylevel1: [],
    entity_types: [],
    subcategorylevel2: [],
    subcategorylevel3: [],
  });
  const [selectedFilters, setSelectedFilters] = useState({
    type: "demo",
    searchTerm: "",
    subcategory: [],
    star_rating_type: [],
    category: [],
    entity_types: [],
    subcategorylevel1: [],
    subcategorylevel2: [],
    subcategorylevel3: [],
  });

  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-info",
          onClick: () => {
            axios.delete(`${Actions.BASE_URL}${entities}/${id}`).then(() => {
              populateList();
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const ifFilterEmpty =
    entityObj?.category == "" &&
    entityObj?.subcategorylevel1 == "" &&
    entityObj?.subcategory == "" &&
    entityObj?.subcategorylevel2 == "" &&
    entityObj?.star_rating_type == "" &&
    entityObj?.subcategorylevel3 == "" &&
    entityObj?.searchTerm?.trim() == "";
  // Get Cards Data
  const populateList = async () => {
    setLoadingFilter(true);
    try {
      if (ifFilterEmpty) {
        const response = await axios.get(`${Actions.BASE_URL}${entities}`);
        setItems(response.data);
        setLoading(false);
      } else {
        const response = await axios.post(
          `${Actions.BASE_URL}Search/filters`,
          entityObj
        );
        setItems(response?.data?.reviews);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error calling API:", error);
      toast.error("Something went wrong. Please Try again later.", {
        autoClose: 3000,
        hideProgressBar: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
      setLoadingFilter(false);
    }
  };

  useEffect(() => {
    populateList();
  }, [entityObj]);

  useEffect(() => {
    fetchCourseNames();
    fetchTeachingModes();
    fetchCategory();
    fetchSubCategory();
    fetchStarRating();
  }, []);

  const fetchCourseNames = async () => {
    try {
      await axios
        .get(`${Actions.BASE_URL}enums/courseNames`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setCategoryKeys(response.data);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Category type fetched from ENUM
  const fetchTeachingModes = async () => {
    try {
      await axios
        .get(`${Actions.BASE_URL}enums/teachingModes`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setSubCategoryKeys_2(response.data);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // entity category fetched from ENUM
  const fetchCategory = async () => {
    try {
      await axios
        .get(`${Actions.BASE_URL}enums/paymentTypes`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setSubCategoryKeys_1(response.data);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // entity category fetched from ENUM
  const fetchSubCategory = async () => {
    try {
      await axios
        .get(`${Actions.BASE_URL}enums/subcategoryTypes`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setSubCategoryKeys(response.data);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // rating fetched from ENUM
  const fetchStarRating = async () => {
    try {
      await axios
        .get(`${Actions.BASE_URL}enums/starRatings`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setStarRatingKeys(response.data);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const itemList = (items) => {
    const handleShowMore = () => {
      setDisplayedCards((prevDisplayedCards) =>
        prevDisplayedCards + 3 <= items.length
          ? prevDisplayedCards + 3
          : items.length
      );
    };

    return (
      <div className="d-flex mt-5 pt-5">
        <FilterSidebar
          filteringFrom="review"
          categoryKeys={categoryKeys}
          subCategoryKeys_2={subCategoryKeys_2}
          subCategoryKeys_1={subCategoryKeys_1}
          subCategoryKeys={subCategoryKeys}
          starRatingKeys={starRatingKeys}
          setEntityObj={setEntityObj}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setSmFilterMenu={setSmFilterMenu}
          smFilterMenu={smFilterMenu}
        />
        <div className="overlay" onClick={() => setSmFilterMenu(false)}></div>
        <Container fluid="lg">
          <Row className="my-4 justify-content-md-start justify-content-center">
            <Col lg={10} md={7} className="text-md-start text-center">
              <h3 id="tabelLabel">Reviews</h3>
            </Col>

            <Col
              lg={12}
              className="d-flex align-items-sm-center justify-content-between align-items-start"
            >
              <FilterChips selectedFilters={selectedFilters} />
              <Button
                variant="link"
                className="filterToggle ms-auto p-0 text-right ms-auto d-lg-none me-lg-0 me-2 d-flex align-items-center justify-content-center btn bg-transparent"
                onClick={() => setSmFilterMenu(!smFilterMenu)}
              >
                <Filter />
              </Button>
            </Col>
            {loadingFilter ? (
              <CustomLoader />
            ) : (
              <>
                {items?.length !== 0 ? (
                  items.slice(0, displayedCards)?.map((reviewData) => (
                    <Col
                      xxl={3}
                      xl={4}
                      md={6}
                      sm={10}
                      className="mt-2 my-3"
                      key={reviewData.id}
                    >
                      <Card className="my-3 reviewCard d-flex justify-content-center align-reviewDatas-center">
                        <div className="card-image px-3 pt-2">
                          <Card.Img
                            src={
                              reviewData.listing_image_url
                                ? reviewData.listing_image_url
                                : RevImg
                            }
                            alt="Load Error"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = RevImg;
                            }}
                            width="100%"
                          />
                        </div>
                        <Card.Body className="text-center py-0 d-flex flex-column justify-content-between">
                          <div className="top-cont">
                            <StarRating rating={reviewData.star_rating} />
                            <Link
                              to={`/share/${reviewData.type}/${reviewData.id}`}
                              className="entity-share-btn d-flex align-items-center cursor-pointer float-end"
                            >
                              <Share />
                            </Link>
                            <Card.Title className="my-2 h6 text-capitalize">
                              ~ By&nbsp;
                              <b>
                                {reviewData.reviewer_first_name}&nbsp;
                                {reviewData.reviewer_last_name}
                              </b>
                            </Card.Title>
                            <Card.Text className="mb-2 mb-0">
                              <b>{reviewData.review_title}</b>
                            </Card.Text>
                            <Card.Text className="mb-0 text-overflow-desc">
                              <q>{reviewData.review_comments} </q>
                            </Card.Text>
                          </div>

                          <div className="itemEdit d-flex align-items-center justify-content-center flex-wrap gap-2">
                            <Link
                              to={`/${entities}/details/${reviewData.entityTypeAssociated}/${reviewData.entityIdAssociated}/${reviewData.id}`}
                              className="actionBtn btn action cursor-pointer"
                            >
                              View More
                            </Link>
                            {role === "Admin" && (
                              <span className="d-flex gap-2">
                                <Link
                                  to={`/${entities}/edit/${reviewData.id}`}
                                  className="actionBtn btn action cursor-pointer"
                                >
                                  Edit
                                </Link>
                                <Button
                                  href="#"
                                  className="actionDltBtn btn action cursor-pointer"
                                  onClick={() => deleteItem(reviewData.id)}
                                >
                                  Delete
                                </Button>
                              </span>
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <p>No data Found</p>
                )}
              </>
            )}
          </Row>
          {!loading && !loadingFilter && displayedCards < items.length ? (
            <div className="text-center mb-5">
              <Button
                onClick={handleShowMore}
                className="btn actionBtnMore action cursor-pointer w-auto "
              >
                Show More
              </Button>
            </div>
          ) : null}
        </Container>
      </div>
    );
  };

  const siteName = SubDomainRouter("title");
  return (
    <>
      <Helmet>
        <title>Reviews | {siteName ? siteName : window.location.host}</title>
      </Helmet>
      {loading ? <CustomLoader /> : itemList(items)}
    </>
  );
}
