import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Check2Circle, XCircle } from "react-bootstrap-icons";
import * as Actions from "../../constants/baseUrl";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import API from "../../components/API/API";
import { getCookie } from "../../utils/cookies";

const AddRoleModal = (props) => {
  const tokenDetails = TokenDetails();
  const myUserID = tokenDetails?.Email;
  const myToken = getCookie("token");

  const [loading, setLoading] = useState(false);
  const [isRoleAdded, setIsRoleAdded] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageContent, setErrorMessageContent] = useState([]);
  const [formData, setFormData] = useState({
    Host: window.location.host,
    Email: myUserID,
    Role: "",
  });
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      API("get", "configs/pagesettings/auth")
        .then((data) => {
          setUserRoles(data?.userRole);

          setLoading(false);
        })
        .catch((err) => console.error(err));
    };

    fetchData();
  }, []);

  const handleFieldChange = (fieldName, fieldValue) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .put(`${Actions.BASE_URL}users/host`, formData, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsRoleAdded(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.httpstatuscode === "BadRequest") {
          setLoading(false);
          setErrorMessage(true);
          setErrorMessageContent(error.response.data);
        }
      });
  };

  const handleDone = () => {
    props.setshow(false);
    setErrorMessage(false);
    window.location.reload();
  };

  return (
    <>
      {loading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}
      {isRoleAdded ? (
        <Modal
          show={props.show}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => {
            props.setshow(false);
            window.location.reload();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className="h5">
              Your Role is Updated.
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex align-items-center justify-content-center flex-column">
            <Check2Circle className="display-1 theme-text" />
            <h5>Profile Updated</h5>
            <Button
              className="btn add-button float-end mt-3"
              onClick={handleDone}
            >
              Done
            </Button>
          </Modal.Body>
        </Modal>
      ) : errorMessage ? (
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => {
            props.setshow(false);
            window.location.reload();
            setErrorMessage(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className="h5">
              {errorMessageContent.Error}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex align-items-center justify-content-center flex-column">
            <XCircle className="display-1 text-danger" />
            <h6 className="text-danger mt-2 text-center">
              {errorMessageContent.Message}
            </h6>
            <Button
              className="btn add-button float-end mt-3 px-4"
              onClick={() => {
                props.setshow(false);
                setErrorMessage(false);
              }}
            >
              OK
            </Button>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => props.setshow(false)}
          show={props.show}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="h6 fw-medium"
            >
              Add your Role/Account type<span className="text-danger">*</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col sm={12}>
                  <p className="theme-text-2 mb-3 lh-sm fw-medium">
                    <small>
                      Please select the desired Role you would like to continue
                      as for this ID
                    </small>
                  </p>
                  <Form.Group className="form-floating mb-3">
                    <Form.Select
                      id="role"
                      required
                      onChange={(e) => {
                        handleFieldChange("Role", e.target.value);
                      }}
                    >
                      <option value=""> Select Role/Account type</option>
                      {userRoles?.length > 0 ? (
                        userRoles?.map((role, index) => (
                          <option
                            value={role?.key}
                            key={index + role.key}
                            className="text-capitalize"
                          >
                            {role.value}
                          </option>
                        ))
                      ) : (
                        <option value={"user"} className="text-capitalize">
                          user
                        </option>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col xs={12}>
                  <Button
                    type="submit"
                    className="btn add-button float-end"
                    disabled={formData.Role === ""}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default AddRoleModal;
