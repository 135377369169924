import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import "./SearchBar.css";

function ThemeInpGroup({
  handleSubmit,
  icon,
  inputPlaceholder,
  loading,
  textarea = false,
}) {
  const [searchedTerm, setSearchedTerm] = useState("");

  const handleInputChange = (event) => {
    const term = event.target.value;
    setSearchedTerm(term);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    if (searchedTerm.trim() !== "") {
      handleSubmit(e, searchedTerm);
      setSearchedTerm("");
    }
  };
  const handleClear = (event) => {
    event.preventDefault();
    setSearchedTerm("");
  };

  return (
    <Form
      onSubmit={formSubmit}
      id="form"
      className="form-container flex-grow-1"
    >
      <InputGroup
        className={`inputGroup w-100 rounded-pill ${
          textarea ? "align-items-end" : ""
        }`}
      >
        <Form.Control
          as={textarea ? "textarea" : "input"}
          className="py-0 text-dark"
          style={
            textarea
              ? { height: "auto !important", maxHeight: "250px !important" }
              : {}
          }
          value={searchedTerm}
          onChange={handleInputChange}
          placeholder={inputPlaceholder}
          onInput={(e) => {
            e.target.style.height = "auto";
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
        />
        {searchedTerm !== "" && (
          <Button
            className="bg-transparent p-0 m-0 border-0 me-2 text-danger d-sm-block d-none w-auto"
            onClick={handleClear}
          >
            <X className="fs-4" />
          </Button>
        )}
        <InputGroup.Text
          type="submit"
          className={`position-relative ${
            loading && "p-0 d-flex align-items-center justify-content-center"
          }`}
          id="search"
          disabled={loading}
          onClick={formSubmit}
        >
          {loading ? (
            <span className="register-loader position-static ai-loader" />
          ) : (
            icon
          )}
        </InputGroup.Text>
      </InputGroup>
    </Form>
  );
}

export default ThemeInpGroup;
