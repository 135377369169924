import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import CodeGenerationOffcanvas from "../../components/CodeGenerationOffcanvas";
import { confirmAlert } from "react-confirm-alert";
import { Check2Circle } from "react-bootstrap-icons";

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  display_name: Yup.string().required("Display Name is required"),
  host: Yup.string().required("Host Name is required"),
  schema: Yup.string().required("Schema is required"),
});

const AddJSONSchema = () => {
  const params = useParams();
  const entityId = params.id;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [loading, setLoading] = useState(false);
  const [reqLoading, setReqLoading] = useState(false);
  const [codeGenerationShow, setCodeGenerationShow] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://api.ameerpetit.com/api/entities/schemas/${entityId}`
        );
        const data = await response.json();

        setValue("name", data?.type);
        setValue("display_name", data?.display_name);
        setValue("host", data?.host);
        setValue("redirect_url", data?.redirect_url);
        setValue("schema", JSON.stringify(data?.schema, null, 2));
        setValue("uischema", JSON.stringify(data?.uischema, null, 2));
        setValue("data", JSON.stringify(data?.data, null, 2));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    if (entityId) fetchData();
  }, [entityId, setValue]);

  const onSubmit = (data) => {
    setReqLoading(true);
    const reqData = {
      type: data?.name,
      name: data?.name,
      redirect_url: data?.redirect_url,
      display_name: data?.display_name,
      schema: data?.schema ? JSON.parse(data?.schema) : null,
      uischema: data?.uischema ? JSON.parse(data?.uischema) : null,
      data: data?.data ? JSON.parse(data?.data) : {},
      host: window.location.host,
    };
    if (entityId) {
      axios
        .put(
          `https://api.ameerpetit.com/api/entities/schemas/${entityId}`,
          reqData,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
            },
          }
        )
        .then((response) => {
          successAlert("Schema Updated successfully!");
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        })
        .finally(() => {
          setReqLoading(false);
        });
    } else {
      axios
        .post(`https://api.ameerpetit.com/api/entities/schemas`, reqData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        })
        .then((response) => {
          successAlert("Schema added successfully!");
          navigate(data?.name ? `/forms/${data?.name}/add` : -1);
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        })
        .finally(() => {
          setReqLoading(false);
        });
    }
  };

  const successAlert = (param) => {
    confirmAlert({
      customUI: () => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center my-2 text-capitalize">
              {param}
            </h5>
          </div>
        );
      },
    });
  };

  return (
    <>
      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}

      <Container>
        {loading ? (
          "Loading..."
        ) : (
          <div className="shadow-sm p-3 my-5">
            <h1 className="text-center text-theme">Schema</h1>

            <Button
              className="clickable add-button my-3 ms-auto d-flex"
              onClick={() => setCodeGenerationShow(true)}
            >
              Generate Using AI
            </Button>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
              <FormGroup className="mb-3" controlId="formGroupName">
                <FormLabel>Name/Type</FormLabel>
                <FormControl
                  type="text"
                  placeholder="Enter your name..."
                  {...register("name")}
                  isInvalid={!!errors.name}
                />
                <FormControl.Feedback type="invalid">
                  {errors.name?.message}
                </FormControl.Feedback>
              </FormGroup>
              <FormGroup className="mb-3" controlId="formGroupName">
                <FormLabel>Display Name</FormLabel>
                <FormControl
                  type="text"
                  placeholder="Enter your display_name..."
                  {...register("display_name")}
                  isInvalid={!!errors.display_name}
                />
                <FormControl.Feedback type="invalid">
                  {errors.display_name?.message}
                </FormControl.Feedback>
              </FormGroup>

              <FormGroup className="mb-3" controlId="formGroupName">
                <FormLabel>Host Name</FormLabel>
                <FormControl
                  defaultValue={window.location.hostname}
                  type="text"
                  placeholder="Enter your host..."
                  {...register("host")}
                  isInvalid={!!errors.host}
                />
                <FormControl.Feedback type="invalid">
                  {errors.host?.message}
                </FormControl.Feedback>
              </FormGroup>

              <FormGroup className="mb-3" controlId="formGroupName">
                <FormLabel>Redirect URL</FormLabel>
                <FormControl
                  type="text"
                  placeholder="Enter your redirect url..."
                  {...register("redirect_url")}
                  isInvalid={!!errors.redirect_url}
                />
                <FormControl.Feedback type="invalid">
                  {errors.redirect_url?.message}
                </FormControl.Feedback>
              </FormGroup>

              <FormGroup className="mb-3" controlId="formGroupSchema">
                <FormLabel>Schema</FormLabel>
                <FormControl
                  as="textarea"
                  rows={5}
                  placeholder="Enter your schema..."
                  {...register("schema")}
                  isInvalid={!!errors.schema}
                />
                <FormControl.Feedback type="invalid">
                  {errors.schema?.message}
                </FormControl.Feedback>
              </FormGroup>

              <FormGroup className="mb-3" controlId="formGroupUiSchema">
                <FormLabel>UI Schema</FormLabel>
                <FormControl
                  as="textarea"
                  rows={5}
                  type="schema" // Changed type to "schema"
                  placeholder="Enter your uischema..."
                  {...register("uischema")}
                />
              </FormGroup>
              <FormGroup className="mb-3" controlId="formGroupData">
                <FormLabel>Data</FormLabel>
                <FormControl
                  as="textarea"
                  rows={5}
                  type="data" // Changed type to "data"
                  placeholder="Enter your data confirmation..."
                  {...register("data")}
                />
              </FormGroup>

              <div className="d-grid my-5">
                <Button
                  variant="primary"
                  className="btn add-button"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Container>

      <CodeGenerationOffcanvas
        generateJson
        onHide={() => setCodeGenerationShow(false)}
        show={codeGenerationShow}
        onInsert={(response) => {
          setValue("schema", response);
          const resObj = JSON.parse(response);
          if (resObj?.title) {
            setValue("display_name", resObj?.title);
            setValue("name", resObj?.title?.replace(/\s/g, "_")?.toLowerCase());
          }
        }}
      />
    </>
  );
};

export default AddJSONSchema;
