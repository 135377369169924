import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row
} from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import API from "../../components/API/API";
import BannerUploaderEdit from "../../components/bannerUploaderEdit/bannerUploaderEdit";
import CustomLoader from "../../components/loader/loader";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import {
  chosenCountries,
  industries
} from "../../constants/extras";
import withRouter from "../../constants/withRouter";
import UploadFile from "../../helper/UploadFile";
import DynamicRelationFieldEdit from "../../components/dynamicRelationFieldSet/dynamicRelationFieldEdit";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";

const AddEditItems = (props) => {
  const item_id = props.params.id ?? "";
  const item_type = props.params.item_type ?? "";
  const entities = "items";
  const navigate = useNavigate();

  const demoAlias = SubDomainRouter("demoAlias");
  const courseAlias = SubDomainRouter("courseAlias");
  const trainerAlias = SubDomainRouter("trainerAlias");
  const instituteAlias = SubDomainRouter("instituteAlias");
  const reviewAlias = SubDomainRouter("reviewAlias");
  const demosAlias = SubDomainRouter("demosAlias");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const trainersAlias = SubDomainRouter("trainersAlias");
  const institutesAlias = SubDomainRouter("institutesAlias");
  const reviewsAlias = SubDomainRouter("reviewsAlias");


  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;
  // states
  const [loading, setLoading] = useState(true);
  const [reqLoading, setReqLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [relationData, setRelationData] = useState([{ type: "", value: "" }]);

  const [socialLinks, setSocialLinks] = useState([]);

  // * ==========================================================================
  const populateList = async (id) => {
    if (!id) {
      setLoading(false);
      return;
    }
    setLoading(true);

    //
    try {
      const response = await API("get", entities + `/${item_type}/` + id);
      setFormData(response?.data);
      setRelationData(response?.data?.entityRelation || [{ type: "", value: "" }]);
      setSocialLinks(response?.data?.social_links || [{ type: "", value: "" }]);
    } catch (error) {
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    populateList(item_id);
  }, [item_id]);
  // ========================

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...formData,
      [fieldName]: fieldValue,
    };
    setFormData(newState);
  };

  // * ==========================================================================

  const handleSubmit = async (event) => {
    setReqLoading(true);

    const reqData = {
      userId: myUserId,
      type: item_type,
      host: window.location.host,
      header: {
        host: window.location.host,
        userId: myUserId,
      },
      data: {
        userId: myUserId,
        social_links: socialLinks,
        entityRelation: relationData,
        ...formData,
      },
    };
    try {
      //
      if (item_id) {
        await API("put", entities + `/${item_type}/` + item_id, reqData);
        navigate(`/${item_type}/items`);
      } else {
        const response = await API("post", entities, reqData);
        navigate(`/share/items_${item_type}/${response.id}/`);
      }
      //
    } catch (error) {
      console.log(error.response);
    } finally {
      setReqLoading(false);
    }
  };

  // * ==========================================================================


  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="demosAddFormSection demosEditSection">
          {reqLoading ? (
            <>
              <span className="register-loader"> </span>
              <span className="spinnerOverlay" />
            </>
          ) : null}

          <Container fluid="lg" className="formMain create my-3">
            <div className="demosAddFormHeader text-center">
              <h2 className="addFormHeading text-capitalize">
                {item_id ? "Edit" : "Create"}&nbsp;
                &nbsp;{item_type}
              </h2>
              <div className="demosEditHeader text-center mb-3 mb-lg-5">
                <h5> Add Details Below:</h5>
              </div>
            </div>
            {/*  */}

            <Form
              id="add-demo-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="form-section addFormSection event-detail">
                <fieldset className="wizard-fieldset demosAddForm position-relative overflow-hidden">
                  <Row className="mx-0 position-relative">
                    <Col md={12} className="position-relative">
                      {formData?.hero_image_url || formData.hero_image_content ? (
                        <Button
                          className="deleteBtn p-1 btn-danger text-white bg-danger h-auto w-auto position-absolute bottom-0 end-0 mb-5 me-4 z-3"
                          onClick={() => {
                            setFormData({
                              ...formData,
                              hero_image_url: "",
                              hero_image_content: "",
                            });
                          }}
                        >
                          <Trash />
                        </Button>
                      ) : null}
                      <BannerUploaderEdit
                        className="item-hero-img"
                        key={formData?.hero_image_url}
                        label="Big Banner"
                        accept="image/png, image/jpg, image/jpeg"
                        imageType="hero_image"
                        placehold={
                          formData.hero_image_url ||
                          "https://placehold.co/1024x200"
                        }
                        state={formData}
                        setState={(data) => {
                          UploadFile(
                            formData.userId,
                            "hero_image",
                            data?.hero_image_content,
                            data?.hero_image_extension,
                            setReqLoading
                          ).then((url) => {
                            setFormData({
                              ...formData,
                              hero_image_url: url,
                              hero_image_content: "",
                            });
                          });
                        }}
                        imageURL={formData?.hero_image_url}
                        imageContent={formData?.hero_image_content}
                        imageExtension={formData?.hero_image_extension}
                      />
                    </Col>

                    <Col
                      lg={5}
                      md={6}
                      className="list-img-wrap position-relative"
                    >
                      {formData?.listing_image_url ||
                        formData.listing_image_content ? (
                        <Button
                          className="deleteBtn p-1 btn-danger text-white bg-danger h-auto w-auto position-absolute bottom-0 end-0 mb-4 me-4 z-3"
                          onClick={() => {
                            setFormData({
                              ...formData,
                              listing_image_url: "",
                              listing_image_content: "",
                            });
                          }}
                        >
                          <Trash />
                        </Button>
                      ) : null}
                      <BannerUploaderEdit
                        className="item-list-img"
                        key={formData?.listing_image_url}
                        label="Small Banner"
                        accept="image/png, image/jpg, image/jpeg"
                        imageType="listing_image"
                        placehold={
                          formData.listing_image_url ||
                          "https://placehold.co/280x180"
                        }
                        state={formData}
                        setState={(data) => {
                          UploadFile(
                            formData.userId,
                            "listing_image",
                            data?.listing_image_content,
                            data?.listing_image_extension,
                            setReqLoading
                          ).then((url) => {
                            setFormData({
                              ...formData,
                              listing_image_url: url,
                              listing_image_content: "",
                            });
                          });
                        }}
                        imageURL={formData?.listing_image_url}
                        imageContent={formData?.listing_image_content}
                        imageExtension={formData?.listing_image_extension}
                      />
                    </Col>
                  </Row>
                  <Row className="mx-0n align-items-end">
                    <Col md={12}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          autoFocus
                          type="text"
                          id="title"
                          required
                          value={formData?.title}
                          placeholder="name"
                          name="title"
                          onChange={(e) =>
                            handleFieldChange("title", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="title">
                          Name/Title
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          as="textarea"
                          className="w-100 form-control max-h-auto"
                          rows="4"
                          name="description"
                          value={formData.description}
                          style={{ height: "auto" }}
                          required
                          id="description"
                          placeholder=" Enter your event description"
                          onChange={(e) => {
                            handleFieldChange("description", e.target.value);
                          }}
                        />
                        <Form.Label className="form-label" htmlFor="description">
                          Description
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          as="textarea"
                          className="w-100 form-control max-h-auto"
                          rows="1"
                          name="address"
                          value={formData.address}
                          style={{ height: "auto" }}
                          id="address"
                          placeholder=" Enter your event address"
                          onChange={(e) => {
                            handleFieldChange("address", e.target.value);
                          }}
                        />
                        <Form.Label className="form-label" htmlFor="address">
                          Address
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="email"
                          id="email"
                          required
                          value={formData?.email}
                          placeholder="email"
                          name="email"
                          onChange={(e) =>
                            handleFieldChange("email", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="email">
                          Email
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="tel"
                          id="phone"
                          required
                          value={formData?.phone}
                          placeholder="phone"
                          name="phone"
                          onChange={(e) =>
                            handleFieldChange("phone", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="phone">
                          Phone
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="tel"
                          id="whatsapp"
                          value={formData?.whatsapp}
                          placeholder="whatsapp"
                          name="whatsapp"
                          onChange={(e) =>
                            handleFieldChange("whatsapp", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="whatsapp">
                          Whatsapp
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Select
                          id="country"
                          className={`form-control text-capitalize`}
                          value={formData?.country}
                          name="country"
                          multiple=""
                          onChange={(e) =>
                            handleFieldChange("country", e.target.value)
                          }
                        >
                          <option value="">country</option>
                          {chosenCountries?.map((country) => (
                            <option key={country} value={country}>
                              {country}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="city"
                          value={formData?.city}
                          placeholder="city"
                          name="city"
                          onChange={(e) =>
                            handleFieldChange("city", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="city">
                          City
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="number"
                          id="zip"
                          value={formData?.zip}
                          placeholder="zip"
                          name="zip"
                          onChange={(e) =>
                            handleFieldChange("zip", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="zip">
                          Zip Code
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="state"
                          value={formData?.state}
                          placeholder="state"
                          name="state"
                          onChange={(e) =>
                            handleFieldChange("state", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="state">
                          State
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={12} className="pt-3">
                      <h5>More Details</h5>
                      <hr />
                    </Col>



                    <Col md={6}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="first_name"
                          value={formData?.first_name}
                          placeholder="first_name"
                          name="first_name"
                          onChange={(e) =>
                            handleFieldChange("first_name", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="first_name">
                          First Name
                        </Form.Label>
                      </Form.Group>
                    </Col>


                    <Col md={6}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="first_name"
                          value={formData?.first_name}
                          placeholder="first_name"
                          name="first_name"
                          onChange={(e) =>
                            handleFieldChange("first_name", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="first_name">
                          Last Name
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="job_title"
                          value={formData?.job_title}
                          placeholder="job_title"
                          name="job_title"
                          onChange={(e) =>
                            handleFieldChange("job_title", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="job_title">
                          Job Title
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Select
                          id="industry"
                          className={`form-control text-capitalize`}
                          value={formData?.industry}
                          name="industry"
                          multiple=""
                          onChange={(e) =>
                            handleFieldChange("industry", e.target.value)
                          }
                        >
                          <option value="">Industry</option>
                          {industries.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="url"
                          id="linkedin"
                          value={formData?.linkedin}
                          placeholder="linkedin"
                          name="linkedin"
                          onChange={(e) =>
                            handleFieldChange("linkedin", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="linkedin">
                          LinkedIn
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={12} className="d-flex justify-content-between gap-3">
                      <h6 className="theme-text-2 me-sm-3 mb-3 flex-grow-1">
                        Do you have any {demosAlias}, {coursesAlias}, {trainersAlias}, {institutesAlias}, or {reviewsAlias}
                        associated with this {item_type ? item_type : ""}, that
                        you already have on this website?
                      </h6>
                      <Button
                        className="clickable add-button mb-3 ms-auto d-flex"
                        onClick={() => {
                          const newField = { type: "", value: "" };
                          setRelationData([...relationData, newField]);
                        }}
                      >
                        <span className="addBttn d-block">+</span>
                      </Button>
                    </Col>
                    <Col md={12}>
                      {relationData?.map((field, index) => (
                        <DynamicRelationFieldEdit
                          key={index}
                          fields={relationData}
                          field={field}
                          index={index}
                          onFieldChange={(newFields) => {
                            setRelationData(newFields);
                          }}
                          fieldLabels={{
                            valueInput: "Item ID",
                          }}
                          selectOptions={[
                            { value: "demo", label: demoAlias },
                            { value: "course", label: courseAlias },
                            { value: "trainer", label: trainerAlias },
                            { value: "institute", label: instituteAlias },
                            { value: "review", label: reviewAlias },
                          ]}
                        />
                      ))}
                    </Col>

                    <Col md={12} className="mt-3">
                      <Link
                        to={`/${item_type}/items`}
                        className="btn prev demosEditBtn "
                      >
                        Discard
                      </Link>
                      <Button
                        type="button"
                        className="next demosEditBtn "
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </fieldset>
              </div>
            </Form>
          </Container>
        </div>
      )}
    </>
  );
};

export default withRouter(AddEditItems);
