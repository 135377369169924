import axios from "axios";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { PencilSquare, PlusCircle, Share, Trash } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Actions from "../../../constants/baseUrl";
import COURSEIMG from "../../../img/course.png";
import ConvertDateFormat from "../../../components/convertDateFormat/convertDateFormat";
import ConvertMinutesToTime from "../../../components/convertFromMinutes/convertFromMinutes  ";
import ConvertTo12HourFormat from "../../../components/convertTo12HourFormat/convertTo12HourFormat";
import CustomLoader from "../../../components/loader/loader";
import SubDomainRouter from "../../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../../components/useUserDetails/useTokenDetails";
import useUserData from "../../../components/useUserDetails/useUserDetails";
import UTCLocalTimeConverter from "../../../components/UTCLocalTimeConverter/UTCLocalTimeConverter";

const MyDemos = () => {
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [trainerItems, setTrainerItems] = useState([]);
  const [userItems, setUserItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayedTrainerCards, setDisplayedTrainerCards] = useState(6);
  const [displayedUserCards, setDisplayedUserCards] = useState(6);

  const userData = useUserData({ setLoading });
  const role = userData.role?.toLowerCase();

  const getTrainerDemos = async () => {
    setLoading(true);
    const data = {
      userid: myUserId,
    };
    try {
      if (role === "admin" || role === "siteadmin" || role === "superadmin") {
        const response = await axios.get(`${Actions.BASE_URL}demos`);
        // Sort data  in ascending order
        const sortedData = response.data.sort((a, b) =>
          moment(a.start_date).diff(moment(b.start_date))
        );
        setTrainerItems(sortedData);
      } else {
        const response = await axios.post(
          `${Actions.BASE_URL}dashboard/MyDemos?userid=${myUserId}`,
          data
        );
        // Sort data  in ascending order
        const sortedData = response.data.sort((a, b) =>
          moment(a.start_date).diff(moment(b.start_date))
        );
        setTrainerItems(sortedData);
      }
    } catch (error) {
      console.error("Error calling API:", error);
      toast.error("Something went wrong. Please Try again later.", {
        autoClose: 3000,
        hideProgressBar: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getUserDemos = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${Actions.BASE_URL}registrants/MyDemos/${myUserId}`
      );

      // Sort data  in ascending order
      const sortedData = response.data.sort((a, b) =>
        moment(a.start_date).diff(moment(b.start_date))
      );
      setUserItems(sortedData);
    } catch (error) {
      console.error("Error calling API:", error);
      toast.error("Something went wrong. Please Try again later.", {
        autoClose: 3000,
        hideProgressBar: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserDemos();
    // Trainer demos
    if (
      role === "admin" ||
      role === "siteadmin" ||
      role === "trainer" ||
      role === "superadmin" ||
      role === "training-coordinator"
    ) {
      getTrainerDemos();
    }
  }, [role]);

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            axios.delete(`${Actions.BASE_URL}demos/${id}`).then((res) => {
              getTrainerDemos();
            });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };
  const demosAlias = SubDomainRouter("demosAlias");

  // Define regular expressions for filtering
  const trainerItemList = (trainerItems) => {
    const handleShowMore = () => {
      setDisplayedTrainerCards((prevDisplayedCards) =>
        prevDisplayedCards + 3 <= trainerItems.length
          ? prevDisplayedCards + 3
          : trainerItems.length
      );
    };
    return (
      <>
        <Row className="my-4 justify-content-md-start justify-content-center">
          {trainerItems?.length !== 0 ? (
            trainerItems.slice(0, displayedTrainerCards)?.map((item) => (
              <Col
                xxl={3}
                xl={4}
                md={6}
                sm={10}
                className="mt-2 mb-3"
                key={item.id}
              >
                <Card className="demoCard">
                  <div className="itemImg">
                    <Link to={`/demos/details/${item.id}`} className="linkImg">
                      <img
                        src={item.listing_image_url || COURSEIMG}
                        className="img-fluid"
                        alt="imageDemo"
                      />
                    </Link>
                  </div>
                  <Card.Body className="itemInfo px-2 pb-3 d-flex flex-column justify-content-between py-1">
                    <div className="card-details text-center">
                      <h3 className="itemName text-left pt-2">
                        <Link
                          to={`/demos/details/${item.id}`}
                          className="detailLink text-left"
                        >
                          {item.title}
                        </Link>
                      </h3>

                      <Link
                        to={`/share/demo/${item.id}`}
                        className="entity-share-btn d-flex align-items-center cursor-pointer float-end"
                      >
                        <Share />
                      </Link>
                      <Card.Text className="card-text my-1 pb-0 text-left d-flex flex-wrap align-items-center justify-content-center">
                        <UTCLocalTimeConverter
                          zone="true"
                          showDate
                          utcDateTime={item?.start_date_time}
                        />
                        <span>
                          (
                          <ConvertMinutesToTime minutes={item.duration} />)
                        </span>
                      </Card.Text>
                      <hr className="mt-2 mb-1" />
                      <Card.Text className="card-text mb-0 pb-0 text-left text-overflow-desc p-0">
                        {item.description}
                      </Card.Text>
                    </div>

                    <div>
                      <div className="itemEdit d-flex flex-wrap justify-content-center mt-3 gap-2">
                        <Link
                          to={`/demos/details/${item.id}`}
                          className="actionBtn d-flex align-items-center justify-content-center text-center btn action cursor-pointer actionBtnMain"
                        >
                          View More
                        </Link>
                        <Link
                          to={`/registrants/${item.type}/${item.id}`}
                          className="actionBtn d-flex align-items-center justify-content-center text-center btn action cursor-pointer actionBtnMain"
                          title="review"
                        >
                          Registrants
                        </Link>
                      </div>
                      <div className="itemEdit d-flex flex-wrap justify-content-center mt-2 pt-md-1 mt-2 gap-2">
                        <Link
                          to={`/demos/clone/${item.id}`}
                          className="actionBtn d-flex align-items-center justify-content-center text-center btn action cursor-pointer flex-grow-1"
                        >
                          Clone
                        </Link>
                        <Link
                          to={`/demos/edit/${item.id}`}
                          className="actionBtn d-flex align-items-center justify-content-center text-center btn action cursor-pointer flex-grow-1"
                        >
                          <span className="d-sm-block d-none">Edit</span>
                          <PencilSquare className="d-sm-none d-block" />
                        </Link>
                        {role === "admin" ||
                          role === "siteadmin" ||
                          role === "superadmin" ? (
                          <Link
                            to="#"
                            className="actionDltBtn d-flex align-items-center justify-content-center text-center btn action cursor-pointer flex-grow-1"
                            onClick={() => deleteItem(item.id)}
                          >
                            <span className="d-sm-block d-none">Delete</span>
                            <Trash className="d-sm-none d-block" />
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <p>You don't have any {demosAlias ? demosAlias : "demos"} yet!</p>
          )}
        </Row>
        {!loading && displayedTrainerCards < trainerItems.length ? (
          <div className="text-center mb-5">
            <Button
              onClick={handleShowMore}
              className="btn actionBtnMore action cursor-pointer w-auto add-button"
            >
              Show More
            </Button>
          </div>
        ) : null}
      </>
    );
  };
  // Define regular expressions for filtering
  const userItemList = (userItems) => {
    const handleShowMore = () => {
      setDisplayedUserCards((prevDisplayedCards) =>
        prevDisplayedCards + 3 <= userItems.length
          ? prevDisplayedCards + 3
          : userItems.length
      );
    };
    return (
      <>
        <Row className="my-4 justify-content-md-start justify-content-center">
          {userItems?.length !== 0 ? (
            userItems.slice(0, displayedUserCards)?.map((item) => (
              <Col
                xxl={3}
                xl={4}
                md={6}
                sm={10}
                className="mt-2 mb-3"
                key={item.id}
              >
                <Card className="demoCard">
                  <div className="itemImg">
                    <Link to={`/demos/details/${item.id}`} className="linkImg">
                      <img
                        src={item.listing_image_url || COURSEIMG}
                        className="img-fluid"
                        alt="imageDemo"
                      />
                    </Link>
                  </div>
                  <Card.Body className="itemInfo px-2 pb-3 d-flex flex-column justify-content-between py-1">
                    <div className="card-details text-center">
                      <h3 className="itemName text-left pt-2">
                        <Link
                          to={`/demos/details/${item.id}`}
                          className="detailLink text-left"
                        >
                          {item.title}
                        </Link>
                      </h3>

                      <Link
                        to={`/share/demo/${item.id}`}
                        className="entity-share-btn d-flex align-items-center cursor-pointer float-end"
                        title="review"
                      >
                        <Share />
                      </Link>
                      <Card.Text className="card-text my-1 pb-0 text-left d-flex flex-wrap align-items-center justify-content-center">
                        <UTCLocalTimeConverter
                          zone="true"
                          showDate
                          utcDateTime={item?.start_date_time}
                        />
                        <span>
                          (
                          <ConvertMinutesToTime minutes={item.duration} />)
                        </span>
                      </Card.Text>
                      <hr className="mt-2 mb-1" />
                      <Card.Text className="card-text mb-0 pb-0 text-left text-overflow-desc p-0">
                        {item.description}
                      </Card.Text>
                    </div>

                    <div>
                      <div className="itemEdit d-flex flex-wrap justify-content-center mt-3 gap-2">
                        <Link
                          to={`/demos/details/${item.id}`}
                          className="actionBtn d-flex align-items-center justify-content-center text-center btn action cursor-pointer actionBtnMain"
                        >
                          View More
                        </Link>
                        <Link
                          to={`/demos/details/${item.id}?demo=reviews`}
                          className="actionBtn d-flex align-items-center justify-content-center text-center btn action cursor-pointer actionBtnMain"
                        >
                          Reviews
                        </Link>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <p>
              You don't have any scheduled {demosAlias ? demosAlias : "demos"}{" "}
              yet!
            </p>
          )}
        </Row>
        {!loading && displayedUserCards < userItems.length ? (
          <div className="text-center mb-5">
            <Button
              onClick={handleShowMore}
              className="btn actionBtnMore action cursor-pointer w-auto add-button"
            >
              Show More
            </Button>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className="my-demos mb-4">
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          {role === "admin" ||
            role === "siteadmin" ||
            role === "superadmin" ||
            role === "trainer" ||
            role === "training-coordinator" ? (
            <>
              <Row className="mx-0 align-items-start">
                <Col
                  md={12}
                  className="heading-text px-0 d-flex gap-1 justify-content-between flex-wrap align-items-start"
                >
                  <div>
                    <h4 className="theme-text-2 text-capitalize">
                      {role === "admin" ||
                        role === "siteadmin" ||
                        role === "superadmin"
                        ? `All ${demosAlias ? demosAlias : "demos"}`
                        : `My ${demosAlias ? demosAlias : "demos"}`}
                    </h4>
                    {role === "admin" ||
                      role === "siteadmin" ||
                      role === "superadmin" ? null : (
                      <p>
                        Check out the list of{" "}
                        {demosAlias ? demosAlias : "demos"} by you.
                      </p>
                    )}
                  </div>
                  <div className="d-flex justify-content-md-end px-0 gap-2 text-capitalize">
                    {role === "admin" ||
                      role === "siteadmin" ||
                      role === "superadmin" ? (
                      <Link className="add-button btn" to="/demos/past">
                        Past {demosAlias ? demosAlias : "demos"}
                      </Link>
                    ) : null}
                    <Link
                      className="add-button btn d-flex align-items-center"
                      to="/demos/add"
                    >
                      <PlusCircle />
                      <span className="d-lg-block text-nowrap d-none">
                        &nbsp; Schedule {demosAlias ? demosAlias : "demos"}
                      </span>
                    </Link>
                  </div>
                </Col>
              </Row>
              {trainerItemList(trainerItems)}
            </>
          ) : null}
          {role === "admin" ||
            role === "siteadmin" ||
            role === "superadmin" ? null : (
            <>
              <Row className="align-items-center mx-0">
                <Col
                  md={12}
                  className="heading-text px-0 d-flex gap-1 justify-content-between flex-wrap align-items-start"
                >
                  <div>
                    <h4 className="theme-text-2 text-capitalize">
                      {demosAlias ? demosAlias : "demos"} I am attending
                    </h4>
                    <p>
                      Check out the list of {demosAlias ? demosAlias : "demos"}{" "}
                      you are attending or schedule{" "}
                      {demosAlias ? demosAlias : "demos"}.
                    </p>
                  </div>
                  <div className="d-flex justify-content-md-end px-0 gap-2 text-capitalize">
                    <Link className="add-button btn" to="/demos">
                      View {demosAlias ? demosAlias : "demos"}
                    </Link>
                  </div>
                </Col>
              </Row>
              {userItemList(userItems)}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MyDemos;
