import Box from "@mui/material/Box";
import * as React from "react";
import { Link45deg } from "react-bootstrap-icons";

const LinksContent = ({ LinksData }) => {
  const hasLInk = LinksData?.some(
    (item) => (item.file_content !== null || item.url !== null) && (item.file_content !== "" || item.url !== "")
  );

  return (
    <div>
      <Box sx={{ minHeight: 400 }}>
        {hasLInk && LinksData ? (
          <Box columns={3} spacing={2}>
            {LinksData?.map((item, index) => (
              <div key={item?.id || index}>
                <a
                  href={item?.file_content}
                  target="_blank"
                  rel="noreferrer"
                  className="text-decoration-none d-flex align-items-center gap-2 theme-text-2 p-1 border-bottom mb-2 btn-link btn"
                >
                  <Link45deg className="h4" />
                  {item.field_name || item.file_content}
                </a>
              </div>
            ))}
          </Box>
        ) : (
          <p>No Links Found</p>
        )}
      </Box>
    </div>
  );
};

export default LinksContent;
