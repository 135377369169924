import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import {
  Check2Circle,
  PencilSquare,
  PlusCircle,
  Share,
  Trash,
} from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Helmet } from "react-helmet";
import { BiCopy } from "react-icons/bi";
import { Link } from "react-router-dom";
import API from "../../../components/API/API";
import CustomLoader from "../../../components/loader/loader";
import SocialShare from "../../../components/share/socialShare";
import SubDomainRouter from "../../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../../components/useUserDetails/useTokenDetails";
import useUserData from "../../../components/useUserDetails/useUserDetails";
import * as Actions from "../../../constants/baseUrl";
import ICON from "../../../img/page-icon.svg";
import { NetworkErrorAlert } from "../../../components/infoPopup/infoPopup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getCookie } from "../../../utils/cookies";

export default function DynamicHomepages() {
  const currentDomain = window.location.hostname;

  const myToken = getCookie("token");
  const userData = useUserData();
  const role = userData.role?.toLowerCase();
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const siteName = SubDomainRouter("title");
  const homePageRoute = SubDomainRouter("homePageRoute");

  const configData = useSelector((state) => state.hostSettings.value);

  const entities = "items";
  const entityType = "homepage";

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reqLoading, setReqLoading] = useState(false);
  const [displayedCards, setDisplayedCards] = useState(8);

  const populateList = async () => {
    setLoading(true);
    try {
      const data = await API("get", "items/" + entityType);
      setItems(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    populateList();
  }, []);

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            axios
              .delete(`${Actions.BASE_URL}${entities}/${entityType}/${id}`, {
                headers: {
                  Authorization: `Bearer ${myToken}`,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                populateList();
              });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };
  console.log(configData);

  // toggle handleHomepage
  const handleHomepageToggle = (path) => {
    const reqConfigData = {
      ...configData,
      appsettings: {
        ...configData.appsettings,
        homePageRoute: path,
      },
      hostsettings: {
        ...configData?.hostsettings,

        common: {
          ...configData?.hostsettings?.common,
          homePageRoute: path,
        },
      },
    };
    setReqLoading(true);
    axios
      .put(`${Actions.BASE_URL}configs/${configData.id}`, reqConfigData)
      .then((response) => {
        successAlert();
        populateList();
        window.location.reload();
      })
      .catch((error) => {
        if (error?.message === "Network Error") {
          NetworkErrorAlert(error?.message);
        } else {
          toast.error(error?.response?.data, {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
        }
      })
      .finally(() => setReqLoading(false));
  };

  const successAlert = (param) => {
    confirmAlert({
      customUI: () => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center my-2 text-capitalize">
              Default Homepage Updated successfully
            </h5>
          </div>
        );
      },
    });
  };

  // Define regular expressions for filtering
  const itemList = (items) => {
    const handleShowMore = () => {
      setDisplayedCards((prevDisplayedCards) =>
        prevDisplayedCards + 4 <= items?.length
          ? prevDisplayedCards + 4
          : items?.length
      );
    };

    return (
      <>
        {reqLoading ? (
          <>
            <span className="register-loader"> </span>
            <span className="spinnerOverlay" />
          </>
        ) : null}
        <Row className="justify-content-md-start justify-content-center">
          <Col className="mb-3" lg={10} sm={8}>
            <h3 className="pageSubHeading mb-0 text-sm-start text-center text-capitalize">
              Dynamic Home Pages
            </h3>
          </Col>
          {role === "admin" ||
          role === "siteadmin" ||
          role === "superadmin" ||
          role === "trainer" ||
          role === "training-coordinator" ? (
            <Col
              lg={2}
              sm={4}
              className="d-flex mb-3 align-items-center justify-content-sm-end justify-content-center"
            >
              <Link
                to={`/${entityType}s/add`}
                type="button"
                className="w-md-75 w-auto px-md-3 px-5 btn add-button d-flex align-items-center justify-content-center"
              >
                <PlusCircle /> &nbsp; Add
              </Link>
            </Col>
          ) : null}

          {loading ? (
            <CustomLoader />
          ) : (
            <>
              {items?.length > 0 ? (
                items?.slice(0, displayedCards)?.map((item, index) => (
                  <Col
                    key={index}
                    xxl={3}
                    xl={4}
                    md={6}
                    sm={10}
                    className="mt-2 mb-3"
                  >
                    <Card className="demoCard px-3 pt-3 pb-1">
                      <div
                        className="image-wrapper d-flex align-items-center justify-content-center position-relative"
                        style={{ height: "6rem" }}
                      >
                        <Image
                          src={ICON}
                          //   width={"100%"}
                          height={"100%"}
                          alt="img"
                        />
                      </div>
                      <h3 className="itemName my-2 px-3 text-center">
                        <Link
                          to={`/${entityType}s/details/${item.id}`}
                          className="detailLink text-center"
                        >
                          {item?.data?.page_title}
                        </Link>
                      </h3>
                      <div className="d-flex gap-2 justify-content-center align-items-center">
                        <SocialShare
                          url={`https://${currentDomain}/${item?.type}s/details/${item?.id}/`}
                          iconsSize={30}
                          title={item?.data?.title}
                          image={item?.data?.listing_image_url}
                          description={item?.data?.description}
                        />

                        <Link
                          to={`/share/items_${entityType}/${item.id}`}
                          className="entity-share-btn d-flex align-items-center cursor-pointer"
                          title="Share"
                        >
                          <Share />
                        </Link>
                      </div>
                      <div className="d-flex w-100 flex-wrap justify-content-center gap-2 mt-3">
                        <Link
                          to={`/${entityType}s/details/${item.id}`}
                          className="add-button btn w-100 flex-1 d-flex align-items-center justify-content-center text-center w-100 flex-grow-1 cursor-pointer"
                        >
                          View More
                        </Link>
                        {role === "admin" ||
                        role === "siteadmin" ||
                        role === "superadmin" ||
                        (item.userId &&
                          item.userId?.toLowerCase() === myUserId) ||
                        item?.data?.userId?.toLowerCase() === myUserId ? (
                          <>
                            <Link
                              to={`/${entityType}s/edit/${item.id}`}
                              className="add-button btn d-flex align-items-center justify-content-center text-center action cursor-pointer flex-grow-1"
                            >
                              <span className="d-sm-block d-none">Edit</span>
                              <PencilSquare className="d-sm-none d-block" />
                            </Link>
                            <Link
                              to={`/${entityType}s/clone/${item.id}`}
                              className="add-button btn d-flex align-items-center justify-content-center text-center action cursor-pointer flex-grow-1"
                            >
                              <span className="d-sm-block d-none">Clone</span>
                              <BiCopy className="d-sm-none d-block" />
                            </Link>
                            <Link
                              to="#"
                              className="actionDltBtn btn d-flex align-items-center justify-content-center text-center action cursor-pointer flex-grow-1"
                              onClick={() => deleteItem(item.id)}
                            >
                              <span className="d-sm-block d-none">Delete</span>
                              <Trash className="d-sm-none d-block" />
                            </Link>
                            {homePageRoute !==
                              `/${entityType}s/details/${item.id}` && (
                              <Button
                                onClick={() =>
                                  handleHomepageToggle(
                                    `/${entityType}s/details/${item.id}`
                                  )
                                }
                                className="add-button btn w-100 flex-1 d-flex align-items-center justify-content-center text-center w-100 flex-grow-1 cursor-pointer"
                              >
                                Set As Homepage
                              </Button>
                            )}
                            {homePageRoute ===
                              `/${entityType}s/details/${item.id}` && (
                              <span className="flex align-items-center gap-2 justify-content-center theme-text-2 fs-6 fw-medium btn w-fit mx-auto">
                                <Check2Circle className="theme-text fs-5" />{" "}
                                Site Homepage
                              </span>
                            )}
                          </>
                        ) : null}
                      </div>
                    </Card>
                  </Col>
                ))
              ) : (
                <p>No data found</p>
              )}
            </>
          )}
        </Row>
        {!loading && displayedCards < items?.length ? (
          <div className="text-center mb-5">
            <Button
              onClick={handleShowMore}
              className="btn actionBtnMore action cursor-pointer w-auto add-button"
            >
              Show More
            </Button>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          Dynamic Homepages | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      {loading ? <CustomLoader /> : itemList(items)}
    </>
  );
}
