import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Modal, Row } from "react-bootstrap";
import {
  Facebook,
  Globe,
  Instagram,
  Link45deg,
  Linkedin,
  Twitter
} from "react-bootstrap-icons";
import userImg from "../../img/user.png";
import TextFormatter from "../textFormat/textFormat";

const MemberDetailsPopup = (props) => {
  const [memberData, setMemberData] = useState(props.memberData);

  const onHide = () => {
    props.setMemberData({});
    setMemberData({});
    props.onHide();
  };

  useEffect(() => {
    setMemberData({ ...props.memberData });
  }, [props.memberData]);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-startup-popup"
      onHide={onHide}
      show={props.show}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-capitalize h5"
        >
          Member Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mx-0">
          <Col
            lg={3}
            className="ps-0 list-img-wrap position-relative min-h-full"
          >
            <Card>
              <Image
                className="modal-profile-img position-static rounded"
                src={memberData?.profile_image_url || userImg}
                alt="image"
                width={"100%"}
              />
            </Card>

            {memberData?.social_links?.length > 0 && (
              <Card className="mt-2 p-3">
                <h6 className="theme-text-2 text-center">Social Links</h6>
                <div className="social d-flex gap-2 flex-wrap align-items-center justify-content-center pt-2">
                  {memberData?.social_links?.map((social, index) => (
                    <>
                      {social.linkedIn && (
                        <a
                          key={index}
                          target="_blank"
                          href={`${social.linkedIn}`}
                          rel="noreferrer"
                        >
                          <Linkedin />
                        </a>
                      )}
                      {social.facebook && (
                        <a
                          key={index}
                          target="_blank"
                          href={`${social.facebook}`}
                          rel="noreferrer"
                        >
                          <Facebook />
                        </a>
                      )}
                      {social.instagram && (
                        <a
                          key={index}
                          target="_blank"
                          href={`${social.instagram}`}
                          rel="noreferrer"
                        >
                          <Instagram />
                        </a>
                      )}
                      {social.twitter && (
                        <a
                          key={index}
                          target="_blank"
                          href={`${social.twitter}`}
                          rel="noreferrer"
                        >
                          <Twitter />
                        </a>
                      )}
                      {social.website && (
                        <a
                          key={index}
                          target="_blank"
                          href={`${social.website}`}
                          rel="noreferrer"
                        >
                          <Globe />
                        </a>
                      )}
                      {social.others && (
                        <a
                          key={index}
                          target="_blank"
                          href={`${social.others}`}
                          rel="noreferrer"
                        >
                          <Link45deg />
                        </a>
                      )}
                    </>
                  ))}
                </div>
              </Card>
            )}
          </Col>
          <Col lg={9} className="min-h-full">
            <Card className="row h-100">
              <Card.Body>
                <Col lg={12} md={6} className="row mb-3">
                  <Col md={3}>
                    <Card.Text className="mb-0 fw-bold text-capitalize">
                      name
                    </Card.Text>
                  </Col>
                  <Col md={9}>
                    <Card.Text className="text-muted mb-0 d-flex align-items-center gap-2">
                      {memberData?.name ?? "Not Available"}
                    </Card.Text>
                  </Col>
                </Col>
                <hr />
                <Col lg={12} md={6} className="row mb-3">
                  <Col md={3}>
                    <Card.Text className="mb-0 fw-bold text-capitalize">
                      title
                    </Card.Text>
                  </Col>
                  <Col md={9}>
                    <Card.Text className="text-muted mb-0 d-flex align-items-center gap-2">
                      {memberData?.title ?? "Not Available"}
                    </Card.Text>
                  </Col>
                </Col>
                <hr />
                <Col lg={12} md={6} className="row mb-3">
                  <Col md={3}>
                    <Card.Text className="mb-0 fw-bold text-capitalize">
                      Gender
                    </Card.Text>
                  </Col>
                  <Col md={9}>
                    <Card.Text className="text-muted mb-0 d-flex align-items-center gap-2">
                      {memberData?.gender ?? "Not Available"}
                    </Card.Text>
                  </Col>
                </Col>
                <hr />
                <Col lg={12} md={6} className="row mb-3">
                  <Col md={3}>
                    <Card.Text className="mb-0 fw-bold text-capitalize">
                      phone
                    </Card.Text>
                  </Col>
                  <Col md={9}>
                    <Card.Text className="text-muted mb-0 d-flex align-items-center gap-2">
                      {memberData?.country_code + memberData?.phone ??
                        "Not Available"}
                    </Card.Text>
                  </Col>
                </Col>
                <hr />
                <Col lg={12} md={6} className="row mb-3">
                  <Col md={3}>
                    <Card.Text className="mb-0 fw-bold text-capitalize">
                      country
                    </Card.Text>
                  </Col>
                  <Col md={9}>
                    <Card.Text className="text-muted mb-0 d-flex align-items-center gap-2">
                      {memberData?.country ?? "Not Available"}
                    </Card.Text>
                  </Col>
                </Col>
                <hr />
                <Col lg={12}>
                  <Card.Text className="mb-0 fw-bold text-capitalize">
                    description
                  </Card.Text>
                </Col>
                <Col md={12}>
                  <Card.Text className="text-muted mb-0 text-wrap">
                    {memberData?.description ? (
                      <TextFormatter text={memberData?.description} />
                    ) : (
                      "Not Available"
                    )}
                  </Card.Text>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Button className="btn add-button float-end mt-3 px-4" onClick={onHide}>
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default MemberDetailsPopup;
