import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Search, X } from "react-bootstrap-icons";
import "./SearchBar.css";

function SearchBar({ setSearchQuery, className = "", placeholderText }) {
  const [searchedTerm, setSearchedTerm] = useState("");

  const handleInputChange = (event) => {
    const term = event.target.value;
    setSearchedTerm(term);
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (searchedTerm.trim() !== "") setSearchQuery(searchedTerm);
  };
  const handleClear = (event) => {
    event.preventDefault();
    setSearchedTerm("");
    setSearchQuery("");
  };

  const currentDomain = window.location.hostname;


  return (
    <Form onSubmit={handleClick} className={`form-container ${className}`}>
      <InputGroup className="mb-3 inputGroup w-100 rounded-pill">
        <Form.Control
          className="py-0"
          value={searchedTerm}
          onChange={handleInputChange}
          placeholder={
            currentDomain === "events.indianstartups.us" ||
              currentDomain === "events.indianstartups.com"
              ? "Search Events"
              : placeholderText || `Search...`
          }
        />
        {searchedTerm !== "" && (
          <Button
            className="bg-transparent p-0 m-0 border-0 me-sm-2 w-fit"
            onClick={handleClear}
          >
            <X className="fs-4" />
          </Button>
        )}
        <InputGroup.Text type="button" id="search" onClick={handleClick}>
          <Search />
        </InputGroup.Text>
      </InputGroup>
    </Form>
  );
}

export default SearchBar;
