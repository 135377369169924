/**
 * Utility functions for handling cookies
 */

/**
 * Set a cookie with the given name, value and options
 * @param {string} name - The name of the cookie
 * @param {string} value - The value of the cookie
 * @param {Object} options - Cookie options (expires, path, etc.)
 */
export const setCookie = (name, value, options = {}) => {
  const { expires, path = "/", domain, secure, sameSite = "Strict" } = options;

  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  if (expires) {
    if (typeof expires === "number") {
      const days = expires;
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      cookieString += `; expires=${date.toUTCString()}`;
    } else if (expires instanceof Date) {
      cookieString += `; expires=${expires.toUTCString()}`;
    }
  }

  if (path) cookieString += `; path=${path}`;
  if (domain) cookieString += `; domain=${domain}`;
  if (secure) cookieString += `; secure`;
  if (sameSite) cookieString += `; samesite=${sameSite}`;

  document.cookie = cookieString;
};

/**
 * Get a cookie value by name
 * @param {string} name - The name of the cookie to retrieve
 * @returns {string|null} The cookie value or null if not found
 */
export const getCookie = (name) => {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (decodeURIComponent(cookieName) === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
};

/**
 * Remove a cookie by name
 * @param {string} name - The name of the cookie to remove
 * @param {Object} options - Cookie options (path, domain)
 */
export const removeCookie = (name, options = {}) => {
  const { path = "/", domain } = options;
  setCookie(name, "", { expires: -1, path, domain });
};

/**
 * Check if a cookie exists
 * @param {string} name - The name of the cookie to check
 * @returns {boolean} True if the cookie exists, false otherwise
 */
export const hasCookie = (name) => {
  return getCookie(name) !== null;
};
