import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import API from "../../components/API/API";
import BannerUploaderEdit from "../../components/bannerUploaderEdit/bannerUploaderEdit";
import DynamicFileUploadEdit from "../../components/fileUploadFieldSet/fileUploadEdit";
import CustomLoader from "../../components/loader/loader";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import {
  chosenCities,
  chosenCountries,
  industries,
} from "../../constants/extras";
import withRouter from "../../constants/withRouter";
import UploadFile from "../../helper/UploadFile";

const AddEditIntern = (props) => {
  const item_id = props.params.id;

  const internAlias = SubDomainRouter("internAlias");

  const entities = "items";
  const entityType = "intern";
  const navigate = useNavigate();

  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  // states
  const [loading, setLoading] = useState(true);
  const [reqLoading, setReqLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  // check values
  const [hasValue, setHasValue] = useState(false);
  const [isValid, setIsValid] = useState(false);

  // * ==========================================================================
  const populateList = async (id) => {
    if (!id) {
      setLoading(false);
      return;
    }
    setLoading(true);

    //
    try {
      const response = await API("get", entities + `/${entityType}/` + id);
      setFormData(response?.data);
    } catch (error) {
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    populateList(item_id);
  }, [item_id]);
  // ========================

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...formData,
      [fieldName]: fieldValue,
    };
    setFormData(newState);
  };

  // Checking field values

  const step1Fields = [
    "first_name",
    "last_name",
    "email",
    "phone",
    "country",
    "whatsapp",
    "city",
    "job_title",
    "industry",
  ];
  const step2Fields = [
    "primary_skillset",
    "secondary_skillset",
    "experience",
    "qualification",
    "aim",
    "current_salary",
    "expected_salary",
  ];

  const areFieldsFilled = (fieldNames, formData) => {
    return fieldNames?.every((fieldName) =>
      formData[fieldName] ? formData[fieldName] : ""
    );
  };

  const isStep1FieldsFilled = areFieldsFilled(step1Fields, formData);
  const isStep2FieldsFilled = areFieldsFilled(step2Fields, formData);

  const handleNext = () => {
    const isStep1FieldsFilled = step1Fields.every((field) => formData[field]);

    if (step === 1 && isStep1FieldsFilled) {
      setStep((prevStep) => prevStep + 1);
      setHasValue(false);
      setIsValid(true);
    } else {
      setHasValue(true);
      setIsValid(true);
      window.scrollTo(0, 0);
    }
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  // * ==========================================================================
  const handleSubmit = async (event) => {
    if (step === 1 && !isStep2FieldsFilled) {
      setHasValue(true);
      setIsValid(true);
      window.scrollTo(0, 0);
      return;
    }

    setReqLoading(true);

    const reqData = {
      userId: myUserId,
      type: entityType,
      host: window.location.host,
      header: {
        host: window.location.host,
        userId: myUserId,
      },
      data: {
        userId: myUserId,
        ...formData,
      },
    };
    try {
      //
      if (item_id) {
        await API("put", entities + `/${entityType}/` + item_id, reqData);
        navigate(`/${entityType}/items`);
      } else {
        const response = await API("post", entities, reqData);
        navigate(`/share/items_${entityType}/${response.id}/`);
      }
      //
    } catch (error) {
      console.log(error.response);
    } finally {
      setReqLoading(false);
    }
  };
  // * ==========================================================================

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="demosAddFormSection demosEditSection">
          {reqLoading ? (
            <>
              <span className="register-loader"> </span>
              <span className="spinnerOverlay" />
            </>
          ) : null}

          <Container fluid="lg" className="formMain create my-3">
            <div className="demosAddFormHeader text-center">
              <h2 className="addFormHeading text-capitalize">
                {item_id ? "Edit" : "Add"}&nbsp;
                {internAlias ? internAlias : entityType}
              </h2>
              <div className="demosEditHeader text-center mb-3 mb-lg-5">
                <h5> {item_id ? "Edit" : "Add"} Details Below:</h5>
                <ul className="demosEditList">
                  <li>
                    <button
                      className={
                        step === 1 ? "active stepstriggers" : "stepstriggers"
                      }
                      onClick={() => setStep(1)}
                    >
                      <span>1</span>
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        step === 2 ? "active stepstriggers" : "stepstriggers"
                      }
                      onClick={() => setStep(2)}
                      disabled={!isStep1FieldsFilled}
                    >
                      <span>2</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            {/*  */}

            <Form
              id="add-demo-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="form-section addFormSection event-detail">
                {step === 1 && (
                  <fieldset className="wizard-fieldset demosAddForm position-relative overflow-hidden">
                    <Row className="mx-0 position-relative">
                      <Col md={12} className="position-relative">
                        {formData?.hero_image_url ||
                          formData.hero_image_content ? (
                          <Button
                            className="deleteBtn p-1 btn-danger text-white bg-danger h-auto w-auto position-absolute bottom-0 end-0 mb-5 me-4 z-3"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                hero_image_url: "",
                                hero_image_content: "",
                              });
                            }}
                          >
                            <Trash />
                          </Button>
                        ) : null}
                        <BannerUploaderEdit
                          className="item-hero-img"
                          key={formData?.hero_image_url}
                          label="Big Banner"
                          accept="image/png, image/jpg, image/jpeg"
                          imageType="hero_image"
                          placehold={
                            formData.hero_image_url ||
                            "https://placehold.co/1024x200"
                          }
                          state={formData}
                          setState={(data) => {
                            UploadFile(
                              formData.userId,
                              "hero_image",
                              data?.hero_image_content,
                              data?.hero_image_extension,
                              setReqLoading
                            ).then((url) => {
                              setFormData({
                                ...formData,
                                hero_image_url: url,
                                hero_image_content: "",
                              });
                            });
                          }}
                          imageURL={formData?.hero_image_url}
                          imageContent={formData?.hero_image_content}
                          imageExtension={formData?.hero_image_extension}
                        />
                      </Col>

                      <Col
                        lg={5}
                        md={6}
                        className="list-img-wrap position-relative"
                      >
                        {formData?.listing_image_url ||
                          formData.listing_image_content ? (
                          <Button
                            className="deleteBtn p-1 btn-danger text-white bg-danger h-auto w-auto position-absolute bottom-0 end-0 mb-4 me-4 z-3"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                listing_image_url: "",
                                listing_image_content: "",
                              });
                            }}
                          >
                            <Trash />
                          </Button>
                        ) : null}
                        <BannerUploaderEdit
                          className="item-list-img"
                          key={formData?.listing_image_url}
                          label="Small Banner"
                          accept="image/png, image/jpg, image/jpeg"
                          imageType="listing_image"
                          placehold={
                            formData.listing_image_url ||
                            "https://placehold.co/280x180"
                          }
                          state={formData}
                          setState={(data) => {
                            UploadFile(
                              formData.userId,
                              "listing_image",
                              data?.listing_image_content,
                              data?.listing_image_extension,
                              setReqLoading
                            ).then((url) => {
                              setFormData({
                                ...formData,
                                listing_image_url: url,
                                listing_image_content: "",
                              });
                            });
                          }}
                          imageURL={formData?.listing_image_url}
                          imageContent={formData?.listing_image_content}
                          imageExtension={formData?.listing_image_extension}
                        />
                      </Col>
                    </Row>
                    <Row className="mx-0n align-items-end">
                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="text"
                            className={`${isValid && hasValue && !formData.first_name
                              ? "border-danger"
                              : ""
                              }`}
                            id="first_name"
                            required
                            value={formData?.first_name}
                            placeholder="name"
                            name="first_name"
                            autoFocus
                            onChange={(e) =>
                              handleFieldChange("first_name", e.target.value)
                            }
                          />
                          <Form.Label
                            className="formLabel"
                            htmlFor="first_name"
                          >
                            First Name
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="text"
                            className={`${isValid && hasValue && !formData.last_name
                              ? "border-danger"
                              : ""
                              }`}
                            id="last_name"
                            required
                            value={formData?.last_name}
                            placeholder="name"
                            name="last_name"
                            onChange={(e) =>
                              handleFieldChange("last_name", e.target.value)
                            }
                          />
                          <Form.Label className="formLabel" htmlFor="last_name">
                            Last Name
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="email"
                            className={
                              isValid && hasValue && !formData.email
                                ? "border-danger"
                                : ""
                            }
                            id="email"
                            required
                            value={formData?.email}
                            placeholder="email"
                            name="email"
                            onChange={(e) =>
                              handleFieldChange("email", e.target.value)
                            }
                          />
                          <Form.Label className="formLabel" htmlFor="email">
                            Email
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="tel"
                            id="whatsapp"
                            value={formData?.whatsapp}
                            placeholder="whatsapp"
                            name="whatsapp"
                            onChange={(e) =>
                              handleFieldChange("whatsapp", e.target.value)
                            }
                          />
                          <Form.Label className="formLabel" htmlFor="whatsapp">
                            Whatsapp
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="tel"
                            className={
                              isValid && hasValue && !formData.phone
                                ? "border-danger"
                                : ""
                            }
                            id="phone"
                            required
                            value={formData?.phone}
                            placeholder="phone"
                            name="phone"
                            onChange={(e) =>
                              handleFieldChange("phone", e.target.value)
                            }
                          />
                          <Form.Label className="formLabel" htmlFor="phone">
                            Phone
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Select
                            id="country"
                            className={`form-control text-capitalize ${isValid && hasValue && !formData.country
                              ? "border-danger"
                              : ""
                              }`}
                            required
                            value={formData?.country}
                            name="country"
                            multiple=""
                            onChange={(e) =>
                              handleFieldChange("country", e.target.value)
                            }
                          >
                            <option value="">country</option>
                            {chosenCountries?.map((country) => (
                              <option key={country} value={country}>
                                {country}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Select
                            id="city"
                            className={`form-control text-capitalize ${isValid && hasValue && !formData.city
                              ? "border-danger"
                              : ""
                              }`}
                            required
                            value={formData?.city}
                            name="city"
                            multiple=""
                            onChange={(e) =>
                              handleFieldChange("city", e.target.value)
                            }
                          >
                            <option value="">city</option>
                            {chosenCities?.map((city) => (
                              <option key={city} value={city}>
                                {city}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col md={12} className="pt-3">
                        <h5>Professional Details</h5>
                        <hr />
                      </Col>

                      <Col md={12}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="text"
                            className={
                              isValid && hasValue && !formData.job_title
                                ? "border-danger"
                                : ""
                            }
                            id="job_title"
                            required
                            value={formData?.job_title}
                            placeholder="job_title"
                            name="job_title"
                            onChange={(e) =>
                              handleFieldChange("job_title", e.target.value)
                            }
                          />
                          <Form.Label className="formLabel" htmlFor="job_title">
                            Job Title
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Select
                            id="industry"
                            className={`form-control text-capitalize ${isValid && hasValue && !formData.industry
                              ? "border-danger"
                              : ""
                              }`}
                            required
                            value={formData?.industry}
                            name="industry"
                            multiple=""
                            onChange={(e) =>
                              handleFieldChange("industry", e.target.value)
                            }
                          >
                            <option value="">Industry</option>
                            {industries.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="url"
                            id="linkedin"
                            value={formData?.linkedin}
                            placeholder="linkedin"
                            name="linkedin"
                            onChange={(e) =>
                              handleFieldChange("linkedin", e.target.value)
                            }
                          />
                          <Form.Label className="formLabel" htmlFor="linkedin">
                            LinkedIn
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col md={12} className="mt-3">
                        <Link
                          to={`/${entityType}/items`}
                          className="btn prev demosEditBtn "
                          onClick={handleNext}
                        >
                          Discard
                        </Link>
                        <Button
                          type="button"
                          className="next demosEditBtn "
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </fieldset>
                )}

                {step === 2 && (
                  <fieldset className="wizard-fieldset demosAddForm">
                    <Row className="mx-0">
                      <Col md={12}>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            as={"textarea"}
                            rows={4}
                            id="bio"
                            value={formData?.bio}
                            placeholder="bio"
                            name="bio"
                            autoFocus
                            onChange={(e) =>
                              handleFieldChange("bio", e.target.value)
                            }
                          />
                          <Form.Label
                            className="formLabel text-capitalize"
                            htmlFor="bio"
                          >
                            {entityType} Bio
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            id="primary_skillset"
                            value={formData?.primary_skillset}
                            className={`form-control text-capitalize ${isValid && hasValue && !formData.primary_skillset
                              ? "border-danger"
                              : ""
                              }`}
                            placeholder="primary_skillset"
                            name="primary_skillset"
                            onChange={(e) =>
                              handleFieldChange(
                                "primary_skillset",
                                e.target.value
                              )
                            }
                          />
                          <Form.Label
                            className="formLabel text-capitalize"
                            htmlFor="primary_skillset"
                          >
                            primary skillset
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            id="secondary_skillset"
                            value={formData?.secondary_skillset}
                            className={`form-control text-capitalize ${isValid &&
                              hasValue &&
                              !formData.secondary_skillset
                              ? "border-danger"
                              : ""
                              }`}
                            placeholder="secondary_skillset"
                            name="secondary_skillset"
                            onChange={(e) =>
                              handleFieldChange(
                                "secondary_skillset",
                                e.target.value
                              )
                            }
                          />
                          <Form.Label
                            className="formLabel text-capitalize"
                            htmlFor="secondary_skillset"
                          >
                            secondary skillset
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="text"
                            id="experience"
                            className={`form-control text-capitalize ${isValid && hasValue && !formData.experience
                              ? "border-danger"
                              : ""
                              }`}
                            required
                            value={formData?.experience}
                            placeholder="experience"
                            name="experience"
                            onChange={(e) =>
                              handleFieldChange("experience", e.target.value)
                            }
                          />
                          <Form.Label
                            className="formLabel text-capitalize"
                            htmlFor="experience"
                          >
                            Years of Experience
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="text"
                            id="qualification"
                            className={`form-control text-capitalize ${isValid && hasValue && !formData.qualification
                              ? "border-danger"
                              : ""
                              }`}
                            required
                            value={formData?.qualification}
                            placeholder="qualification"
                            name="qualification"
                            onChange={(e) =>
                              handleFieldChange("qualification", e.target.value)
                            }
                          />
                          <Form.Label
                            className="formLabel text-capitalize"
                            htmlFor="qualification"
                          >
                            Academic Qualification
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Select
                            type="text"
                            id="aim"
                            className={`form-control text-capitalize ${isValid && hasValue && !formData.aim
                              ? "border-danger"
                              : ""
                              }`}
                            required
                            value={formData?.aim}
                            placeholder="aim"
                            name="aim"
                            onChange={(e) =>
                              handleFieldChange("aim", e.target.value)
                            }
                          >
                            <option value={""}>
                              What are you looking for?
                            </option>
                            <option value={"full time employment"}>
                              full time employment
                            </option>
                            <option value={"part time employment"}>
                              part time employment
                            </option>
                            <option value={"intern"}>intern</option>
                            <option value={"freelancer"}>freelancer</option>
                            <option value={"consultant"}>consultant</option>
                            <option value={"other"}>other</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            id="current_salary"
                            name="current_salary"
                            value={formData?.current_salary}
                            className={`form-control text-capitalize ${isValid && hasValue && !formData.current_salary
                              ? "border-danger"
                              : ""
                              }`}
                            type="text"
                            placeholder="da"
                            onChange={(e) =>
                              handleFieldChange(
                                "current_salary",
                                e.target.value
                              )
                            }
                          />
                          <Form.Label
                            className="form-label"
                            htmlFor="current_salary"
                          >
                            Current Salary
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            id="expected_salary"
                            name="expected_salary"
                            value={formData?.expected_salary}
                            className={`form-control text-capitalize ${isValid && hasValue && !formData.expected_salary
                              ? "border-danger"
                              : ""
                              }`}
                            type="text"
                            placeholder="da"
                            onChange={(e) =>
                              handleFieldChange(
                                "expected_salary",
                                e.target.value
                              )
                            }
                          />
                          <Form.Label
                            className="form-label"
                            htmlFor="expected_salary"
                          >
                            Expected Salary
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col lg={12} className="resume-upload-wrapper mt-3">
                        <h5>Upload Expertise Profile / Resume</h5>
                        <hr />
                        <div className="position-relative">
                          <DynamicFileUploadEdit
                            key={formData.resume_url}
                            field={formData?.resume_url}
                            onFieldChange={(data) => {
                              UploadFile(
                                formData.userId,
                                "document",
                                data?.file_content,
                                data?.file_extension,
                                setReqLoading
                              ).then((url) => {
                                setFormData({
                                  ...formData,
                                  resume_url: {
                                    url,
                                    file_extension: data?.file_extension,
                                    file_name: data?.documentType,
                                  },
                                });
                              });
                            }}
                            fileCategory="document"
                            acceptedTypes="application/pdf,application/msword,text/plain"
                            noteLabel="PDF/DOC/TXT"
                            inputIdPrefix={`documentUpload`}
                            hasDelete={false}
                          />

                          {formData?.resume_url ? (
                            <Button
                              className="deleteBtn p-1 btn-danger text-white bg-danger h-auto w-auto position-absolute bottom-0 end-0 mb-5 me-4 z-3"
                              onClick={() => {
                                setFormData({
                                  ...formData,
                                  resume_url: "",
                                });
                              }}
                            >
                              <Trash />
                            </Button>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={12} className="mt-3">
                        <Button
                          type="button"
                          className="previous-button demosEditBtn"
                          onClick={handlePrevious}
                        >
                          Previous
                        </Button>
                        <Button type="submit" className="next demosEditBtn">
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </fieldset>
                )}
              </div>
            </Form>
          </Container>
        </div>
      )}
    </>
  );
};

export default withRouter(AddEditIntern);
