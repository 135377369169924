import React from "react";
import { Col, Nav, Row } from "react-bootstrap";
import { Link, Outlet, useLocation } from "react-router-dom";
import "./campaignDashboard.css";
import { Helmet } from "react-helmet";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import useUserData from "../../components/useUserDetails/useUserDetails";

const CampaignDashboard = () => {
  const location = useLocation();
  const userData = useUserData();

  const siteName = SubDomainRouter("title");
  const role = userData.role;

  return (
    <>
      <Helmet>
        <title>
          Campaigns Dashboard | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      <div className="my-5 py-5">
        <Row className="campaigns-wrapper p-3 pb-5 mt-3 align-items-stretch">
          <Col
            lg={2}
            className="ps-0 pe-lg-2 pe-0 border-end border-opacity-50 my-sm-3 mb-4 overflow-x-lg-scroll lg-border"
          >
            <Nav
              variant="pills"
              className="flex-lg-column justify-content-lg-start justify-content-center flex-nowrap"
            >
              <Nav.Item>
                <Link
                  to={`/campaigns-dashboard/email-campaign`}
                  className={`nav-link text-nowrap ${location.pathname === "/campaigns-dashboard/email-campaign"
                    ? "active"
                    : ""
                    }`}
                >
                  email campaigns
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  to={`/campaigns-dashboard/templates`}
                  className={`nav-link text-nowrap ${location.pathname === "/campaigns-dashboard/templates"
                    ? "active"
                    : ""
                    }`}
                >
                  email templates
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  to={`/campaigns-dashboard/drafts`}
                  className={`nav-link text-nowrap ${location.pathname === "/campaigns-dashboard/drafts"
                    ? "active"
                    : ""
                    }`}
                >
                  email drafts
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  to={`/campaigns-dashboard/whatsapp-campaign`}
                  className={`nav-link text-nowrap ${location.pathname ===
                    "/campaigns-dashboard/whatsapp-campaign"
                    ? "active"
                    : ""
                    }`}
                >
                  whatsapp campaign
                </Link>
              </Nav.Item>
              {window.location.host === "ameerpetit.com" && (
                <>
                  <Nav.Item>
                    <Link
                      to={`/campaigns-dashboard/telegram-campaign`}
                      className={`nav-link text-nowrap ${location.pathname ===
                        "/campaigns-dashboard/telegram-campaign"
                        ? "active"
                        : ""
                        }`}
                    >
                      telegram campaign
                    </Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Link
                      to={`/campaigns-dashboard/sms-campaign`}
                      className={`nav-link text-nowrap ${location.pathname ===
                        "/campaigns-dashboard/sms-campaign"
                        ? "active"
                        : ""
                        }`}
                    >
                      SMS campaign
                    </Link>
                  </Nav.Item>
                </>
              )}
              {role === "admin" ||
                role === "siteadmin" ||
                role === "superadmin" ? (
                <Nav.Item>
                  <Link
                    to={`/campaigns-dashboard/newsletter`}
                    className={`nav-link text-nowrap ${location.pathname === "/campaigns-dashboard/newsletter"
                      ? "active"
                      : ""
                      }`}
                  >
                    Newsletter
                  </Link>
                </Nav.Item>
              ) : null}
              <Nav.Item>
                <Link
                  to={`/campaigns-dashboard/analytics`}
                  className={`nav-link text-nowrap ${location.pathname === "/campaigns-dashboard/analytics"
                    ? "active"
                    : ""
                    }`}
                >
                  Analytics
                </Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col xl={9} lg={10} className="pe-lg-0 mx-auto">
            <Outlet />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CampaignDashboard;
