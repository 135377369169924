import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, InputGroup, Table } from "react-bootstrap";
import {
  EyeFill,
  PencilSquare,
  Search,
  Trash3Fill,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import API from "../../components/API/API";
import CustomLoader from "../../components/loader/loader";
import * as Actions from "../../constants/baseUrl";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

const JSONSchemasListing = () => {
  const [loading, setLoading] = useState(true);
  const [schemas, setSchemas] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await API("get", `${Actions.BASE_URL}schemas`);
      setSchemas(response);
    } catch (error) {
      console.error("Error fetching Data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete this item?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            API("delete", `${Actions.BASE_URL}schemas/${id}`)
              .then((res) => {
                fetchData();
              })
              .catch((error) => {
                const errMsg = error.response?.data?.message
                  ? error.response.data.message
                  : "Something went wrong!";
                toast.error(errMsg);
              });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = schemas?.filter(
    (contact) =>
      contact?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.display_name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const sortedData = filteredData?.sort((a, b) => {
    const isAsc = order === "asc";
    return isAsc
      ? a[orderBy]?.localeCompare(b[orderBy])
      : b[orderBy]?.localeCompare(a[orderBy]);
  });

  return (
    <div className="my-demos mb-4 all-contacts">
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="my-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <h4 className="theme-text mb-1">SchemasListing</h4>

            <InputGroup className="mb-3 w-100 tableSearch ms-auto rounded-pill position-relative">
              <Form.Control
                className="rounded-pill"
                onChange={handleSearch}
                placeholder="Search"
              />
              <InputGroup.Text
                id="basic-addon1"
                className="border-0 bg-transparent position-absolute top-0 h-100 end-0"
              >
                <Search />
              </InputGroup.Text>
            </InputGroup>
          </div>

          {schemas.length > 0 ? (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 768 }}
                aria-label="simple table"
                className="striped rounded-0"
              >
                <TableHead className="bg-theme">
                  <TableRow>
                    <TableCell className="text-nowrap text-white fw-bold bg-theme">
                      <TableSortLabel
                        active={orderBy === "name"}
                        direction={orderBy === "name" ? order : "asc"}
                        onClick={() => handleSortRequest("name")}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className="text-nowrap text-white fw-bold bg-theme">
                      <TableSortLabel
                        active={orderBy === "display_name"}
                        direction={orderBy === "display_name" ? order : "asc"}
                        onClick={() => handleSortRequest("display_name")}
                      >
                        Display Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className="text-nowrap text-white text-center fw-bold bg-theme">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((item) => (
                      <TableRow key={item?.id}>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>{item?.display_name}</TableCell>
                        <TableCell>
                          <span className="d-flex align-items-center justify-content-center gap-2">
                            <Link
                              to={`/forms/${item?.id}/add`}
                              className="btn-link theme-text text-center me-1"
                            >
                              <EyeFill />
                            </Link>
                            <Link
                              to={`/schemas/edit/${item?.id}`}
                              className="btn-link theme-text text-center me-1"
                            >
                              <PencilSquare />
                            </Link>
                            <Link
                              to={"#"}
                              className="btn-link text-danger text-center p-0"
                              onClick={() => deleteItem(item.id)}
                            >
                              <Trash3Fill />
                            </Link>
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 50, 75, 100]}
                component="div"
                className="d-flex align-items-center justify-content-end"
                count={sortedData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </TableContainer>
          ) : (
            <p>No Schemas Founds...</p>
          )}
        </div>
      )}
    </div>
  );
};

export default JSONSchemasListing;
