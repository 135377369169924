import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import CustomLoader from "../loader/loader";
import TokenDetails from "../useUserDetails/useTokenDetails";
import { toast } from "react-toastify";
import { getCookie } from "../../utils/cookies";

const TemplateForm = (props) => {
  const listId = props?.params?.id;

  const myToken = getCookie("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(listId ? true : false);
  const [formData, setFormData] = useState({
    userId: myUserId,
    type: "template",
    template_name: "",
    template_email_body_url: "",
    subject: "",
    body_title: "",
    body_header: "",
    body: "",
    body_footer: "",
    body_plain_text: "",
  });

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...formData,
      [fieldName]: fieldValue,
    };
    setFormData(newState);
  };

  const populateForm = (listId) => {
    axios
      .get(`${Actions.BASE_URL}templates/${listId}`, formData, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setFormData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong. Please Try again later.", {
          autoClose: 3000,
          hideProgressBar: true,
          draggable: true,
        });
        console.log(error.response);
      });
  };

  useEffect(() => {
    if (listId) {
      populateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (listId) {
      axios
        .put(`${Actions.BASE_URL}templates/${listId}`, formData, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          navigate(`/campaigns-dashboard/templates`);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Something went wrong. Please Try again later.", {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
          console.log(error.response);
        });
    } else {
      axios
        .post(`${Actions.BASE_URL}templates`, formData, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          navigate(`/campaigns-dashboard/templates`);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Something went wrong. Please Try again later.", {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
          console.log(error.response);
        });
    }
  };

  const hasData =
    formData?.template_name === "" ||
    formData?.subject === "" ||
    formData?.body === "";

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg" className="formMain create my-5 py-5">
          <div className="demosAddFormHeader text-center mt-3">
            <h2 className="addFormHeading theme-text-2">Template Details</h2>
          </div>
          <fieldset className="wizard-fieldset show demosAddForm py-4">
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Row className="mx-0">
                <Col md={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Template Name"
                      className="italicInput"
                      name="template_name"
                      value={formData?.template_name}
                      id="template_name"
                      onChange={(e) =>
                        handleFieldChange("template_name", e.target.value)
                      }
                    />
                    <Form.Label className="form-label" htmlFor="template_name">
                      Template Name
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="url"
                      placeholder="Body Template URL"
                      className="italicInput"
                      name="template_email_body_url"
                      value={formData?.template_email_body_url}
                      id="template_email_body_url"
                      onChange={(e) =>
                        handleFieldChange(
                          "template_email_body_url",
                          e.target.value
                        )
                      }
                    />
                    <Form.Label
                      className="form-label"
                      htmlFor="template_email_body_url"
                    >
                      Body Template URL
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="text"
                      placeholder="subject"
                      className="form-control"
                      name="subject"
                      value={formData?.subject}
                      id="subject"
                      onChange={(e) =>
                        handleFieldChange("subject", e.target.value)
                      }
                    />
                    <Form.Label className="form-label" htmlFor="subject">
                      Subject
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Body Title"
                      className="form-control"
                      name="body_title"
                      value={formData?.body_title}
                      id="body_title"
                      onChange={(e) =>
                        handleFieldChange("body_title", e.target.value)
                      }
                    />
                    <Form.Label className="form-label" htmlFor="body_title">
                      Body Title
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      as={"textarea"}
                      rows={"4"}
                      placeholder="body_header"
                      className="max-h-auto"
                      name="body_header"
                      value={formData?.body_header}
                      id="body_header"
                      onChange={(e) =>
                        handleFieldChange("body_header", e.target.value)
                      }
                    />
                    <Form.Label className="form-label" htmlFor="body_header">
                      Body Header
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      as={"textarea"}
                      rows={"4"}
                      placeholder="body"
                      className="max-h-auto"
                      name="body"
                      value={formData?.body}
                      id="body"
                      onChange={(e) =>
                        handleFieldChange("body", e.target.value)
                      }
                    />
                    <Form.Label className="form-label" htmlFor="body">
                      Body Content
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      as={"textarea"}
                      rows={"4"}
                      placeholder="body_footer"
                      className="max-h-auto"
                      name="body_footer"
                      value={formData?.body_footer}
                      id="body_footer"
                      onChange={(e) =>
                        handleFieldChange("body_footer", e.target.value)
                      }
                    />
                    <Form.Label className="form-label" htmlFor="body_footer">
                      Body Footer
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      as={"textarea"}
                      rows={"4"}
                      placeholder="body_plain_text"
                      className="max-h-auto"
                      name="body_plain_text"
                      value={formData?.body_plain_text}
                      id="body_plain_text"
                      onChange={(e) =>
                        handleFieldChange("body_plain_text", e.target.value)
                      }
                    />
                    <Form.Label
                      className="form-label"
                      htmlFor="body_plain_text"
                    >
                      Body Plain Text
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col md={12} className="mt-3">
                  <Link
                    to="/campaigns-dashboard/templates"
                    className="previous-button add-button btn demosEditBtn w-auto"
                  >
                    Cancel
                  </Link>
                  <Button
                    type="submit"
                    disabled={hasData}
                    className="next add-button btn demosEditBtn w-auto"
                  >
                    Save Template
                  </Button>
                </Col>
              </Row>
            </Form>
          </fieldset>
        </Container>
      )}
    </>
  );
};

export default withRouter(TemplateForm);
