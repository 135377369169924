import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { configDefaultValues } from "../../constants/configDefaultValues";

const SubDomainRouter = (contentType, getNameAliasData = true) => {
  const configStatus = useSelector((state) => state.hostSettings.status);
  const config = useSelector((state) => state.hostSettings.value);

  const [content, setContent] = useState("");

  useEffect(() => {
    try {
      // Check if config is not null and contains the specified contentType
      if (config?.common?.nameAlias != null && configStatus !== 'failed' && configStatus !== 'loading' && config?.common?.nameAlias?.hasOwnProperty(contentType)) {

        if (
          getNameAliasData
        ) {

          setContent(config?.common?.nameAlias[contentType]);
        } else {
          setContent(config?.common[contentType]);
        }

      } else {
        // Use provided default values for missing fields

        setContent(configDefaultValues[contentType] || `${contentType}`);
      }
    } catch (error) {
      // Handle any other errors that might occur during content retrieval
      console.error("Error retrieving content:", error);
      // Set a default value or handle the error as needed
      setContent(`${contentType}`);
    }
  }, [config, configStatus, contentType]);

  return content;
};

export default SubDomainRouter;
