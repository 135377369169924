import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import API from "../../components/API/API";
import SearchedCard from "../../components/homeSegments/SearchedCard";
import Slider from "../../components/homeSegments/Slider";
import CustomLoader from "../../components/loader/loader";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import "./home.css";

const Home = () => {
  const [itemSearched, setItemSearched] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingHome, setLoadingHome] = useState(true);
  const [pageData, setPageData] = useState(null);

  const populateList = async () => {
    setLoading(true);
    if (searchQuery.trim() === "") {
      return null;
    }

    try {
      await API(`search/${searchQuery}`).then((response) => {
        setItemSearched(response);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    populateList();
  }, [searchQuery]);

  useEffect(() => {
    const fetchData = () => {
      API("get", "configs/pagesettings/home")
        .then((data) => {
          setPageData(data);
        })
        .catch((err) => console.error(err))
        .finally(() => setLoadingHome(false));
    };

    fetchData();
  }, []);

  const siteName = SubDomainRouter("title");

  return (
    <>
      <Helmet>
        <title>{siteName ? siteName : window.location.host}</title>
      </Helmet>

      {loadingHome ? (
        <CustomLoader />
      ) : (
        <>
          {pageData?.show_hero_slider && (
            <Slider setSearchQuery={setSearchQuery} searchQuery={searchQuery} />
          )}
          {searchQuery === "" ? (
            <>
              {pageData?.section_html !== "section_html" && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData?.section_html,
                  }}
                />
              )}
            </>
          ) : (
            <>
              {loading ? (
                <CustomLoader />
              ) : (
                <SearchedCard itemSearched={itemSearched} />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Home;
