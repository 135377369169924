import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import {
  Facebook,
  Globe,
  HeartPulse,
  Instagram,
  Link45deg,
  Linkedin,
  Twitter,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import ConvertMinutesToTime from "../../components/convertFromMinutes/convertFromMinutes  ";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TextFormatter from "../../components/textFormat/textFormat";

const DemoInfo = ({ entityData }) => {
  const eventAlias = SubDomainRouter("eventAlias");
  const instructorAlias = SubDomainRouter("instructorAlias");

  return (
    <Card className="border-top-0 rounded-top-0">
      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2 text-capitalize">
          {eventAlias ? eventAlias : "eventAlias"} Details:
        </h5>
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Description</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.description && entityData?.description !== "" ? (
                <TextFormatter text={entityData?.description} />
              ) : (
                "Not Available"
              )}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Duration</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.duration && entityData?.duration !== "" ? (
                <ConvertMinutesToTime minutes={entityData?.duration} />
              ) : (
                ""
              )}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Category</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {entityData?.category && entityData?.category !== ""
                ? entityData?.category
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Type</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {entityData?.subcategorylevel1 &&
              entityData?.subcategorylevel1 !== ""
                ? entityData?.subcategorylevel1
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Subcategory Level 2</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.subcategorylevel2 !== ""
                ? entityData?.subcategorylevel2
                : "Yet To Decide"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        {entityData?.subcategorylevel1?.toLowerCase() === "paid" && (
          <Link
            to={`/request-sponsor/${entityData?.type}/${entityData?.id}`}
            className="btn-link theme-text-2 text-decoration-none d-flex align-items-center mb-3"
          >
            <HeartPulse />
            &nbsp;
            <span className="text-decoration-underline">
              Request your employer or some one to sponsor / pay for this{" "}
              {eventAlias}
            </span>
          </Link>
        )}
        <Row>
          <Col md={12}>
            <div className="card-text text-muted mb-0 d-flex align-items-center gap-2">
              {entityData?.tags !== "" &&
                entityData?.tags?.map((tag, index) => (
                  <h3 className="tag-chip" key={index}>
                    #{tag}
                  </h3>
                ))}
            </div>
          </Col>
        </Row>
      </Card.Body>

      {entityData?.social_links?.length > 0 && (
        <Card.Body>
          <Row>
            <Col md={3}>
              <h6 className="theme-text-2 text-capitalize">Follow us:</h6>
            </Col>
            <Col
              md={9}
              className="social d-flex gap-md-3 gap-2 flex-wrap align-items-center"
            >
              {entityData?.social_links?.map((social, index) => (
                <>
                  {social.linkedIn && (
                    <a
                      key={index}
                      target="_blank"
                      href={`${social.linkedIn}`}
                      rel="noreferrer"
                    >
                      <Linkedin />
                    </a>
                  )}
                  {social.facebook && (
                    <a
                      key={index}
                      target="_blank"
                      href={`${social.facebook}`}
                      rel="noreferrer"
                    >
                      <Facebook />
                    </a>
                  )}
                  {social.instagram && (
                    <a
                      key={index}
                      target="_blank"
                      href={`${social.instagram}`}
                      rel="noreferrer"
                    >
                      <Instagram />
                    </a>
                  )}
                  {social.twitter && (
                    <a
                      key={index}
                      target="_blank"
                      href={`${social.twitter}`}
                      rel="noreferrer"
                    >
                      <Twitter />
                    </a>
                  )}
                  {social.website && (
                    <a
                      key={index}
                      target="_blank"
                      href={`${social.website}`}
                      rel="noreferrer"
                    >
                      <Globe />
                    </a>
                  )}
                  {social.others && (
                    <a
                      key={index}
                      target="_blank"
                      href={`${social.others}`}
                      rel="noreferrer"
                    >
                      <Link45deg />
                    </a>
                  )}
                </>
              ))}
            </Col>
          </Row>
        </Card.Body>
      )}

      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2 text-capitalize">
          {instructorAlias} Details:
        </h5>
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              {instructorAlias} Name
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.instructor_first_name ||
              entityData?.instructor_last_name
                ? `${entityData?.instructor_first_name} ${entityData?.instructor_last_name}`.trim()
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              {instructorAlias} BIO
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.instructor_bio ? (
                <TextFormatter text={entityData?.instructor_bio} />
              ) : (
                "Not Available"
              )}
            </Card.Text>
          </Col>
        </Row>
        <hr />
      </Card.Body>
      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2">Organizer Details:</h5>
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Organizer Name</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.organizer_first_name ||
              entityData?.organizer_last_name
                ? `${entityData?.organizer_first_name} ${entityData?.organizer_last_name}`.trim()
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Company Name</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.organizer_company_name &&
              entityData?.organizer_company_name !== ""
                ? entityData?.organizer_company_name
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Website</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.organizer_website &&
              entityData?.organizer_website.trim() !== "" ? (
                <a
                  href={entityData?.organizer_website}
                  target="_blank"
                  rel="noreferrer"
                >
                  {entityData?.organizer_website}
                </a>
              ) : (
                "Not Available"
              )}
            </Card.Text>
          </Col>
        </Row>
        <hr />
      </Card.Body>

      {(entityData?.sponsor_first_name || entityData?.sponsor_company_name) && (
        <Card.Body>
          <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2">Sponsor Details:</h5>

          {entityData?.sponsor_first_name &&
            entityData.sponsor_first_name !== "" && (
              <>
                <Row>
                  <Col md={3}>
                    <Card.Text className="mb-0 fw-bold">Sponsor Name</Card.Text>
                  </Col>
                  <Col md={9}>
                    <Card.Text className="text-muted mb-0">
                      {entityData.sponsor_first_name}
                    </Card.Text>
                  </Col>
                </Row>
                <hr />
              </>
            )}

          {entityData?.sponsor_company_name &&
            entityData.sponsor_company_name !== "" && (
              <>
                <Row>
                  <Col md={3}>
                    <Card.Text className="mb-0 fw-bold">
                      Sponsor Company
                    </Card.Text>
                  </Col>
                  <Col md={9}>
                    <Card.Text className="text-muted mb-0">
                      {entityData.sponsor_company_name}
                    </Card.Text>
                  </Col>
                </Row>
                <hr />
              </>
            )}

          {entityData?.sponsor_logo_url &&
            entityData.sponsor_logo_url !== "" && (
              <>
                <img
                  src={entityData?.sponsor_logo_url}
                  width={120}
                  alt="Invalid Logo"
                />
                <hr />
              </>
            )}

          <Row>
            <Col md={3}>
              <Card.Text className="mb-0 fw-bold">Company Name</Card.Text>
            </Col>
            <Col md={9}>
              <Card.Text className="text-muted mb-0">
                {entityData.sponsor_company_name || "Not Available"}
              </Card.Text>
            </Col>
          </Row>
          <hr />

          <Row>
            <Col md={3}>
              <Card.Text className="mb-0 fw-bold">Website</Card.Text>
            </Col>
            <Col md={9}>
              <Card.Text className="text-muted mb-0">
                {entityData.sponsor_website || "Not Available"}
              </Card.Text>
            </Col>
          </Row>
        </Card.Body>
      )}
    </Card>
  );
};

export default DemoInfo;
