import React from "react";
import { convertDateTimeToLocal } from "../../helper/timezoneConversions";

const parseDateTime = (dateTime) => {
  if (!dateTime) return null;

  if (dateTime.includes("T")) {
    // Handle ISO format
    return new Date(dateTime);
  }

  // Handle "MM/DD/YYYY HH:mm:ss" format
  const [datePart, timePart] = dateTime.split(" ");
  const [month, day, year] = datePart.split("/").map(Number);
  const [hours, minutes, seconds] = timePart.split(":").map(Number);
  return new Date(year, month - 1, day, hours, minutes, seconds);
};

const UTCLocalTimeConverter = ({ utcDateTime, zone = false, showDate = false, showTime = true }) => { 
  
  const parsedDate = parseDateTime(utcDateTime);
  if (!parsedDate || isNaN(parsedDate.getTime())) return <span>Invalid Date</span>;

  const { localDate, localTime } = convertDateTimeToLocal(parsedDate.toISOString());

  const formattedLocalDate = new Date(localDate)?.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: '2-digit' });

  const userTimeZone = Intl.DateTimeFormat(undefined, {
    timeZoneName: "long",
  })
    ?.formatToParts(new Date(`${localDate} ${localTime}`))
    ?.find((part) => part.type === "timeZoneName")?.value;

  return (
    <>
      <span>{showDate && formattedLocalDate} {showTime && showDate ? "-" : null} {showTime && localTime}</span>
      {zone && (
        <span className="flex-grow-1 w-100 m-0 d-block">
          (<b className="text-nowrap">{userTimeZone}</b>)
        </span>
      )}
    </>
  );
};

export default UTCLocalTimeConverter;
