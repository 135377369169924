// Import necessary dependencies
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import API from "../../../components/API/API";
import { SuccessAlert } from "../../../components/generalMsgModal/Alerts";
import TokenDetails from "../../../components/useUserDetails/useTokenDetails";

const APIIntegrations = () => {
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [formData, setFormData] = useState(null);
  const [reqLoading, setReqLoading] = useState(false);

  const handleFieldChange = (fieldValue, fieldName) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const populateList = () => {
    try {
      API("get", `thirdpartyapis`).then((res) => {
        setFormData({
          id: res?.[0]?.id,
          zoom_email: Object.values(res?.[0]?.zoom)[0]?.email,
          zoom_account_id: Object.values(res?.[0]?.zoom)[0]?.accountId,
          zoom_client_id: Object.values(res?.[0]?.zoom)[0]?.clientId,
          zoom_client_secret: Object.values(res?.[0]?.zoom)[0]?.clientSecret,

          paypal_email: res?.[0]?.paypal,

          google_meet_default_organizer_display_name:
            res?.[0]?.google_meet?.default?.defaultOrganizerDisplayName,
          google_meet_default_organizer_email:
            res?.[0]?.google_meet?.default?.defaultOrganizerEmail,
          google_meet_impersonated_user_email:
            res?.[0]?.google_meet?.default?.impersonatedUserEmail,

          brevo_smtp_server: res?.[0]?.brevo?.default?.smtpserver,
          brevo_login: res?.[0]?.brevo?.default?.login,
          brevo_password: res?.[0]?.brevo?.default?.password,
          brevo_fromEmail: res?.[0]?.brevo?.default?.fromEmail,
          brevo_fromName: res?.[0]?.brevo?.default?.fromName,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    populateList();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setReqLoading(true);

    const reqData = {
      userId: myUserId,
      zoom: {
        [formData?.zoom_email]: {
          name: "zoom",
          email: formData?.zoom_email,
          accountId: formData?.zoom_account_id,
          clientId: formData?.zoom_client_id,
          clientSecret: formData?.zoom_client_secret,
        },
      },
      paypal: formData?.paypal_email,
      brevo: {
        default: {
          smtpserver: formData?.brevo_smtp_server,
          login: formData?.brevo_login,
          password: formData?.brevo_password,
          fromEmail: formData?.brevo_fromEmail,
          fromName: formData?.brevo_fromName,
        },
      },
      google_meet: {
        default: {
          defaultOrganizerDisplayName:
            formData?.google_meet_default_organizer_display_name,
          defaultOrganizerEmail: formData?.google_meet_default_organizer_email,
          impersonatedUserEmail: formData?.google_meet_impersonated_user_email,
        },
      },
    };

    API("put", `thirdpartyapis/${formData?.id}`, reqData)
      .then((res) => {
        SuccessAlert("Keys Updated Successfully");
      })
      .catch((error) => {
        console.error("API request failed:", error);
      })
      .finally(() => {
        setReqLoading(false);
      });
  };

  return (
    <Container
      fluid="lg"
      className="d-flex align-items-center justify-content-center flex-column api_integrations"
    >
      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}

      <div className="header text-center">
        <h3 className="theme-text-2 text-center mb-0">API Integrations</h3>
      </div>
      <Form className="mt-sm-4 demosViewMoreForm" onSubmit={handleSubmit}>
        <Row className="pb-2 mx-0">
          <Col md={12}>
            <div className="d-flex justify-content-between align-items-baseline">
              <h5 className="mb-0">Zoom</h5>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
            </div>
            <hr className="mt-2" />
            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="email"
                id="zoom_email"
                required
                placeholder="placeholder text"
                value={formData?.zoom_email}
                name="zoom_email"
                onChange={(e) =>
                  handleFieldChange(e.target.value, "zoom_email")
                }
              />
              <Form.Label className="formLabel" htmlFor="zoom_email">
                Email
              </Form.Label>
            </Form.Group>

            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="password"
                id="zoom_account_id"
                required
                placeholder="placeholder text"
                value={formData?.zoom_account_id}
                name="zoom_account_id"
                onChange={(e) =>
                  handleFieldChange(e.target.value, "zoom_account_id")
                }
              />
              <Form.Label className="formLabel" htmlFor="zoom_account_id">
                Zoom Account ID
              </Form.Label>
            </Form.Group>

            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="password"
                id="zoom_client_id"
                required
                placeholder="placeholder text"
                value={formData?.zoom_client_id}
                name="zoom_client_id"
                onChange={(e) =>
                  handleFieldChange(e.target.value, "zoom_client_id")
                }
              />
              <Form.Label className="formLabel" htmlFor="zoom_client_id">
                Client ID
              </Form.Label>
            </Form.Group>

            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="password"
                id="zoom_client_secret"
                required
                placeholder="placeholder text"
                value={formData?.zoom_client_secret}
                name="zoom_client_secret"
                onChange={(e) =>
                  handleFieldChange(e.target.value, "zoom_client_secret")
                }
              />
              <Form.Label className="formLabel" htmlFor="zoom_client_secret">
                Client Secret
              </Form.Label>
            </Form.Group>
          </Col>

          <Col md={12}>
            <div className="d-flex justify-content-between align-items-baseline mt-3">
              <h5 className="mb-0">Google Meet</h5>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
            </div>
            <hr className="mt-2" />

            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="text"
                id="google_meet_default_organizer_display_name"
                required
                placeholder="placeholder text"
                value={formData?.google_meet_default_organizer_display_name}
                name="google_meet_default_organizer_display_name"
                onChange={(e) =>
                  handleFieldChange(
                    e.target.value,
                    "google_meet_default_organizer_display_name"
                  )
                }
              />
              <Form.Label
                className="formLabel text-capitalize"
                htmlFor="google_meet_default_organizer_display_name"
              >
                default organizer display name
              </Form.Label>
            </Form.Group>

            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="text"
                id="google_meet_default_organizer_email"
                required
                placeholder="placeholder text"
                value={formData?.google_meet_default_organizer_email}
                name="google_meet_default_organizer_email"
                onChange={(e) =>
                  handleFieldChange(
                    e.target.value,
                    "google_meet_default_organizer_email"
                  )
                }
              />
              <Form.Label
                className="formLabel text-capitalize"
                htmlFor="google_meet_default_organizer_email"
              >
                default organizer email
              </Form.Label>
            </Form.Group>

            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="email"
                id="google_meet_impersonated_user_email"
                required
                placeholder="placeholder text"
                value={formData?.google_meet_impersonated_user_email}
                name="google_meet_impersonated_user_email"
                onChange={(e) =>
                  handleFieldChange(
                    e.target.value,
                    "google_meet_impersonated_user_email"
                  )
                }
              />
              <Form.Label
                className="formLabel text-capitalize"
                htmlFor="google_meet_impersonated_user_email"
              >
                impersonated user email
              </Form.Label>
            </Form.Group>
          </Col>

          <Col md={12}>
            <div className="d-flex justify-content-between align-items-baseline mt-3">
              <h5 className="mb-0">Bravo</h5>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
            </div>
            <hr className="mt-2" />

            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="text"
                id="brevo_smtp_server"
                required
                placeholder="placeholder text"
                value={formData?.brevo_smtp_server}
                name="brevo_smtp_server"
                onChange={(e) =>
                  handleFieldChange(e.target.value, "brevo_smtp_server")
                }
              />
              <Form.Label
                className="formLabel text-capitalize"
                htmlFor="brevo_smtp_server"
              >
                SMTP server
              </Form.Label>
            </Form.Group>

            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="email"
                id="brevo_login"
                required
                placeholder="placeholder text"
                value={formData?.brevo_login}
                name="brevo_login"
                onChange={(e) =>
                  handleFieldChange(e.target.value, "brevo_login")
                }
              />
              <Form.Label
                className="formLabel text-capitalize"
                htmlFor="brevo_login"
              >
                login email
              </Form.Label>
            </Form.Group>

            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="password"
                id="brevo_password"
                required
                placeholder="placeholder text"
                value={formData?.brevo_password}
                name="brevo_password"
                onChange={(e) =>
                  handleFieldChange(e.target.value, "brevo_password")
                }
              />
              <Form.Label
                className="formLabel text-capitalize"
                htmlFor="brevo_password"
              >
                password
              </Form.Label>
            </Form.Group>

            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="email"
                id="brevo_fromEmail"
                required
                placeholder="placeholder text"
                value={formData?.brevo_fromEmail}
                name="brevo_fromEmail"
                onChange={(e) =>
                  handleFieldChange(e.target.value, "brevo_fromEmail")
                }
              />
              <Form.Label
                className="formLabel text-capitalize"
                htmlFor="brevo_fromEmail"
              >
                from email
              </Form.Label>
            </Form.Group>

            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="text"
                id="brevo_fromName"
                required
                placeholder="placeholder text"
                value={formData?.brevo_fromName}
                name="brevo_fromName"
                onChange={(e) =>
                  handleFieldChange(e.target.value, "brevo_fromName")
                }
              />
              <Form.Label
                className="formLabel text-capitalize"
                htmlFor="brevo_fromName"
              >
                from name
              </Form.Label>
            </Form.Group>
          </Col>

          <Col md={12}>
            <div className="d-flex justify-content-between align-items-baseline mt-3">
              <h5 className="mb-0">Paypal Email</h5>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
            </div>
            <hr className="mt-2" />

            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="text"
                id="paypal_email"
                required
                placeholder="placeholder text"
                name="paypal_email"
                value={formData?.paypal_email}
                onChange={(e) =>
                  handleFieldChange(e.target.value, "paypal_email")
                }
              />
              <Form.Label className="formLabel" htmlFor="paypal_email">
                Paypal Email
              </Form.Label>
            </Form.Group>
          </Col>

          <Col md={12}>
            <div className="d-flex justify-content-between align-items-baseline mt-3">
              <h5 className="mb-0">Stripe Api Key</h5>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
            </div>
            <hr className="mt-2" />
            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="password"
                id="stripe_api_key"
                required
                placeholder="placeholder text"
                name="stripe_api_key"
                onChange={(e) =>
                  handleFieldChange(e.target.value, "stripe_api_key")
                }
              />
              <Form.Label className="formLabel" htmlFor="stripe_api_key">
                Stripe Api Key
              </Form.Label>
            </Form.Group>
          </Col>

          <Col md={12}>
            <div className="d-flex justify-content-between align-items-baseline mt-3">
              <h5 className="mb-0">Sendgrid Api Key</h5>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
            </div>
            <hr className="mt-2" />
            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="password"
                id="sendgrid_api_key"
                required
                placeholder="placeholder text"
                name="sendgrid_api_key"
                onChange={(e) =>
                  handleFieldChange(e.target.value, "sendgrid_api_key")
                }
              />
              <Form.Label className="formLabel" htmlFor="sendgrid_api_key">
                Sendgrid Api Key
              </Form.Label>
            </Form.Group>
          </Col>
          <Col md={12} className="d-flex justify-content-end">
            <Button type="submit" className="add-button">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default APIIntegrations;
