import React, { useEffect, useState } from "react";
import "./Slider.css";
// Import Swiper styles
import { Container } from "react-bootstrap";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import API from "../API/API";
import SearchBar from "./SearchBar";
import Subscriber from "./Subscriber";

function Slider({ setSearchQuery, searchQuery }) {
  const [configData, setConfigData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = () => {
      API("get", "configs/pagesettings/home")
        .then((data) => {
          setConfigData(data);

          setLoading(false);
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    };

    fetchData();
  }, []);

  const defaultHeroSliderImages = configData?.heroSliderImages || {
    0: "/assets/images/SWIPERIMG3-min.jpg",
    1: "/assets/images/swiperIMG2-min.jpg",
    2: "/assets/images/swiperImg1-min.jpg",
  };

  return (
    <section className="heroSection position-relative">
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        navigation={false}
        speed={900}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[EffectFade, Autoplay, Navigation, Pagination]}
        className="mySwiper relative"
      >
        {loading ? (
          <>
            <span className="register-loader position-absolute bg-opacity-50 h-50 w-50"></span>
            <span className="spinnerOverlay position-absolute"></span>
          </>
        ) : (
          <>
            {defaultHeroSliderImages &&
            Object.keys(defaultHeroSliderImages).length > 0
              ? Object.keys(defaultHeroSliderImages)?.map((imageKey) => (
                  <SwiperSlide
                    key={imageKey}
                    className="d-flex align-items-center"
                    style={{
                      backgroundImage: `radial-gradient(#00000099, #0712128c, #00000074), url(${defaultHeroSliderImages[imageKey]})`,
                    }}
                  >
                    {" "}
                  </SwiperSlide>
                ))
              : null}
          </>
        )}
      </Swiper>
      <Container fluid="lg" className="heroContentWrap h-100">
        <div className="heroContent d-flex flex-column align-items-center justify-content-between h-100 py-5">
          <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center w-100">
            <h1 className="theme-text">
              {configData?.heroSliderHeading || "SEARCH DEMOS COURSES..."}
            </h1>
            <p>
              {configData?.heroSliderText ||
                "Our online courses are built in partnership with technology leaders and are designed to meet industry demands."}
            </p>
            <SearchBar className={"w-100"} setSearchQuery={setSearchQuery} />
            {searchQuery !== "" && (
              <p className="text-start mb-0 searchNote">
                Searching for&nbsp;
                <span className="text-decoration-underline">
                  {searchQuery}...
                </span>
              </p>
            )}
          </div>
          <Subscriber className="mb-2" />
        </div>
      </Container>
    </section>
  );
}

export default Slider;
