import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { Facebook, Globe, Instagram, Linkedin } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import INSTITUTEIMG from "../../img/institute.png";
import CustomLoader from "../../components/loader/loader";
import StarRating from "../../components/starRating/starRating";
import "./reviews.css";
import { Helmet } from "react-helmet";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";

function GetReview(props) {
  const entities = "reviews";

  const [loading, setLoading] = useState(true);
  const [reviewData, setReviewData] = useState({});
  const [entityData, setEntityData] = useState({});

  const populateList = async (entityId, entityType) => {
    try {
      await axios
        .get(`${Actions.BASE_URL}${entityType}s/${entityId}`)
        .then((response) => {
          // const tagsArray = Object.values(data.tags);
          setLoading(false);
          setEntityData(response.data);
        });
    } catch (error) {
      setLoading(false);
      console.error("There was an error", error);
    }
  };
  const getReview = async (id) => {
    try {
      await axios
        .get(`${Actions.BASE_URL}${entities}/${id}`)
        .then((response) => {
          // const tagsArray = Object.values(data.tags);
          setLoading(false);
          setReviewData(response.data);
        });
    } catch (error) {
      setLoading(false);
      console.error("There was an error", error);
    }
  };

  useEffect(() => {
    populateList(props.params.entityId, props.params.entityType);
    getReview(props.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.params.id]);

  const siteName = SubDomainRouter("title");
  return (
    <>
      <Helmet>
        <title>
          {reviewData?.title ? `${reviewData?.title}` : "Review Details"} |
          &nbsp;{siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      <Container fluid="lg" className="my-5 pt-5">
        {loading ? (
          <CustomLoader />
        ) : (
          <div className="demoProfile demosViewMoreSection overflow-hidden mx-auto">
            <div className="p-2 coverImg">
              <Image
                src={
                  entityData?.hero_image_url
                    ? entityData?.hero_image_url
                    : INSTITUTEIMG
                }
                width="100%"
                alt=""
              />
            </div>

            <Row className="profileHeader align-items-center position-relative py-2 mx-4">
              <Col lg={2} className="mr-3">
                <Image
                  className="profileImg"
                  src={
                    entityData?.listing_image_url
                      ? entityData?.listing_image_url
                      : INSTITUTEIMG
                  }
                  width="100%"
                  alt=""
                />
              </Col>
              <Col md={6} className="mt-lg-0 mt-5 pt-lg-0 pt-2 ps-4">
                <h4 className="demoTitle mb-2">
                  {entityData?.title ||
                    entityData?.name ||
                    `${entityData?.first_name} ${entityData?.last_name}`}
                  <br />
                  <span className="industry pt-2">
                    {reviewData.star_rating != null &&
                      reviewData.star_rating != "" ? (
                      <StarRating rating={reviewData.star_rating} />
                    ) : (
                      "Not Rated"
                    )}
                  </span>
                </h4>
              </Col>
              <Col
                lg={4}
                md={6}
                className="mt-lg-0 pt-lg-0 pt-2 social d-flex gap-md-3 gap-2 flex-wrap justify-content-center"
              >
                {entityData?.linkedin && (
                  <a
                    target="_blank"
                    href={`https://${entityData?.linkedin}`}
                    rel="noreferrer"
                  >
                    <Linkedin />
                  </a>
                )}
                {entityData?.facebook && (
                  <a
                    target="_blank"
                    href={`https://${entityData?.facebook}`}
                    rel="noreferrer"
                  >
                    <Facebook />
                  </a>
                )}
                {entityData?.instagram && (
                  <a
                    target="_blank"
                    href={`https://${entityData?.instagram}`}
                    rel="noreferrer"
                  >
                    <Instagram />
                  </a>
                )}
                {entityData?.website && (
                  <a
                    target="_blank"
                    href={`https://${entityData?.website}`}
                    rel="noreferrer"
                  >
                    <Globe />
                  </a>
                )}
              </Col>
            </Row>
            <Card className="m-4 mt-2">
              <Card.Body>
                {" "}
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="theme-text-2 m-0 text-capitalize">
                    {entityData?.type} Details
                  </h5>
                </div>
                <hr />
                <Row>
                  <Col md={3}>
                    <Card.Text className="mb-0 fw-bold">
                      {entityData?.title
                        ? "Title"
                        : entityData?.first_name ||
                          entityData?.last_name ||
                          entityData?.name
                          ? "Name"
                          : null}
                    </Card.Text>
                  </Col>
                  <Col md={9}>
                    <Card.Text className="text-muted mb-0">
                      {entityData?.title != "" ||
                        entityData?.name != "" ||
                        entityData?.first_name != "" ||
                        entityData?.last_name != "" ? (
                        <>
                          {entityData?.title ||
                            entityData?.name ||
                            `${entityData?.first_name} ${entityData?.last_name}`}
                        </>
                      ) : (
                        "Not Available"
                      )}
                    </Card.Text>
                  </Col>
                </Row>
                <hr />
                {entityData?.description != "" && (
                  <>
                    <Row>
                      <Col md={3}>
                        <Card.Text className="mb-0 fw-bold">
                          {entityData?.description
                            ? "Description"
                            : entityData?.bio
                              ? "BIO"
                              : entityData?.brief_two_liner_bio
                                ? "Biref BIO"
                                : null}
                        </Card.Text>
                      </Col>
                      <Col md={9}>
                        <Card.Text className="text-muted mb-0">
                          {entityData?.description != "" ||
                            entityData?.bio != "" ||
                            entityData?.brief_two_liner_bio != "" ? (
                            <>
                              {entityData?.description ||
                                entityData?.bio ||
                                entityData?.brief_two_liner_bio}
                            </>
                          ) : (
                            "Not Available"
                          )}
                        </Card.Text>
                      </Col>
                    </Row>
                    <hr />
                  </>
                )}
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="theme-text-2 m-0">Reviews And Ratings</h5>
                  <Link
                    to={`/reviews/${entityData?.type}/${entityData?.id}/add`}
                    className="actionBtn btn add-button cursor-pointer"
                  >
                    Add Review
                  </Link>
                </div>
                <hr />
                <Row>
                  <Col md={3}>
                    <Card.Text className="mb-0 fw-bold">
                      Reviwer's Name
                    </Card.Text>
                  </Col>
                  <Col md={9}>
                    <Card.Text className="text-muted mb-0">
                      {reviewData.reviewer_first_name} -{" "}
                      {reviewData.reviewer_last_name}
                      &nbsp; &nbsp;(
                      <b>
                        {reviewData.service_availed != "" &&
                          reviewData.service_availed === "yes"
                          ? "Sevice Availed"
                          : "Service Not Availed Yet"}
                      </b>
                      )
                    </Card.Text>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md={3}>
                    <Card.Text className="mb-0 fw-bold">Rated</Card.Text>
                  </Col>
                  <Col md={9}>
                    <Card.Text className="text-muted mb-0">
                      {reviewData.star_rating != "" ? (
                        <div className="d-flex align-items-baseline gap-2">
                          <StarRating rating={reviewData.star_rating} />{" "}
                          <h6>({reviewData.star_rating})</h6>
                        </div>
                      ) : (
                        "Not Available"
                      )}
                    </Card.Text>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md={3}>
                    <Card.Text className="mb-0 fw-bold">
                      Review Tiltle
                    </Card.Text>
                  </Col>
                  <Col md={9}>
                    <Card.Text className="text-muted mb-0">
                      {reviewData.review_title != ""
                        ? reviewData.review_title
                        : "Not Available"}
                    </Card.Text>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md={3}>
                    <Card.Text className="mb-0 fw-bold">
                      Review Comment
                    </Card.Text>
                  </Col>
                  <Col md={9}>
                    <Card.Text className="text-muted mb-0">
                      <q>
                        {reviewData.review_comments != ""
                          ? reviewData.review_comments
                          : "Not Available"}
                      </q>
                    </Card.Text>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        )}
      </Container>
    </>
  );
}

export default withRouter(GetReview);
