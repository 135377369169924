import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "react-oidc-context";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App.jsx";
import LoginSuccess from "./components/login/loginsuccess";
import { OIDC_CONFIG } from "./constants/baseUrl";
import "./index.css";
import AuthPage from "./pages/auth/auth";
import reportWebVitals from "./reportWebVitals";
import { store } from "./states/store";
import { ToastContainer } from "react-toastify";
import { getCookie } from "./utils/cookies.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
const token = getCookie("token");

root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider {...OIDC_CONFIG}>
        <Provider store={store}>
          {window.location.pathname.indexOf("loginsuccess") > -1 ? (
            <LoginSuccess />
          ) : (localStorage.getItem("isValidUser") === true ||
              localStorage.getItem("isValidUser") === "true") &&
            token ? (
            <App />
          ) : (
            <AuthPage />
          )}

          <ToastContainer
            limit={1}
            autoClose={2500}
            draggable
            hideProgressBar
            position="bottom-right"
            bodyStyle={{
              fontSize: "12px",
              margintop: "0px",
              marginbottom: "0px",
            }}
          />
        </Provider>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
