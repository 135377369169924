import React, { useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Share } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import DEMOIMG from "../../img/Digital-Skills-2.jpeg";
import COURSEIMG from "../../img/course.png";
import UTCLocalTimeConverter from "../UTCLocalTimeConverter/UTCLocalTimeConverter";
import ConvertMinutesToTime from "../convertFromMinutes/convertFromMinutes  ";
import RegisterModal from "../register/registerModal";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";

const SearchedCard = ({ itemSearched }) => {
  const navigate = useNavigate();

  const [modalData, setModalData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [displayEventCards, setDisplayEventCards] = useState(6);
  // const [displayDemoCards, setDisplayDemoCards] = useState(6);
  // const [displayCourseCards, setDisplayCourseCards] = useState(6);
  // const [displayInstituteCards, setDisplayInstituteCards] = useState(6);
  // const [displayTrainerCards, setDisplayTrainerCards] = useState(6);

  const eventsAlias = SubDomainRouter("eventsAlias");
  // const demosAlias = SubDomainRouter("demosAlias");
  // const coursesAlias = SubDomainRouter("coursesAlias");

  return (
    <Container fluid="lg">
      {/* events */}
      {itemSearched?.events?.length > 0 && (
        <Row className="justify-content-center py-5 mx-0">
          <Col lg={12}>
            <h2 className="main-heading text-center text-capitalize">
              {eventsAlias ? eventsAlias : "events"}
            </h2>
          </Col>
          {itemSearched?.events?.slice(0, displayEventCards)?.map((item) => (
            <Col xl={4} md={6} sm={10} className="mt-2 mb-3" key={item.id}>
              <Card className="demoCard">
                <div className="itemImg">
                  <Link to={`/events/details/${item.id}`} className="linkImg">
                    <img
                      src={
                        item.listing_image_url || item.hero_image_url || DEMOIMG
                      }
                      className="img-fluid"
                      alt="event"
                    />
                  </Link>
                </div>
                <Card.Body className="itemInfo px-2 pb-3 d-flex flex-column justify-content-between py-1">
                  <div className="card-details text-center">
                    <h3 className="itemName text-left mb-0">
                      <Link
                        to={`/events/details/${item.id}`}
                        className="detailLink text-left"
                      >
                        {item.title}
                      </Link>
                    </h3>

                    <Link
                      to={`/share/event/${item.id}`}
                      className="entity-share-btn d-flex align-items-center cursor-pointer float-end"
                      title="review"
                    >
                      <Share />
                    </Link>
                    <Card.Text className="card-text my-1 pb-0 text-left d-flex flex-wrap align-items-center justify-content-center">
                      <UTCLocalTimeConverter
                        showDate
                        utcDateTime={item?.start_date_time}
                      />
                      &nbsp;
                      <span>
                        (
                        <ConvertMinutesToTime minutes={item.duration} />)
                      </span>
                    </Card.Text>
                    <hr className="mt-2 mb-1" />
                    <Card.Text className="card-text mb-0 pb-0 text-left text-overflow-desc p-0">
                      {item.description}
                    </Card.Text>
                  </div>
                  <div className="itemEdit d-flex flex-wrap justify-content-center mt-3 gap-2">
                    <Link
                      to={`/events/details/${item.id}`}
                      className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                    >
                      View More
                    </Link>
                    <Button
                      onClick={() => {
                        setModalShow(true);
                        setModalData(item);
                      }}
                      className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                    >
                      Register
                    </Button>
                    <Link
                      to={`/events/details/${item.id}?event=reviews`}
                      className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                      title="review"
                    >
                      Reviews
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
          {displayEventCards < itemSearched?.events?.length && (
            <Col lg={12} className="d-flex justify-content-center">
              <Button
                onClick={() =>
                  setDisplayEventCards(displayEventCards + displayEventCards)
                }
                className="btn actionBtnMore action cursor-pointer w-auto text-center"
              >
                Show More
              </Button>
            </Col>
          )}
        </Row>
      )}
      {/* {itemSearched?.demos?.length > 0 && (
        <Row className="justify-content-center py-5 mx-0">
          <Col lg={12}>
            <h2 className="main-heading text-center text-capitalize">
              {demosAlias ? demosAlias : "demos"}
            </h2>
          </Col>
          {itemSearched?.demos?.slice(0, displayDemoCards)?.map((item) => (
            <Col xl={4} md={6} sm={10} className="mt-2 mb-3" key={item.id}>
              <Card className="demoCard">
                <div className="itemImg">
                  <Link to={`/demos/details/${item.id}`} className="linkImg">
                    <img
                      src={
                        item.listing_image_url || item.hero_image_url || DEMOIMG
                      }
                      className="img-fluid"
                      alt="demo"
                    />
                  </Link>
                </div>
                <Card.Body className="itemInfo px-2 pb-3 d-flex flex-column justify-content-between py-1">
                  <div className="card-details text-center">
                    <h3 className="itemName text-left mb-0">
                      <Link
                        to={`/demos/details/${item.id}`}
                        className="detailLink text-left"
                      >
                        {item.title}
                      </Link>
                    </h3>

                    <Link
                      to={`/share/demo/${item.id}`}
                      className="entity-share-btn d-flex align-items-center cursor-pointer float-end"
                      title="review"
                    >
                      <Share />
                    </Link>
                    <Card.Text className="card-text my-1 pb-0 text-left d-flex flex-wrap align-items-center justify-content-center">
                      <UTCLocalTimeConverter
                        showDate utcDateTime={item?.start_date_time}

                      />
                      &nbsp;
                      <span>
                        (
                        <ConvertMinutesToTime minutes={item.duration} />)
                      </span>
                    </Card.Text>
                    <hr className="mt-2 mb-1" />
                    <Card.Text className="card-text mb-0 pb-0 text-left text-overflow-desc p-0">
                      {item.description}
                    </Card.Text>
                  </div>
                  <div className="itemEdit d-flex flex-wrap justify-content-center mt-3 gap-2">
                    <Link
                      to={`/demos/details/${item.id}`}
                      className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                    >
                      View More
                    </Link>
                    <Button
                      onClick={() => {
                        setModalShow(true);
                        setModalData(item);
                      }}
                      className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                    >
                      Register
                    </Button>
                    <Link
                      to={`/demos/details/${item.id}?demo=reviews`}
                      className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                      title="review"
                    >
                      Reviews
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
          {displayDemoCards < itemSearched?.demos?.length && (
            <Col lg={12} className="d-flex justify-content-center">
              <Button
                onClick={() =>
                  setDisplayDemoCards(displayDemoCards + displayDemoCards)
                }
                className="btn actionBtnMore action cursor-pointer w-auto text-center"
              >
                Show More
              </Button>
            </Col>
          )}
        </Row>
      )} */}
      {/* {itemSearched?.courses?.length > 0 && (
        <Row className="justify-content-center py-5 mx-0">
          <Col lg={12}>
            <h2 className="main-heading text-center text-capitalize">
              {coursesAlias ? coursesAlias : "courses"}
            </h2>
          </Col>
          {itemSearched?.courses?.slice(0, displayCourseCards)?.map((item) => (
            <Col xl={4} md={6} sm={10} key={item.id} className="pt-2 pb-3">
              <Card className="DemoCard p-3">
                <div className="img-bg h-100">
                  <img
                    onClick={() => {
                      navigate("/courses/details/" + item.id);
                    }}
                    src={item.listing_image_url || COURSEIMG}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = COURSEIMG;
                    }}
                    width="100%"
                    alt=""
                  />
                </div>
                <Card.Body className="p-0 d-flex align-items-center justify-content-between flex-column">
                  <div>
                    <Card.Title>
                      <Link
                        to={`/courses/details/${item.id}`}
                        className="detailLink"
                      >
                        {item.title}
                      </Link>
                    </Card.Title>
                    <Card.Text className="text-overflow-desc mb-2">
                      {item.description}
                    </Card.Text>
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      <p className="card-text mb-0 d-flex align-items-center">
                        <UTCLocalTimeConverter
                          showDate utcDateTime={item?.start_date_time}

                        />
                      </p>
                    </div>
                  </div>
                  <Link
                    to={`/courses/details/${item.id}`}
                    className="btn-more btn mt-2"
                  >
                    Read More
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
          {displayCourseCards < itemSearched?.courses?.length && (
            <Col lg={12} className="d-flex justify-content-center">
              <Button
                onClick={() =>
                  setDisplayCourseCards(displayCourseCards + displayCourseCards)
                }
                className="btn actionBtnMore action cursor-pointer w-auto text-center"
              >
                Show More
              </Button>
            </Col>
          )}
        </Row>
      )} */}
      {/* {itemSearched?.institutes?.length > 0 && (
        <Row className="justify-content-center py-5 mx-0">
          <Col lg={12}>
            <h2 className="main-heading text-center">Institutes</h2>
          </Col>
          {itemSearched?.institutes
            ?.slice(0, displayInstituteCards)
            ?.map((item) => (
              <Col xl={4} md={6} sm={10} className="mt-2 mb-3" key={item.id}>
                <Card className="demoCard">
                  <div className="itemImg">
                    <Link
                      to={`/institutes/details/${item.id}`}
                      className="linkImg"
                    >
                      <img
                        src={
                          item.listing_image_url || item.hero_image_url || IMG
                        }
                        className="img-fluid"
                        alt="Load Error"
                      />
                    </Link>
                  </div>
                  <Card.Body className="itemInfo px-2 pb-3 d-flex flex-column justify-content-between py-1">
                    <div className="card-details text-center">
                      <h3 className="itemName text-left mb-0">
                        <Link
                          to={`/institutes/details/${item.id}`}
                          className="detailLink text-left"
                        >
                          {item.name}
                        </Link>
                      </h3>

                      <Link
                        to={`/share/course/${item.id}`}
                        className="entity-share-btn d-flex align-items-center cursor-pointer float-end"
                        title="review"
                      >
                        <Share />
                      </Link>
                      <Card.Text className="card-text mb-0 pb-0 text-left text-overflow-desc p-0">
                        {item.brief_two_liner_bio}
                      </Card.Text>
                    </div>
                    <div className="itemEdit d-flex flex-wrap justify-content-center mt-3 gap-2">
                      <Link
                        to={`/institutes/details/${item.id}`}
                        className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                      >
                        View More
                      </Link>
                      <Link
                        to={`/institutes/details/${item.id}?demo=reviews`}
                        className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                        title="review"
                      >
                        Reviews
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          {itemSearched?.institutes > displayInstituteCards?.length && (
            <Col lg={12} className="d-flex justify-content-center">
              <Button
                onClick={() =>
                  setDisplayInstituteCards(
                    displayInstituteCards + displayInstituteCards
                  )
                }
                className="btn actionBtnMore action cursor-pointer w-auto text-center"
              >
                Show More
              </Button>
            </Col>
          )}
        </Row>
      )}
      {itemSearched?.trainers?.length > 0 && (
        <Row className="justify-content-center py-5 mx-0">
          <Col lg={12}>
            <h2 className="main-heading text-center">Trainers</h2>
          </Col>
          {itemSearched?.trainers?.slice(0, displayTrainerCards)?.map((item) => (
            <Col xl={4} md={5} sm={10} key={item.id} className="mt-2 my-3">
              <Card className="profileCard d-flex align-items-center flex-row">
                <div className="contUser">
                  <a
                    href={`/trainers/details/${item.id}`}
                    className="userImage"
                  >
                    <Card.Img
                      src={item.listing_image_url || USER}
                      alt="Load Error"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = USER;
                      }}
                      className="img-fluid"
                    />
                  </a>
                </div>
                <Card.Body className="py-0">
                  <Card.Title className="mb-0">
                    <Link
                      to={`/trainers/details/${item.id}`}
                      className="username mt-2"
                    >
                      {item.name}
                    </Link>
                  </Card.Title>
                  <p className="info specialization mb-1">
                    <span>
                      {item.specialization && item.specialization.length > 27
                        ? `${item.specialization.slice(0, 27)}...`
                        : item.specialization}
                    </span>
                  </p>

                  <StarRating rating={item.star_rating} />

                  <div className="itemEdit d-flex align-items-center flex-wrap gap-2 mt-sm-4 mt-3">
                    <Link
                      to={`/trainers/details/${item.id}`}
                      className="actionBtn btn action cursor-pointer"
                      
                    >
                      View Profile
                    </Link>
                    <Link
                      to={`/trainers/details/${item.id}?trainer=reviews`}
                      className="actionBtn btn action cursor-pointer"
                      title="review"
                    >
                      Reviews
                    </Link>
                    <Link
                      to={`/share/${item.type}/${item.id}`}
                      className="actionBtn btn action entity-share-btn d-flex align-items-center cursor-pointer"
                      title="review"
                    >
                      <Share />
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
          {itemSearched?.trainers > displayTrainerCards?.length && (
            <Col lg={12} className="d-flex justify-content-center">
              <Button
                onClick={() =>
                  setDisplayTrainerCards(
                    displayTrainerCards + displayTrainerCards
                  )
                }
                className="btn actionBtnMore action cursor-pointer w-auto text-center"
              >
                Show More
              </Button>
            </Col>
          )}
        </Row>
      )} */}
      {(!itemSearched.demos || itemSearched.demos.length === 0) &&
        (!itemSearched.courses || itemSearched.courses.length === 0) && (
          <Row className="justify-content-center py-5 mx-0">
            <Col lg={12}>
              <p className="no-results-message">
                No results found for your search...
              </p>
            </Col>
          </Row>
        )}
      <RegisterModal
        show={modalShow}
        setshow={setModalShow}
        modaldata={modalData}
        onHide={() => setModalShow(false)}
      />
    </Container>
  );
};

export default SearchedCard;
