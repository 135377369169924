import React, { useEffect, useState } from "react";
import * as Actions from "../../constants/baseUrl";
import axios from "axios";
import withRouter from "../../constants/withRouter";
import "./reviews.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CustomLoader from "../../components/loader/loader";
import { getCookie } from "../../utils/cookies";

function EditReview(props) {
  const entities = "reviews";

  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    id: "",
    userId: "",
    type: "",
    reviewer_first_name: "",
    reviewer_last_name: "",
    service_availed: "",
    reviewType: "",
    review_title: "",
    review_comments: "",
    star_rating: "",
    no_of_reviews: "",
    institute: "",
    instituteId: "",
    trainer: "",
    trainerId: "",
    course: "",
    courseId: "",
    demo: "",
    demoId: "",
    listing_image_url: "",
    listing_image_content: "",
    listing_image_extension: "",
    hero_image_url: "",
    hero_image_content: "",
    hero_image_extension: "",
    category: "",
    comments: "",
  });

  useEffect(() => {
    populateList(props.params.id);
  }, [props.params.id]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const item = formData;
    axios
      .put(`${Actions.BASE_URL}${entities}/${item.id}`, item, {
        headers: {
          Authorization: "Bearer " + getCookie("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        window.location.href = `/${entities}/`;
      });
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const populateList = async (id) => {
    try {
      const data = await fetch(`${Actions.BASE_URL}${entities}/${id}`).then(
        (response) => {
          console.log(response);
          return response.json();
        }
      );

      setLoading(false);
      setFormData(data);
    } catch (error) {
      console.log("There was an error", error);
    }
  };

  return (
    <Container fluid="lg" className="mt-5 pt-5 institutesAddformSection">
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="py-3">
          <div className="coursesAddHeader text-center">
            <h1 className="heading">Edit Review</h1>
            <p>Edit the fields you want to change</p>
          </div>
          <Form action="" method="post" role="form">
            <fieldset className="CoursesAddForm show">
              <Row className="py-3">
                <Col md={6}>
                  <div className="form-floating formfloating mb-3">
                    <select
                      className="form-control fromcontrol"
                      id="star_rating"
                      name="star_rating"
                      defaultValue={formData.star_rating}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          star_rating: e.target.value,
                        })
                      }
                    >
                      <option value={0}>Rating</option>
                      <option value={1}>1</option>
                      <option value={1.5}>1.5</option>
                      <option value={2}>2</option>
                      <option value={2.5}>2.5</option>
                      <option value={3}>3</option>
                      <option value={3.5}>3.5</option>
                      <option value={4}>4</option>
                      <option value={4.5}>4.5</option>
                      <option value={5}>5</option>
                    </select>
                  </div>
                </Col>

                <Col md={6} sm={12}>
                  <div className="form-floating formfloating mb-3">
                    <select
                      className="form-control fromcontrol"
                      id="service_availed"
                      name="service_availed"
                      defaultValue={formData.service_availed}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          service_availed: e.target.value,
                        })
                      }
                    >
                      <option value="Yes" selected>
                        Yes
                      </option>
                      <option value="No">No</option>
                    </select>
                    <Form.Label htmlFor="reviewer_first_name">
                      Service Availed
                    </Form.Label>
                  </div>
                </Col>

                <Col md={6} sm={12}>
                  <div className="form-floating formfloating mb-3">
                    <Form.Control
                      className="form-control fromcontrol"
                      id="reviewer_first_name"
                      name="reviewer_first_name"
                      placeholder="Reviewer First name"
                      defaultValue={formData.reviewer_first_name}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          reviewer_first_name: e.target.value,
                        })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="reviewer_first_name">
                      Reviewer First name
                    </Form.Label>
                  </div>
                </Col>

                <Col md={6} sm={12}>
                  <div className="form-floating formfloating mb-3">
                    <Form.Control
                      className="form-control fromcontrol"
                      id="reviewer_last_name"
                      name="reviewer_last_name"
                      placeholder="Reviewer Last Name"
                      defaultValue={formData.reviewer_last_name}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          reviewer_last_name: e.target.value,
                        })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="reviewer_last_name">
                      Reviewer Last Name
                    </Form.Label>
                  </div>
                </Col>

                <Col sm={12}>
                  <div className="form-floating formfloating mb-3">
                    <Form.Control
                      className="form-control fromcontrol"
                      id="review_title"
                      name="review_title"
                      placeholder="Review Title"
                      defaultValue={formData.review_title}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          review_title: e.target.value,
                        })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="review_title">Review Title</Form.Label>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="form-floating formfloating mb-3">
                    <Form.Control
                      className="form-control fromcontrol max-h-auto"
                      id="review_comments"
                      as="textarea"
                      name="review_comments"
                      placeholder="Reviewer Comment"
                      defaultValue={formData.review_comments}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          review_comments: e.target.value,
                        })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="review_comments">
                      Reviewer Comment
                    </Form.Label>
                  </div>
                </Col>

                <Col md={12} className="mt-2">
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    className="submit-button coursesAddBtn  next"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </fieldset>
          </Form>
        </div>
      )}
    </Container>
  );
}

export default withRouter(EditReview);
