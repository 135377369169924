import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row, Toast } from "react-bootstrap";
import { ChevronLeft, ChevronRight, Clock } from "react-bootstrap-icons";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import * as Actions from "../../constants/baseUrl";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { mainTimeZones } from "../../constants/extras";
import { convertUTCToLocal } from "../../helper/timezoneConversions";
import DurationSelect from "../durationSelect/durationSelect";
import "./scheduleDateModal.css";
import { getCookie } from "../../utils/cookies";

const ScheduleDateModal = ({
  show,
  setShow,
  entityType,
  entity,
  state,
  setState,
  onHide,
}) => {
  const location = useLocation();
  const isEditing = location?.pathname?.includes("edit");

  const myToken = getCookie("token");

  const [valueReq, onChangeReq] = useState(new Date());
  const [startTimeReq, setStartTimeReq] = useState(new Date());
  const [formattedDateReq, setFormattedDateReq] = useState("");
  const [selectedTimeZoneReq, setSelectedTimeZoneReq] = useState(null);

  const [valueURL, onChangeURL] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [selectedTimeZoneURL, setSelectedTimeZoneURL] = useState(null);

  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [requestMade, setRequestMade] = useState(false);
  const [hasURL, setHasURL] = useState(false);
  const [activeTile, setActiveTile] = useState(null);
  const [showToast, setShowToast] = useState(false);

  const [isValid, setIsValid] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isURL, setIsURL] = useState(false);

  const [toastVisible, setToastVisible] = useState(false);

  const tileClassName = ({ date }) => {
    return activeTile && activeTile.getDate() === date.getDate()
      ? "active"
      : "";
  };

  // handle field change
  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...state,
      [fieldName]: fieldValue,
    };
    setState(newState);
  };

  const handleTileClick = (value) => {
    setActiveTile(value);
  };

  useEffect(() => {
    // Set the default timezone to the user's current timezone
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimeZoneReq({ label: userTimezone, value: userTimezone });
    setSelectedTimeZoneURL({ label: userTimezone, value: userTimezone });
  }, []);

  const timeZones = moment.tz
    .names()
    .filter((tz) => mainTimeZones.includes(tz))
    ?.map((tz) => ({
      label: tz,
      value: tz,
    }));

  const handleDateChangeReq = (newValue) => {
    onChangeReq(newValue);
    setFormattedDateReq(moment(newValue).format("YYYY/MM/DD"));
    handleFieldChange("start_date", moment(newValue).format("YYYY/MM/DD"));
  };

  const handleDateChangeURL = (newValue) => {
    onChangeURL(newValue);
    handleFieldChange("start_date", moment(newValue).format("YYYY/MM/DD"));
  };

  const handleTimeZoneChangeURL = (selectedOption) => {
    setSelectedTimeZoneURL(selectedOption);
    handleFieldChange("timeZone", selectedOption?.value);
  };

  const handleTimeZoneChangeReq = (selectedOption) => {
    setSelectedTimeZoneReq(selectedOption);
    handleFieldChange("timeZone", selectedOption?.value);
  };

  const handleTimeChange = (time) => {
    setStartTime(time);
    handleFieldChange("start_time", moment(time).format("HH:mm"));
  };

  const handleTimeChangeReq = (time) => {
    setStartTimeReq(time);
  };

  useEffect(() => {
    handleFieldChange("timezone", selectedTimeZoneReq);
  }, [valueReq, startTimeReq, selectedTimeZoneReq]);

  useEffect(() => {
    handleFieldChange("timezone", selectedTimeZoneURL);
  }, [valueURL, startTime, selectedTimeZoneURL]);

  const sendApiRequest = async () => {
    if (formattedDateReq && selectedTimeZoneReq) {
      const requestData = {
        start_date: formattedDateReq,
        timeZone: selectedTimeZoneReq?.value,
      };
      setLoading(true);
      try {
        const response = await axios.post(
          `${Actions.BASE_URL}demos/GetExistingEvents`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${myToken}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        const parsedData = {};

        for (const key in response.data) {
          const [time, duration] = key.split(":");
          const formattedTime = time + ":" + duration;
          parsedData[formattedTime] = response?.data[key];
        }

        setLoading(false);
        setRequestMade(true);
        setApiResponse(parsedData);
      } catch (error) {
        // Handle any errors
        console.error("API Request Error:", error);
      }
    }
  };

  useEffect(() => {
    sendApiRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedDateReq, selectedTimeZoneReq, startTimeReq, startTime]);

  function isEmpty(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  // Restrict user from selecting a blocked slot
  useEffect(() => {
    const handleTimeChangeReq = () => {
      if (!startTimeReq || !apiResponse) {
        return;
      }

      for (const key in apiResponse) {
        const startTime = key;
        const durationMinutes = apiResponse[key];
        const endTime = moment(startTime, "HH:mm")
          .add(durationMinutes, "minutes")
          .format("HH:mm");

        const startTimeMoment = moment(startTime, "HH:mm");
        const endTimeMoment = moment(endTime, "HH:mm");
        const selectedTimeMoment = moment(startTimeReq, "HH:mm");

        if (
          selectedTimeMoment.isBetween(
            startTimeMoment,
            endTimeMoment,
            null,
            "[]"
          )
        ) {
          setShowToast(true);
          // Check if the selected time is immediately after a blocked time slot
          if (selectedTimeMoment.isSame(endTimeMoment)) {
            handleFieldChange(
              "start_time",
              moment(startTimeReq).format("HH:mm")
            );
            setShowToast(false);
          }
          return;
        }
      }
      handleFieldChange("start_time", moment(startTimeReq).format("HH:mm"));
    };

    handleTimeChangeReq();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiResponse, startTimeReq, selectedTimeZoneReq]);

  useEffect(() => {
    // made request when has date in state
    if (state?.start_date) {
      setRequestMade(true);
    } else {
      setRequestMade(false);
    }
    // made request when has date in state
    if (state?.register_url) {
      setHasURL(true);
    } else {
      setHasURL(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.start_date]);

  const handleSubmit = () => {
    if (hasURL) {
      if (state?.register_url && isURL) {
        setIsValid(true);
        setIsChanged(false);
        handleCloseModal();
      } else {
        setIsValid(false);
        setIsChanged(true);
      }
    }
  };

  useEffect(() => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    const isValidURL = urlPattern.test(state?.register_url);
    setIsURL(isValidURL);
  }, [state?.register_url, isURL, isValid]);

  const handleCloseModal = () => {
    toast.warning(
      "Event Date & Time cannot be changed once added. Please make Sure they are accurate.",
      { autoClose: 6000 }
    );
    onHide();
  };

  useEffect(() => {
    if (show && isEditing && !toastVisible) {
      setToastVisible(true);
      toast.warning("Event Date & Time cannot be changed", {
        autoClose: 2000,
      });
      setTimeout(() => {
        setToastVisible(false);
        onHide();
      }, 2000);
    }
  }, [show, isEditing, toastVisible, onHide]);

  return (
    <Modal
      onHide={onHide}
      show={isEditing ? false : show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {loading && (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      )}
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-capitalize h5"
        >
          Schedule your {entity}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mx-0">
          <Col md={12}>
            <Form.Group className="d-flex gap-2 align-items-end mb-3">
              <Form.Check
                id="meetingURL"
                className="custom-checkbox ps-0 theme-text-2 fw-medium"
                onChange={() => setHasURL(!hasURL)}
                checked={hasURL}
                label="I have my own meeting URL"
              />
            </Form.Group>
          </Col>
        </Row>
        {hasURL ? (
          <>
            <Row className="justify-content-center mx-0">
              <Col md={12}>
                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating">
                  <Form.Control
                    id="register_url"
                    name="register_url"
                    type="text"
                    placeholder="register Url"
                    value={state.register_url}
                    className={
                      (!isURL || !isValid) && isChanged ? "border-danger" : ""
                    }
                    onChange={(e) =>
                      handleFieldChange("register_url", e.target.value)
                    }
                  />
                  <Form.Label className="form-label" htmlFor="register_url">
                    Register Link For Users
                  </Form.Label>
                  {(!isURL || !isValid) && isChanged && (
                    <small className="text-end d-block text-danger">
                      Enter a valid register link
                    </small>
                  )}
                </Form.Group>
                <small className="text-start d-block text-dark fw-bold">
                  <span className="text-danger">*</span>Enter your Meeting URL
                  first
                </small>
              </Col>
            </Row>
            <Row className="position-relative py-3">
              {!isValid && !state.register_url && (
                <div className="semi-bg position-absolute w-100 h-100 start-0 top-0 overlayCalender"></div>
              )}
              <Col lg={6} className="position-relative">
                <Calendar
                  onChange={handleDateChangeURL}
                  value={state?.start_date || valueURL}
                  className="mx-auto"
                  prevLabel={<ChevronLeft />}
                  tileClassName={tileClassName}
                  onClickDay={handleTileClick}
                  nextLabel={<ChevronRight />}
                  minDate={new Date()}
                />

                <Form.Group className="form-floating my-3 mx-auto">
                  <Select
                    className="timezone form-control z-3"
                    required
                    id="timezone"
                    options={timeZones}
                    value={selectedTimeZoneURL}
                    onChange={handleTimeZoneChangeURL}
                    placeholder="Select your time zone"
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-floating mx-auto mb-3">
                  <ReactDatePicker
                    className="form-control form-select w-100"
                    selected={startTime}
                    onChange={handleTimeChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={5}
                    dateFormat="h:mm aa"
                  />
                </Form.Group>
                <Form.Group className="form-floating mx-auto mb-3">
                  <DurationSelect
                    field={state}
                    handleFieldChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        ) : (
          <Row className="justify-content-center mx-0">
            <Col lg={6} className="position-relative">
              <Calendar
                onChange={handleDateChangeReq}
                value={state?.start_date || valueReq}
                className="mx-auto"
                prevLabel={<ChevronLeft />}
                tileClassName={tileClassName}
                onClickDay={handleTileClick}
                nextLabel={<ChevronRight />}
                minDate={new Date()}
              />

              <Form.Group className="form-floating my-3 mx-auto">
                <Select
                  className="timezone form-control z-3"
                  required
                  id="timezone"
                  options={timeZones}
                  value={selectedTimeZoneReq}
                  onChange={handleTimeZoneChangeReq}
                  placeholder="Select your time zone"
                />
              </Form.Group>
              {requestMade && (
                <>
                  <Form.Group className="form-floating mx-auto mb-3">
                    <ReactDatePicker
                      className="form-control form-select w-100"
                      selected={startTimeReq}
                      onChange={handleTimeChangeReq}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={5}
                      dateFormat="h:mm aa"
                    />
                    <Toast
                      show={showToast}
                      onClose={() => setShowToast(false)}
                      autohide
                      delay={3000}
                      style={{
                        position: "absolute",
                        top: "100%",
                        opacity: 1,
                        left: 0,
                        zIndex: "99",
                      }}
                    >
                      <Toast.Header closeButton={false}>
                        <strong className="mr-auto text-danger">
                          <Clock />
                          &nbsp;Error
                        </strong>
                      </Toast.Header>
                      <Toast.Body>
                        You cannot select a Reserved Time Slot
                      </Toast.Body>
                    </Toast>
                  </Form.Group>
                  <Form.Group className="form-floating mx-auto mb-3">
                    <DurationSelect
                      field={state}
                      handleFieldChange={handleFieldChange}
                    />
                  </Form.Group>
                </>
              )}
            </Col>
            {requestMade &&
              apiResponse !== null &&
              (apiResponse !== null && !isEmpty(apiResponse) ? (
                <Col lg={6}>
                  <h5 className="theme-text-2 mb-3">Blocked Time Slots</h5>
                  {Object.keys(apiResponse)?.map((time) => {
                    const startTime = time;
                    const durationMinutes = apiResponse[time];
                    const endTime = moment(startTime, "HH:mm")
                      .add(durationMinutes, "minutes")
                      .format("HH:mm");

                    return (
                      <Alert className="p-2 mb-2 alert-theme" key={time}>
                        <Row>
                          <Col md={12}>
                            <span className="fw-medium text-capitalize">
                              Reserved from&nbsp;
                              <span className="theme-text-2">
                                {
                                  convertUTCToLocal(formattedDateReq, startTime)
                                    ?.localTime
                                }
                              </span>
                              &nbsp;to&nbsp;
                              <span className="theme-text-2">
                                {
                                  convertUTCToLocal(formattedDateReq, endTime)
                                    ?.localTime
                                }
                              </span>
                            </span>
                          </Col>
                        </Row>
                      </Alert>
                    );
                  })}
                  <p className="text-danger">
                    <small>
                      *Select any time slot other than blocked time slots
                    </small>
                  </p>
                </Col>
              ) : (
                <Col lg={6}>
                  <h5 className="theme-text-2">Great!</h5>
                  <p>You can select any time slot for this day.</p>
                </Col>
              ))}
          </Row>
        )}
        {hasURL ? (
          <Button
            className="btn add-button float-end mt-3 px-4"
            onClick={handleSubmit}
          >
            Done
          </Button>
        ) : (
          <Button
            className="btn add-button float-end mt-3 px-4"
            onClick={() => handleCloseModal()}
          >
            Done
          </Button>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ScheduleDateModal;
