import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { ArrowRight, Check2Circle } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../../components/API/API";
import DynamicGeneralFieldset from "../../../components/dynamicFieldset/dynamicFieldset";
import DynamicNavItemFieldset from "../../../components/dynamicFieldset/dynamicNavItemsFieldset";
import DynamicOptionsFieldset from "../../../components/dynamicOptionsFieldset/dynamicOptionsFieldset";
import DynamicUserRoleFieldset from "../../../components/dynamicOptionsFieldset/DynamicUserRoleFieldset";
import { NetworkErrorAlert } from "../../../components/infoPopup/infoPopup";
import CustomLoader from "../../../components/loader/loader";
import SubDomainRouter from "../../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../../components/useUserDetails/useTokenDetails";
import useUserData from "../../../components/useUserDetails/useUserDetails";
import { configDataStructure } from "../../../constants/configDataStructure";
import withRouter from "../../../constants/withRouter";
import { getSearchQuery } from "../../../helper/getSearchQuery";
import APIIntegrations from "../../admin/APIIntegrations/APIIntegrations";

const AddCopyConfig = ({ params }) => {
  const configId = params.id;
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;
  const navigate = useNavigate();
  const userData = useUserData();
  const role = userData.role;

  const defaultActiveTab = getSearchQuery("q");
  const handleSelect = (qKey) => {
    navigate(`?q=${qKey}`);
  };

  const siteName = SubDomainRouter("siteName");
  const demosAlias = SubDomainRouter("demosAlias");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const cohortsAlias = SubDomainRouter("cohortsAlias");
  const codeAIAlias = SubDomainRouter("codeAIAlias");
  const startupsAlias = SubDomainRouter("startupsAlias");
  const propertyEventsAlias = SubDomainRouter("propertyEventsAlias");
  const propertiesAlias = SubDomainRouter("propertiesAlias");
  const trainersAlias = SubDomainRouter("trainersAlias");
  const eventsAlias = SubDomainRouter("eventsAlias");

  const countryTypeFilterAlias = SubDomainRouter("countryTypeFilterAlias");
  const coursesFilterAlias = SubDomainRouter("coursesFilterAlias");
  const learningLevelFilterAlias = SubDomainRouter("learningLevelFilterAlias");
  const paymentFilterAlias = SubDomainRouter("paymentFilterAlias");
  const teachingModesFilterAlias = SubDomainRouter("teachingModesFilterAlias");
  const ratingsFilterAlias = SubDomainRouter("ratingsFilterAlias");

  const [loading, setLoading] = useState(false);
  const [reqLoading, setReqLoading] = useState(false);
  const [domainData, setDomainData] = useState({});
  const [customFields, setCustomFields] = useState([]);
  const [nameAliasData, setNameAliasData] = useState({
    userId: myUserId,
    createdBy: myUserId,
    modifiedBy: myUserId,
    ...configDataStructure,
  });
  const [navItems, setNavItems] = useState([
    { path: "/", label: "Home" },
    { path: "/", label: "" },
  ]);
  const [userRole, setUserRole] = useState([
    { key: "user", value: "user" },
    { key: "", value: "" },
  ]);
  const [dynamicUrls, setDynamicUrls] = useState([{ key: "", value: "" }]);
  const [countryTypes, setCountryTypes] = useState({ 0: "" });
  const [courseNames, setCourseNames] = useState({ 0: "" });
  const [subcategoryTypes, setSubcategoryTypes] = useState({ 0: "" });
  const [teachingModes, setTeachingModes] = useState({ 0: "" });
  const [paymentTypes, setPaymentTypes] = useState({ 0: "" });
  const [starRatings, setStarRatings] = useState({ 0: "" });
  const [basicPackage, setBasicPackage] = useState({ 0: "" });
  const [proPackage, setProPackage] = useState({ 0: "" });
  const [masterPackage, setMasterPackage] = useState({ 0: "" });
  const [heroSliderImages, setHeroSliderImages] = useState({ 0: "" });

  const populateList = () => {
    setLoading(true);
    API("get", `configs/${configId}`)
      .then((response) => {
        setNameAliasData({
          ...response?.hostsettings?.common?.nameAlias,
          show_hero_slider: response?.pagesettings?.home?.show_hero_slider,
          section_html: response?.pagesettings?.home?.section_html,
        });
        setDomainData(response);
        setNavItems(
          response?.hostsettings?.header?.navItems ?? [
            { path: "/", label: "Home" },
            { path: "/", label: "" },
          ]
        );

        setUserRole(
          response?.pagesettings?.auth?.userRole || [
            { key: "user", value: "user" },
            { key: "", value: "" },
          ]
        );

        setDynamicUrls(
          response?.hostsettings?.common?.dynamicUrls || [
            { key: "", value: "" },
          ]
        );

        setCountryTypes(
          response?.hostsettings?.common?.enums?.countryTypes ?? {
            100: "",
          }
        );
        setCourseNames(
          response?.hostsettings?.common?.enums?.courseNames ?? {
            100: "",
          }
        );
        setSubcategoryTypes(
          response?.hostsettings?.common?.enums?.subcategoryTypes ?? {
            100: "",
          }
        );
        setTeachingModes(
          response?.hostsettings?.common?.enums?.teachingModes ?? {
            0: "",
          }
        );
        setPaymentTypes(
          response?.hostsettings?.common?.enums?.paymentTypes ?? { 0: "" }
        );
        setStarRatings(
          response?.hostsettings?.common?.enums?.starRatings ?? { 0: "" }
        );
        setBasicPackage(response?.hostsettings?.common?.enums?.basicPackage);
        setProPackage(response?.hostsettings?.common?.enums?.proPackage);
        setMasterPackage(response?.hostsettings?.common?.enums?.masterPackage);

        setHeroSliderImages(
          response?.pagesettings?.home?.heroSliderImages ?? { 0: "" }
        );
        setCustomFields(
          response?.hostsettings?.common?.customfields || [
            { key: "", value: "" },
          ]
        );
      })
      .catch((error) => {
        if (error?.message === "Network Error") {
          NetworkErrorAlert(error?.message);
        } else {
          toast.error(error?.response?.data, {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (configId) {
      populateList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addField = () => {
    setUserRole((prevRole) => [...prevRole, { key: "", value: "" }]);
  };

  const removeField = (index) => {
    setUserRole((prevRole) => prevRole.filter((_, i) => i !== index));
  };

  const updateUserRoles = (index, updatedRole) => {
    setUserRole((prevRole) => {
      const newRole = [...prevRole];
      newRole[index] = updatedRole;
      return newRole;
    });
  };

  const handleFieldChange = (fieldName, fieldValue) => {
    setNameAliasData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setReqLoading(true);

    const { show_hero_slider, section_html, ...commonAliases } = nameAliasData;

    const { _id, ...data } = {
      ...domainData,
      type: domainData.type,
      userId: domainData.userId,
      appsettings: {},
      hostsettings: {
        title: commonAliases?.title,
        name: commonAliases?.name,
        logo: commonAliases?.logo,
        link: commonAliases?.link,
        favicon: commonAliases?.favicon,
        header: {
          logo: commonAliases?.logo,
          navItems,
        },
        common: {
          defaultRegisterForm: commonAliases?.defaultRegisterForm,
          nameAlias: commonAliases,
          dynamicUrls,
          customfields: customFields,
          enums: {
            countryTypes,
            courseNames,
            subcategoryTypes,
            paymentTypes,
            teachingModes,
            starRatings,
            basicPackage,
            proPackage,
            masterPackage,
          },
        },
        footer: {
          logo: commonAliases?.logo,
          fromEmail: commonAliases?.fromEmail,
          adminEmail: commonAliases?.adminEmail,
          supportEmail: commonAliases?.supportEmail,
        },
      },
      pagesettings: {
        home: {
          show_hero_slider,
          heroSliderHeading: commonAliases?.heroSliderHeading,
          heroSliderText: commonAliases?.heroSliderText,
          heroSliderImages,
          section_html,
        },
        auth: {
          userRole,
        },
      },
    };

    API("post", `configs/new`, data)
      .then(() => {
        successAlert();
        navigate("/super-admin-dashboard/config-management");
      })
      .catch((error) => {
        if (error?.message === "Network Error") {
          NetworkErrorAlert(error?.message);
        } else {
          toast.error(error?.response?.data, {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
        }
      })
      .finally(() => setReqLoading(false));
  };

  const successAlert = (param) => {
    confirmAlert({
      customUI: () => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center my-2 text-capitalize">
              Data Updated successfully
            </h5>
          </div>
        );
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>
          {configId ? "Clone" : "Add"} Configs |{" "}
          {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}

      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg" className="profile-edit config-page">
          <h3 className="heading-text theme-text-2 text-center">
            {configId ? "Clone" : "Add"} Config
          </h3>
          <div className="demosAddForm">
            <Form onSubmit={handleSubmit}>
              <Tabs
                onSelect={handleSelect}
                defaultActiveKey={defaultActiveTab || "host_settings"}
                fill
                className="text-dark"
              >
                <Tab
                  eventKey="host_settings"
                  title="Host Settings"
                  className="px-2 py-4 border border-top-0"
                >
                  <Row className="mx-0">
                    <Col md={6}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          required
                          type="text"
                          id="title"
                          name="title"
                          placeholder="Type"
                          value={nameAliasData?.title}
                          onChange={(e) =>
                            handleFieldChange("title", e.target.value)
                          }
                        />
                        <label htmlFor="title">Site Title</label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          required
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Type"
                          value={nameAliasData?.name}
                          onChange={(e) =>
                            handleFieldChange("name", e.target.value)
                          }
                        />
                        <label htmlFor="name">Site Name</label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          required
                          type="text"
                          id="host"
                          name="host"
                          placeholder="Type"
                          value={domainData?.host}
                          onChange={(e) =>
                            setDomainData((prevState) => ({
                              ...prevState,
                              host: e.target.value,
                            }))
                          }
                        />
                        <label htmlFor="host">Host</label>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          required
                          type="text"
                          id="logo"
                          name="logo"
                          placeholder="Logo Url"
                          value={nameAliasData?.logo}
                          onChange={(e) =>
                            handleFieldChange("logo", e.target.value)
                          }
                        />
                        <label htmlFor="logo">Logo Url</label>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="favicon"
                          name="favicon"
                          placeholder="FavIcon URL"
                          value={nameAliasData?.favicon}
                          onChange={(e) =>
                            handleFieldChange("favicon", e.target.value)
                          }
                        />
                        <label htmlFor="favicon">FavIcon URL</label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="adminEmail"
                          name="adminEmail"
                          placeholder="adminEmail"
                          value={nameAliasData?.adminEmail}
                          onChange={(e) =>
                            handleFieldChange("adminEmail", e.target.value)
                          }
                        />
                        <label htmlFor="adminEmail">Admin Email</label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="supportEmail"
                          name="supportEmail"
                          placeholder="supportEmail"
                          value={nameAliasData?.supportEmail}
                          onChange={(e) =>
                            handleFieldChange("supportEmail", e.target.value)
                          }
                        />
                        <label htmlFor="supportEmail">Support Email</label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="trainersListID"
                          name="trainersListID"
                          placeholder="trainersListID"
                          value={nameAliasData?.trainersListID}
                          onChange={(e) =>
                            handleFieldChange("trainersListID", e.target.value)
                          }
                        />
                        <label htmlFor="trainersListID">Support List ID</label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="subscriptionListID"
                          name="subscriptionListID"
                          placeholder="subscriptionListID"
                          value={nameAliasData?.subscriptionListID}
                          onChange={(e) =>
                            handleFieldChange(
                              "subscriptionListID",
                              e.target.value
                            )
                          }
                        />
                        <label htmlFor="subscriptionListID">
                          Subscription List ID
                        </label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="fromEmail"
                          name="fromEmail"
                          placeholder="fromEmail"
                          defaultValue={`contact@${window.location.host}`}
                          value={nameAliasData?.fromEmail}
                          onChange={(e) =>
                            handleFieldChange("fromEmail", e.target.value)
                          }
                        />
                        <label htmlFor="fromEmail">
                          Add Email to send mails from
                        </label>
                      </Form.Group>
                    </Col>

                    {/* Default HomePage Path */}
                    <h6 className="theme-text-2 fw-normal mt-3">
                      Default HomePage
                    </h6>
                    <hr />

                    <Col md={12}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3">
                        <Form.Select
                          id="homePageRoute"
                          className={`form-control form-select text-capitalize`}
                          value={nameAliasData?.homePageRoute}
                          name="homePageRoute"
                          onChange={(e) =>
                            handleFieldChange("homePageRoute", e.target.value)
                          }
                        >
                          <option value="">Select Default Homepage</option>
                          <option value="/">Default Homepage Page</option>
                          <option value="/courses">{coursesAlias} Page</option>
                          <option value="/demos">{demosAlias} Page</option>
                          <option value="/startup/items">
                            {startupsAlias} Page
                          </option>
                          <option value="/contact">Contact Page</option>
                          <option value="/blogs">Blogs Page</option>
                          <option value="/code-ai">{codeAIAlias}</option>
                          <option value="/cohort/itemss">
                            {cohortsAlias} Page
                          </option>
                          <option value="/propertyevent/items">
                            {propertyEventsAlias} Page
                          </option>
                          <option value="/events">{eventsAlias} Page</option>
                        </Form.Select>
                        <Form.Label
                          className="form-label"
                          htmlFor="defaultRegisterForm"
                        >
                          Default Home Page
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    {/* default register form */}
                    <h6 className="theme-text-2 fw-normal mt-4">
                      Default Register {demosAlias}/{coursesAlias} Form
                    </h6>
                    <hr />
                    <Col md={12}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3">
                        <Form.Select
                          id="defaultRegisterForm"
                          className={`form-control form-select text-capitalize`}
                          value={nameAliasData?.defaultRegisterForm}
                          name="defaultRegisterForm"
                          onChange={(e) =>
                            handleFieldChange(
                              "defaultRegisterForm",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Default Register Form</option>
                          <option value="registerPopup">Register Popup</option>
                          <option value="registerAcceleratorForm">
                            Register Accelerator Form
                          </option>
                        </Form.Select>
                        <Form.Label
                          className="form-label"
                          htmlFor="defaultRegisterForm"
                        >
                          Default Register Form
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Row className="mx-0">
                      <Col
                        md={12}
                        className="d-flex justify-content-between align-items-center gap-3 mt-4 mb-2"
                      >
                        <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                          Navbar Items
                        </h6>
                        <Button
                          className="clickable add-button ms-auto rounded-circle d-flex"
                          onClick={() =>
                            setNavItems((prevNavItems) => [
                              ...prevNavItems,
                              { path: "/", label: "" },
                            ])
                          }
                        >
                          <span className="addBttn d-block">+</span>
                        </Button>
                      </Col>
                      <hr />
                      <Col md={12} className="mt-3">
                        <Row>
                          {navItems.map((navItem, index) => (
                            <DynamicNavItemFieldset
                              key={index}
                              index={index}
                              fields={navItems}
                              setFields={setNavItems}
                            />
                          ))}
                        </Row>
                      </Col>
                    </Row>

                    {["siteadmin", "superadmin"].includes(role) && (
                      <>
                        <Accordion>
                          <Accordion.Item eventKey="0" className="border-0">
                            <Accordion.Header className="theme-text-2 border-bottom">
                              <h5 className="theme-text-2">More Items</h5>
                            </Accordion.Header>
                            <Accordion.Body className="px-0">
                              <Row className="mx-0">
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /demos
                                  </span>{" "}
                                  <ArrowRight /> {demosAlias}
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /courses
                                  </span>{" "}
                                  <ArrowRight /> {coursesAlias}
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /events
                                  </span>{" "}
                                  <ArrowRight /> {eventsAlias}
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /trainers
                                  </span>{" "}
                                  <ArrowRight /> {trainersAlias}
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /contact
                                  </span>{" "}
                                  <ArrowRight /> Contact
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /propertyevent/items
                                  </span>{" "}
                                  <ArrowRight /> {propertyEventsAlias}{" "}
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /code-ai
                                  </span>{" "}
                                  <ArrowRight /> {codeAIAlias}
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /property/items
                                  </span>{" "}
                                  <ArrowRight /> {propertiesAlias}
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /blog
                                  </span>{" "}
                                  <ArrowRight /> Blog
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /corporates
                                  </span>{" "}
                                  <ArrowRight /> Corporates
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /reviews
                                  </span>{" "}
                                  <ArrowRight /> Reviews
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /startup/items
                                  </span>{" "}
                                  <ArrowRight /> {startupsAlias}
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /cohort/items
                                  </span>{" "}
                                  <ArrowRight /> {cohortsAlias}
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /items/meetup
                                  </span>{" "}
                                  <ArrowRight /> meetups
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /founder/items
                                  </span>{" "}
                                  <ArrowRight /> founders
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /speaker/items
                                  </span>{" "}
                                  <ArrowRight /> speakers
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /mentor/items
                                  </span>{" "}
                                  <ArrowRight /> mentors
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /items/blogger
                                  </span>{" "}
                                  <ArrowRight /> bloggers
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /intern/items
                                  </span>{" "}
                                  <ArrowRight /> interns
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /freelancer/items
                                  </span>{" "}
                                  <ArrowRight /> freelancers
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /partner/items
                                  </span>{" "}
                                  <ArrowRight /> partners
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /items/sponsor
                                  </span>{" "}
                                  <ArrowRight /> sponsors
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /NRI-startup/items
                                  </span>{" "}
                                  <ArrowRight /> NRI startups
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="mb-1 text-capitalize"
                                >
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /items/bets-tester
                                  </span>{" "}
                                  <ArrowRight /> beta testers
                                </Col>
                                <Col md={6} className="mb-1 text-capitalize">
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /items/corporate-startup
                                  </span>{" "}
                                  <ArrowRight /> corporate startups
                                </Col>
                                <Col md={6} className="mb-1 text-capitalize">
                                  <span className="theme-text-2 italicInput text-lowercase">
                                    /service-provider/items
                                  </span>{" "}
                                  <ArrowRight /> service providers
                                </Col>
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </>
                    )}
                  </Row>
                </Tab>

                <Tab
                  eventKey="page_settings"
                  title="Page Settings"
                  className="px-2 py-4 border border-top-0"
                >
                  <Row className="mx-0">
                    <Col className="mb-3 px-2">
                      <h5 className="theme-text flex-grow-1">Home Page</h5>
                      <hr />
                    </Col>

                    <Col md={12} className="mt-4 mb-2 px-2">
                      <div className="d-flex justify-content-between align-items-between">
                        <h5 className="theme-text-2 flex-grow-1 mb-0">
                          Hero Slider Section
                        </h5>
                        <Form.Check
                          type="checkbox"
                          className="custom-checkbox ps-0"
                          id="show_hero_slider"
                          label={`Show Hero Slider Section`}
                          checked={nameAliasData?.show_hero_slider}
                          onChange={(e) => {
                            const newValue = e.target.checked;
                            handleFieldChange("show_hero_slider", newValue);
                          }}
                        />
                      </div>
                      <hr />
                    </Col>
                    <Col md={12} className="px-2">
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="heroSliderHeading"
                          name="heroSliderHeading"
                          placeholder="Hero Slider Heading"
                          value={nameAliasData?.heroSliderHeading}
                          onChange={(e) =>
                            handleFieldChange(
                              "heroSliderHeading",
                              e.target.value
                            )
                          }
                        />
                        <label htmlFor="heroSliderHeading">
                          Hero Slider Heading
                        </label>
                      </Form.Group>
                    </Col>

                    <Col md={12} className="px-2">
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="heroSliderText"
                          name="heroSliderText"
                          placeholder="Hero Slider Description"
                          value={nameAliasData?.heroSliderText}
                          onChange={(e) =>
                            handleFieldChange("heroSliderText", e.target.value)
                          }
                        />
                        <label htmlFor="heroSliderText">
                          Hero Slider Description
                        </label>
                      </Form.Group>
                    </Col>

                    {/* heroSliderImages */}
                    <Col
                      md={12}
                      className="d-flex justify-content-between gap-3 mt-4 mb-2 px-2"
                    >
                      <h6 className="theme-text-2 flex-grow-1">
                        Hero Slider Images
                      </h6>
                      <Button
                        className="clickable add-button ms-auto rounded-circle d-flex"
                        onClick={() => {
                          setHeroSliderImages((prevState) => {
                            const newIndex =
                              Object.keys(prevState)?.length.toString();
                            return {
                              ...prevState,
                              [newIndex]: "",
                            };
                          });
                        }}
                      >
                        <span className="addBttn d-block">+</span>
                      </Button>
                    </Col>
                    <hr />
                    <Col md={12} className="mt-3 px-2">
                      <Row>
                        {heroSliderImages &&
                          Object.keys(heroSliderImages)?.map((index) => (
                            <DynamicOptionsFieldset
                              ColNum={12}
                              label={"Slider Image"}
                              key={index}
                              index={index}
                              fields={heroSliderImages}
                              setFields={setHeroSliderImages}
                            />
                          ))}
                      </Row>
                    </Col>
                    <hr />
                    {["siteadmin", "superadmin"].includes(role) && (
                      <>
                        <Col
                          md={12}
                          className="d-flex justify-content-between gap-3 mt-4 mb-2 px-2"
                        >
                          <h5 className="theme-text-2 flex-grow-1">
                            Sections HTML
                            <hr />
                          </h5>
                        </Col>
                        <Col md={12}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              as={"textarea"}
                              rows={8}
                              style={{
                                height: "auto",
                              }}
                              id="section_html"
                              name="section_html"
                              placeholder="section_html"
                              value={nameAliasData?.section_html}
                              onChange={(e) =>
                                handleFieldChange(
                                  "section_html",
                                  e.target.value
                                )
                              }
                            />
                            <label htmlFor="section_html">
                              Add HTML for section
                            </label>
                          </Form.Group>
                        </Col>

                        <Col md={12}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              as={"textarea"}
                              rows={8}
                              style={{
                                height: "auto",
                              }}
                              id="footer_html"
                              name="footer_html"
                              placeholder="footer_html"
                              value={nameAliasData?.footer_html}
                              onChange={(e) =>
                                handleFieldChange("footer_html", e.target.value)
                              }
                            />
                            <label htmlFor="footer_html">
                              Add HTML for new footer (will replace original
                              footer)
                            </label>
                          </Form.Group>
                        </Col>
                      </>
                    )}

                    {["siteadmin", "superadmin"].includes(role) && (
                      <>
                        <Col className="mb-3 px-2 mt-4">
                          <h5 className="theme-text flex-grow-1">Auth Page</h5>
                          <hr />
                        </Col>
                        {/* UserRoles */}
                        <Col
                          md={12}
                          className="d-flex justify-content-between align-items-center gap-3 mt-4 px-2 mb-2"
                        >
                          <h6 className="theme-text-2 flex-grow-1 text-capitalize m-0">
                            User Roles
                          </h6>
                          <Button
                            className="clickable add-button ms-auto rounded-circle d-flex"
                            onClick={addField}
                          >
                            <span className="addBttn d-block">+</span>
                          </Button>
                        </Col>
                        <hr />
                        <Col md={12} className="mt-3 px-2">
                          <Col md={12} className="mt-3">
                            {userRole?.map((role, index) => (
                              <DynamicUserRoleFieldset
                                key={index}
                                field={role}
                                onFieldChange={(updatedRole) =>
                                  updateUserRoles(index, updatedRole)
                                }
                                onRemove={() => removeField(index)}
                              />
                            ))}
                          </Col>
                        </Col>
                      </>
                    )}
                  </Row>
                </Tab>

                <Tab
                  eventKey="sidebar_filter"
                  title="Filter Sidebar"
                  className="px-2 py-4 border border-top-0"
                >
                  <Row className="mx-0">
                    <h6 className="theme-text-2 fw-normal mt-3">
                      Sidebar FiltersAlias
                    </h6>
                    <hr />
                    <Col md={6}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3 text-capitalize">
                        <Form.Control
                          type="text"
                          id="countryTypeFilterAlias"
                          name="countryTypeFilterAlias"
                          placeholder="countryTypeFilterAlias"
                          value={nameAliasData?.countryTypeFilterAlias}
                          onChange={(e) =>
                            handleFieldChange(
                              "countryTypeFilterAlias",
                              e.target.value
                            )
                          }
                        />
                        <label htmlFor="countryTypeFilterAlias">
                          country_type FilterAlias
                        </label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3 text-capitalize">
                        <Form.Control
                          type="text"
                          id="coursesFilterAlias"
                          name="coursesFilterAlias"
                          placeholder="coursesFilterAlias"
                          value={nameAliasData?.coursesFilterAlias}
                          onChange={(e) =>
                            handleFieldChange(
                              "coursesFilterAlias",
                              e.target.value
                            )
                          }
                        />
                        <label htmlFor="coursesFilterAlias">
                          category FilterAlias
                        </label>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3 text-capitalize">
                        <Form.Control
                          type="text"
                          id="learningLevelFilterAlias"
                          name="learningLevelFilterAlias"
                          placeholder="learningLevelFilterAlias"
                          value={nameAliasData?.learningLevelFilterAlias}
                          onChange={(e) =>
                            handleFieldChange(
                              "learningLevelFilterAlias",
                              e.target.value
                            )
                          }
                        />
                        <label htmlFor="learningLevelFilterAlias">
                          subcategory FilterAlias
                        </label>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3 text-capitalize">
                        <Form.Control
                          type="text"
                          id="paymentFilterAlias"
                          name="paymentFilterAlias"
                          placeholder="paymentFilterAlias"
                          value={nameAliasData?.paymentFilterAlias}
                          onChange={(e) =>
                            handleFieldChange(
                              "paymentFilterAlias",
                              e.target.value
                            )
                          }
                        />
                        <label htmlFor="paymentFilterAlias">
                          subcategorylevel1 FilterAlias
                        </label>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3 text-capitalize">
                        <Form.Control
                          type="text"
                          id="teachingModesFilterAlias"
                          name="teachingModesFilterAlias"
                          placeholder="teachingModesFilterAlias"
                          value={nameAliasData?.teachingModesFilterAlias}
                          onChange={(e) =>
                            handleFieldChange(
                              "teachingModesFilterAlias",
                              e.target.value
                            )
                          }
                        />
                        <label htmlFor="teachingModesFilterAlias">
                          subcategorylevel2 FilterAlias
                        </label>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3 text-capitalize">
                        <Form.Control
                          type="text"
                          id="tagsFilterAlias"
                          name="tagsFilterAlias"
                          placeholder="tagsFilterAlias"
                          value={nameAliasData?.tagsFilterAlias}
                          onChange={(e) =>
                            handleFieldChange("tagsFilterAlias", e.target.value)
                          }
                        />
                        <label htmlFor="tagsFilterAlias">
                          subcategorylevel3 FilterAlias
                        </label>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3 text-capitalize">
                        <Form.Control
                          type="text"
                          id="ratingsFilterAlias"
                          name="ratingsFilterAlias"
                          placeholder="ratingsFilterAlias"
                          value={nameAliasData?.ratingsFilterAlias}
                          onChange={(e) =>
                            handleFieldChange(
                              "ratingsFilterAlias",
                              e.target.value
                            )
                          }
                        />
                        <label htmlFor="ratingsFilterAlias">
                          star_rating_type FilterAlias
                        </label>
                      </Form.Group>
                    </Col>

                    <>
                      <h6 className="theme-text-2 fw-normal mt-3">
                        Sidebar Filters
                      </h6>
                      <hr />

                      <Col sm={6}>
                        <Form.Check
                          type="checkbox"
                          className="custom-checkbox ps-0 mb-3"
                          id="countryFilter"
                          label={`country_type FilterAlias`}
                          checked={nameAliasData?.countryFilter === "show"}
                          onChange={(e) => {
                            const newValue = e.target.checked ? "show" : "hide";
                            handleFieldChange("countryFilter", newValue);
                          }}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Check
                          type="checkbox"
                          className="custom-checkbox ps-0 mb-3"
                          id="coursesFilter"
                          label={`category FilterAlias`}
                          checked={nameAliasData?.coursesFilter === "show"}
                          onChange={(e) => {
                            const newValue = e.target.checked ? "show" : "hide";
                            handleFieldChange("coursesFilter", newValue);
                          }}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Check
                          type="checkbox"
                          className="custom-checkbox ps-0 mb-3"
                          id="learningLevelFilter"
                          label={`subcategory FilterAlias`}
                          checked={
                            nameAliasData?.learningLevelFilter === "show"
                          }
                          onChange={(e) => {
                            const newValue = e.target.checked ? "show" : "hide";
                            handleFieldChange("learningLevelFilter", newValue);
                          }}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Check
                          type="checkbox"
                          className="custom-checkbox ps-0 mb-3"
                          id="paymentFilter"
                          label={`subcategorylevel1 FilterAlias`}
                          checked={nameAliasData?.paymentFilter === "show"}
                          onChange={(e) => {
                            const newValue = e.target.checked ? "show" : "hide";
                            handleFieldChange("paymentFilter", newValue);
                          }}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Check
                          type="checkbox"
                          className="custom-checkbox ps-0 mb-3"
                          id="teachingModeFilter"
                          label={`subcategorylevel2 FilterAlias`}
                          checked={nameAliasData?.teachingModeFilter === "show"}
                          onChange={(e) => {
                            const newValue = e.target.checked ? "show" : "hide";
                            handleFieldChange("teachingModeFilter", newValue);
                          }}
                        />
                      </Col>

                      <Col sm={6}>
                        <Form.Check
                          type="checkbox"
                          className="custom-checkbox ps-0 mb-3"
                          id="tagsFilter"
                          label={`subcategorylevel3 FilterAlias`}
                          checked={nameAliasData?.tagsFilter === "show"}
                          onChange={(e) => {
                            const newValue = e.target.checked ? "show" : "hide";
                            handleFieldChange("tagsFilter", newValue);
                          }}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Check
                          type="checkbox"
                          className="custom-checkbox ps-0 mb-3"
                          id="ratingsFilter"
                          label={`star_rating_type FilterAlias`}
                          checked={nameAliasData?.ratingsFilter === "show"}
                          onChange={(e) => {
                            const newValue = e.target.checked ? "show" : "hide";
                            handleFieldChange("ratingsFilter", newValue);
                          }}
                        />
                      </Col>
                    </>
                  </Row>
                </Tab>

                <Tab
                  eventKey="enums"
                  title="Enums"
                  className="px-2 py-4 border border-top-0"
                >
                  <Row className="mx-0">
                    {/*************** enums *****************/}

                    {/* Redirect Urls */}
                    <>
                      <Col
                        md={12}
                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                      >
                        <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                          Dynamic URLs
                        </h6>
                        <Button
                          className="clickable add-button ms-auto rounded-circle d-flex"
                          onClick={() =>
                            setDynamicUrls((prevRole) => [
                              ...prevRole,
                              { key: "", value: "" },
                            ])
                          }
                        >
                          <span className="addBttn d-block">+</span>
                        </Button>
                      </Col>
                      <hr />
                      <Col md={12} className="mt-3">
                        <Col md={12} className="mt-3">
                          {dynamicUrls?.map((role, index) => (
                            <DynamicUserRoleFieldset
                              keyLabel="Route"
                              valueLabel="Redirect URL"
                              key={index}
                              field={role}
                              onFieldChange={(updatedRole) =>
                                setDynamicUrls((prevRole) => {
                                  const newRole = [...prevRole];
                                  newRole[index] = updatedRole;
                                  return newRole;
                                })
                              }
                              onRemove={() =>
                                setDynamicUrls((prevRole) =>
                                  prevRole.filter((_, i) => i !== index)
                                )
                              }
                            />
                          ))}
                        </Col>
                      </Col>
                    </>
                    {/* Countries */}
                    <>
                      <Col
                        md={12}
                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                      >
                        <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                          country_types - label:{countryTypeFilterAlias}
                        </h6>
                        <Button
                          className="clickable add-button ms-auto rounded-circle d-flex"
                          onClick={() => {
                            setCountryTypes((prevCountryTypes) => {
                              const newIndex =
                                Object.keys(
                                  prevCountryTypes
                                )?.length.toString();
                              return {
                                ...prevCountryTypes,
                                [newIndex]: "",
                              };
                            });
                          }}
                        >
                          <span className="addBttn d-block">+</span>
                        </Button>
                      </Col>
                      <hr />
                      <Col md={12} className="mt-3">
                        <Row>
                          {countryTypes &&
                            Object.keys(countryTypes)?.map((index) => (
                              <DynamicOptionsFieldset
                                key={index}
                                index={index}
                                fields={countryTypes}
                                setFields={setCountryTypes}
                              />
                            ))}
                        </Row>
                      </Col>
                    </>
                    {/* Course Names */}
                    <>
                      <Col
                        md={12}
                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                      >
                        <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                          Category - label:{coursesFilterAlias}
                        </h6>
                        <Button
                          className="clickable add-button ms-auto rounded-circle d-flex"
                          onClick={() => {
                            setCourseNames((prevState) => {
                              const newIndex =
                                Object.keys(prevState)?.length.toString();
                              return {
                                ...prevState,
                                [newIndex]: "",
                              };
                            });
                          }}
                        >
                          <span className="addBttn d-block">+</span>
                        </Button>
                      </Col>
                      <hr />
                      <Col md={12} className="mt-3">
                        <Row>
                          {courseNames &&
                            Object.keys(courseNames)?.map((index) => (
                              <DynamicOptionsFieldset
                                key={index}
                                index={index}
                                fields={courseNames}
                                setFields={setCourseNames}
                              />
                            ))}
                        </Row>
                      </Col>
                    </>
                    {/* Subcategory Types */}
                    <>
                      <Col
                        md={12}
                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                      >
                        <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                          subcategory - label:{learningLevelFilterAlias}
                        </h6>
                        <Button
                          className="clickable add-button ms-auto rounded-circle d-flex"
                          onClick={() => {
                            setSubcategoryTypes((prevState) => {
                              const newIndex =
                                Object.keys(prevState)?.length.toString();
                              return {
                                ...prevState,
                                [newIndex]: "",
                              };
                            });
                          }}
                        >
                          <span className="addBttn d-block">+</span>
                        </Button>
                      </Col>
                      <hr />
                      <Col md={12} className="mt-3">
                        <Row>
                          {subcategoryTypes &&
                            Object.keys(subcategoryTypes)?.map((index) => (
                              <DynamicOptionsFieldset
                                key={index}
                                index={index}
                                fields={subcategoryTypes}
                                setFields={setSubcategoryTypes}
                              />
                            ))}
                        </Row>
                      </Col>
                    </>
                    {/* Payment Types */}
                    <>
                      <Col
                        md={12}
                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                      >
                        <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                          subcategorylevel1 - label:{paymentFilterAlias}
                        </h6>
                        <Button
                          className="clickable add-button ms-auto rounded-circle d-flex"
                          onClick={() => {
                            setPaymentTypes((prevState) => {
                              const newIndex =
                                Object.keys(prevState)?.length.toString();
                              return {
                                ...prevState,
                                [newIndex]: "",
                              };
                            });
                          }}
                        >
                          <span className="addBttn d-block">+</span>
                        </Button>
                      </Col>
                      <hr />
                      <Col md={12} className="mt-3">
                        <Row>
                          {paymentTypes &&
                            Object.keys(paymentTypes)?.map((index) => (
                              <DynamicOptionsFieldset
                                key={index}
                                index={index}
                                fields={paymentTypes}
                                setFields={setPaymentTypes}
                              />
                            ))}
                        </Row>
                      </Col>
                    </>

                    {/* Teaching Modes */}
                    <>
                      <Col
                        md={12}
                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                      >
                        <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                          subcategorylevel2 - label:{teachingModesFilterAlias}
                        </h6>
                        <Button
                          className="clickable add-button ms-auto rounded-circle d-flex"
                          onClick={() => {
                            setTeachingModes((prevState) => {
                              const newIndex =
                                Object.keys(prevState)?.length.toString();
                              return {
                                ...prevState,
                                [newIndex]: "",
                              };
                            });
                          }}
                        >
                          <span className="addBttn d-block">+</span>
                        </Button>
                      </Col>
                      <hr />
                      <Col md={12} className="mt-3">
                        <Row>
                          {teachingModes &&
                            Object.keys(teachingModes)?.map((index) => (
                              <DynamicOptionsFieldset
                                key={index}
                                index={index}
                                fields={teachingModes}
                                setFields={setTeachingModes}
                              />
                            ))}
                        </Row>
                      </Col>
                    </>
                    {/* StarRatings */}
                    <>
                      <Col
                        md={12}
                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                      >
                        <h6 className="theme-text-2 flex-grow-1 text-capitalize">
                          star_rating_type - label:{ratingsFilterAlias}
                        </h6>
                        <Button
                          className="clickable add-button ms-auto rounded-circle d-flex"
                          onClick={() => {
                            setStarRatings((prevState) => {
                              const newIndex =
                                Object.keys(prevState)?.length.toString();
                              return {
                                ...prevState,
                                [newIndex]: "",
                              };
                            });
                          }}
                        >
                          <span className="addBttn d-block">+</span>
                        </Button>
                      </Col>
                      <hr />
                      <Col md={12} className="mt-3">
                        <Row>
                          {starRatings &&
                            Object.keys(starRatings)?.map((index) => (
                              <DynamicOptionsFieldset
                                key={index}
                                index={index}
                                fields={starRatings}
                                setFields={setStarRatings}
                              />
                            ))}
                        </Row>
                      </Col>
                    </>

                    {/***************  Subscriptions ********************/}
                    <>
                      <Col md={12}>
                        <h6 className="theme-text-2 fw-normal mt-3">
                          Subscription Packages Details
                        </h6>
                      </Col>
                      <hr />
                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="text"
                            id="basicSubscriptionsCharges"
                            name="basicSubscriptionsCharges"
                            placeholder="basicSubscriptionsCharges"
                            value={nameAliasData?.basicSubscriptionsCharges}
                            onChange={(e) =>
                              handleFieldChange(
                                "basicSubscriptionsCharges",
                                e.target.value
                              )
                            }
                          />
                          <label htmlFor="basicSubscriptionsCharges">
                            Basic Subscription Charges
                          </label>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="text"
                            id="proSubscriptionsCharges"
                            name="proSubscriptionsCharges"
                            placeholder="proSubscriptionsCharges"
                            value={nameAliasData?.proSubscriptionsCharges}
                            onChange={(e) =>
                              handleFieldChange(
                                "proSubscriptionsCharges",
                                e.target.value
                              )
                            }
                          />
                          <label htmlFor="proSubscriptionsCharges">
                            Professional Subscription Charges
                          </label>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="text"
                            id="masterSubscriptionsCharges"
                            name="masterSubscriptionsCharges"
                            placeholder="masterSubscriptionsCharges"
                            value={nameAliasData?.masterSubscriptionsCharges}
                            onChange={(e) =>
                              handleFieldChange(
                                "masterSubscriptionsCharges",
                                e.target.value
                              )
                            }
                          />
                          <label htmlFor="masterSubscriptionsCharges">
                            Master Subscription Charges
                          </label>
                        </Form.Group>
                      </Col>

                      {/* basic Package */}
                      <Col
                        md={12}
                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                      >
                        <h6 className="theme-text-2 flex-grow-1">
                          Basic Package Benefits
                        </h6>
                        <Button
                          className="clickable add-button ms-auto rounded-circle d-flex"
                          onClick={() => {
                            setBasicPackage((prevState) => {
                              const newIndex =
                                Object.keys(prevState)?.length.toString();
                              return {
                                ...prevState,
                                [newIndex]: "",
                              };
                            });
                          }}
                        >
                          <span className="addBttn d-block">+</span>
                        </Button>
                      </Col>
                      <hr />
                      <Col md={12} className="mt-3">
                        <Row>
                          {basicPackage &&
                            Object.keys(basicPackage)?.map((index) => (
                              <DynamicOptionsFieldset
                                label={"Benefit"}
                                key={index}
                                index={index}
                                fields={basicPackage}
                                setFields={setBasicPackage}
                              />
                            ))}
                        </Row>
                      </Col>
                      {/* pro Package */}
                      <Col
                        md={12}
                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                      >
                        <h6 className="theme-text-2 flex-grow-1">
                          Professional Package Benefits
                        </h6>
                        <Button
                          className="clickable add-button ms-auto rounded-circle d-flex"
                          onClick={() => {
                            setProPackage((prevState) => {
                              const newIndex =
                                Object.keys(prevState)?.length.toString();
                              return {
                                ...prevState,
                                [newIndex]: "",
                              };
                            });
                          }}
                        >
                          <span className="addBttn d-block">+</span>
                        </Button>
                      </Col>
                      <hr />
                      <Col md={12} className="mt-3">
                        <Row>
                          {proPackage &&
                            Object.keys(proPackage)?.map((index) => (
                              <DynamicOptionsFieldset
                                label={"Benefit"}
                                key={index}
                                index={index}
                                fields={proPackage}
                                setFields={setProPackage}
                              />
                            ))}
                        </Row>
                      </Col>
                      {/* master Package */}
                      <Col
                        md={12}
                        className="d-flex justify-content-between gap-3 mt-4 mb-2"
                      >
                        <h6 className="theme-text-2 flex-grow-1">
                          Master Package Benefits
                        </h6>
                        <Button
                          className="clickable add-button ms-auto rounded-circle d-flex"
                          onClick={() => {
                            setMasterPackage((prevState) => {
                              const newIndex =
                                Object.keys(prevState)?.length.toString();
                              return {
                                ...prevState,
                                [newIndex]: "",
                              };
                            });
                          }}
                        >
                          <span className="addBttn d-block">+</span>
                        </Button>
                      </Col>
                      <hr />
                      <Col md={12} className="mt-3">
                        <Row>
                          {masterPackage &&
                            Object.keys(masterPackage)?.map((index) => (
                              <DynamicOptionsFieldset
                                label={"Benefit"}
                                key={index}
                                index={index}
                                fields={masterPackage}
                                setFields={setMasterPackage}
                              />
                            ))}
                        </Row>
                      </Col>
                    </>

                    {/***************  Custom Fields ********************/}
                    <Col
                      md={12}
                      className="d-flex justify-content-between gap-3 mt-4 mb-2"
                    >
                      <h6 className="theme-text-2 flex-grow-1">
                        Add Custom Fields
                      </h6>
                      <Button
                        className="clickable add-button ms-auto rounded-circle d-flex"
                        onClick={() => {
                          const newField = { key: "", value: "" };
                          setCustomFields([...customFields, newField]);
                        }}
                      >
                        <span className="addBttn d-block">+</span>
                      </Button>
                    </Col>
                    <hr />
                    <Col md={12}>
                      {customFields?.map((field, index) => (
                        <DynamicGeneralFieldset
                          key={index}
                          fields={customFields}
                          index={index}
                          onFieldChange={(newFields) => {
                            setCustomFields(newFields);
                          }}
                        />
                      ))}
                    </Col>
                  </Row>
                </Tab>

                <Tab
                  eventKey="api_integrations"
                  title="API Integrations"
                  className="px-2 py-4 border border-top-0"
                >
                  <APIIntegrations />
                </Tab>
              </Tabs>

              <Col
                lg={12}
                className="d-flex justify-content-end flex-wrap mt-2 gap-2"
              >
                <Button type="submit" className="add-button next w-auto">
                  Add Config
                </Button>
              </Col>
            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

export default withRouter(AddCopyConfig);
