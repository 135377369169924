import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";

import COURSEIMG from "../../img/course.png";
import UTCLocalTimeConverter from "../../components/UTCLocalTimeConverter/UTCLocalTimeConverter";
import ConvertDateFormat from "../../components/convertDateFormat/convertDateFormat";
import UploadedDocuments from "../../components/documents/documents";
import ImageGallery from "../../components/gallery/gallery";
import LinksContent from "../../components/linksContent/linksContent";
import CustomLoader from "../../components/loader/loader";
import RegisterModal from "../../components/register/registerModal";
import StarRating from "../../components/starRating/starRating";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import VideoGallery from "../../components/videoGallery/videoGallery";
import courseData from "./course-config.json";
import "./details.css";
import ItemInfo from "./itemInfo";
import { getCookie } from "../../utils/cookies";

function CourseDetails(props) {
  const entityName = courseData.entity;
  const entities = courseData.entities;

  const myToken = getCookie("token");

  const siteName = SubDomainRouter("title");
  const defaultRegisterForm = SubDomainRouter("defaultRegisterForm");

  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const [loading, setLoading] = useState(true);
  const [entityData, setEntityData] = useState({});
  const [reviewData, setReviewData] = useState([]);
  const [displayedCards, setDisplayedCards] = useState(3);

  const populateList = async (id) => {
    try {
      axios.get(`${Actions.BASE_URL}${entities}/${id}`).then((response) => {
        setLoading(false);
        setEntityData(response.data);
      });
    } catch (error) {
      console.log("There was an error", error);
    }
  };
  const GetReviews = async (id) => {
    try {
      await axios
        .get(`${Actions.BASE_URL}reviews/${entityName}/${id}`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setReviewData(response.data);
          ScrollToReviews();
        });
    } catch (error) {
      console.error("There was an error", error);
    }
  };
  // Scroll to reviews
  const location = useLocation();
  const scrollTo = new URLSearchParams(location.search).get(entityName);

  // Create a ref for the target element
  const targetRef = useRef(null);

  const ScrollToReviews = () => {
    if (scrollTo) {
      setTimeout(() => {
        if (targetRef.current) {
          targetRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 800);
    }
  };

  useEffect(() => {
    populateList(props.params.id);
    GetReviews(props.params.id);
  }, [props.params.id]);

  const handleShowMore = () => {
    setDisplayedCards((prevDisplayedCards) =>
      prevDisplayedCards + 3 <= reviewData.length
        ? prevDisplayedCards + 3
        : reviewData.length
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {entityData?.title ? `${entityData?.title}` : "Course Details"} |
          {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      <Container fluid="lg" className="my-5 pt-5">
        {loading ? (
          <CustomLoader />
        ) : (
          <div className="demosViewMoreSection demoProfile overflow-hidden mx-auto">
            <div className="coverImg p-2 position-relative w-100">
              <Image
                src={
                  entityData?.hero_image_url ||
                  entityData?.listing_image_url ||
                  "/assets/images/SWIPERIMG3-min.jpg"
                }
                width="100%"
                alt=""
              />
            </div>

            <Row className="profileHeader align-items-center position-relative py-2 mx-4">
              <Col lg={2} className="mr-3">
                <Image
                  src={
                    entityData?.listing_image_url ||
                    entityData?.hero_image_url ||
                    COURSEIMG
                  }
                  alt="..."
                  className="profileImg"
                />
              </Col>
              <Col md={6} className="mt-lg-0 mt-5 pt-lg-0 pt-2 ps-4">
                <h4 className="trainerName mb-2">
                  {entityData?.title}
                  <br />
                  <span className="industry">
                    <UTCLocalTimeConverter
                      zone
                      showDate
                      utcDateTime={entityData?.start_date_time}
                    />
                  </span>
                </h4>
                {entityData?.star_rating_type && (
                  <StarRating rating={entityData?.star_rating_type} />
                )}
              </Col>
              <Col
                lg={4}
                md={6}
                className="d-flex align-items-center justify-content-center gap-3"
              >
                {defaultRegisterForm === "registerAcceleratorForm" ? (
                  <Link
                    to={`/accelerator/register`}
                    className="add-button action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                    title="register"
                  >
                    Register
                  </Link>
                ) : (
                  <Button
                    onClick={() => {
                      setModalShow(true);
                      setModalData(entityData);
                    }}
                    className="add-button btn w-fit px-4 d-flex align-items-center justify-content-center text-center cursor-pointer"
                  >
                    Register
                  </Button>
                )}

                <Link
                  to={`/enquiries/${entityName}/${entityData?.id}/add`}
                  className="add-button btn w-fit px-3 d-block"
                >
                  Enquiry
                </Link>
                {entityData?.subcategorylevel1?.toLowerCase() === "paid" && (
                  <Link
                    to={`/pay?currency=${entityData?.currency}&amount=${entityData?.amount}&email=${entityData?.userId}&entityType=${entityData.type}&entityId=${entityData.id}`}
                    className="add-button btn w-fit px-3 d-block"
                  >
                    Pay
                  </Link>
                )}
              </Col>
            </Row>

            <Tabs
              defaultActiveKey="details"
              id="demo-details"
              className="mx-4 mt-2"
              fill
            >
              <Tab eventKey="details" title="Details" className="m-4 mt-0">
                <ItemInfo
                  entityData={entityData}
                  displayedCards={displayedCards}
                  reviewData={reviewData}
                  targetRef={targetRef}
                  handleShowMore={handleShowMore}
                />
              </Tab>
              <Tab eventKey="gallery" title="Gallery" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <ImageGallery imageData={entityData?.attachments?.images} />
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="videos" title="Videos" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <VideoGallery videoData={entityData?.attachments?.videos} />
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="docs" title="Docs" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <UploadedDocuments
                      docData={entityData?.attachments?.documents}
                    />
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="link" title="Links" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <LinksContent LinksData={entityData?.attachments?.links} />
                  </Card.Body>
                </Card>
              </Tab>
            </Tabs>
          </div>
        )}

        <RegisterModal
          show={modalShow}
          setshow={setModalShow}
          modaldata={modalData}
        />
      </Container>
    </>
  );
}
export default withRouter(CourseDetails);
