import moment from "moment-timezone";
import PropTypes from "prop-types";

/**
 * Converts local time to UTC based on the provided date, time, and timezone.
 * @param {string} localDate - The local date in "YYYY-MM-DD" format.
 * @param {string} localTime - The local time in "HH:mm" format.
 * @param {string} timeZone - The timezone (e.g., "America/New_York").
 * @returns {Object} - An object containing the UTC date and time.
 */
export const convertLocalToUTC = (localDate, localTime, timeZone) => {

    // Combine date and time into one string
    const combinedDateTime = moment.tz(`${localDate} ${localTime}`, "YYYY-MM-DD HH:mm", timeZone);

    // Convert to UTC
    const utcDateTime = combinedDateTime.utc();

    return {
        utcDate: utcDateTime.format("YYYY-MM-DD"),  // Get the UTC date
        utcTime: utcDateTime.format("HH:mm"),       // Get the UTC time
    };
};

// Define PropTypes for `convertLocalToUTC`
convertLocalToUTC.propTypes = {
    localDate: PropTypes.string.isRequired, // Expected date format: "YYYY-MM-DD"
    localTime: PropTypes.string.isRequired, // Expected time format: "HH:mm"
    timeZone: PropTypes.string.isRequired,  // Timezone string (e.g., "America/New_York")
};



// --------------------------------------------------------------------------------------------------------//

/**
 * Converts UTC time to local based on the provided date, time, and timezone.
 * @param {string} utcDate - The UTC date in "YYYY-MM-DD" format.
 * @param {string} utcTime - The UTC time in "HH:mm" format. 
 * @returns {Object} - An object containing the local date and time.
 */
export const convertUTCToLocal = (utcDate, utcTime,) => {

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Combine UTC date and time into one string and convert to local time zone
    const combinedDateTime = moment.tz(`${utcDate} ${utcTime}`, "YYYY-MM-DD HH:mm", "UTC").tz(userTimezone);

    return {
        localDate: combinedDateTime.format("YYYY-MM-DD"),  // Get the local date
        localTime: combinedDateTime.format("HH:mm"),       // Get the local time
    };
};

// Define PropTypes for `convertUTCToLocal`
convertUTCToLocal.propTypes = {
    utcDate: PropTypes.string.isRequired,   // Expected date format: "YYYY-MM-DD"
    utcTime: PropTypes.string.isRequired,   // Expected time format: "HH:mm" 
};


// ----------------------------------------------------------------------------------------------------------//

export const convertDateTimeToLocal = (utcDateTime) => {
    // Parse the UTC ISO 8601 string into a Date object
    const utcDateTimeObj = new Date(utcDateTime);

    // Get the local date and time using toLocaleDateString and toLocaleTimeString
    const localDate = utcDateTimeObj.toLocaleDateString("en-US");
    const localTime = utcDateTimeObj.toLocaleTimeString("en-US", { hour12: true, hour: '2-digit', minute: '2-digit' });

    return { localDate, localTime };
};

