import axios from "axios";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Check2Circle } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { confirmAlert } from "react-confirm-alert";
import * as Actions from "../../constants/baseUrl";
import EmailBodyContent from "../emailTemplate/emailBody";
import EmailFooter from "../emailTemplate/emailFooter";
import EmailHeader from "../emailTemplate/emailHeader";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import TokenDetails from "../useUserDetails/useTokenDetails";
import useUserData from "../useUserDetails/useUserDetails";
import { toast } from "react-toastify";
import { getCookie } from "../../utils/cookies";

const RegistrantsCampaignModal = ({
  show,
  selectedItemDetails,
  registeredEmails,
  onHide,
}) => {
  const myToken = getCookie("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;
  const userData = useUserData();

  const logo = SubDomainRouter("logo");
  const title = SubDomainRouter("title");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");
  const adminEmail = SubDomainRouter("adminEmail");
  const supportEmail = SubDomainRouter("supportEmail");
  const fromEmail = SubDomainRouter("fromEmail");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    campaign_name: `My Campaign For Registrants`,
    description: `Campaign for inviting registrants ${new Date()}`,
    userId: myUserId,
    type: "campaign",
    emaillists: [],
    entityRelation: [],
    subject: "",
    body_header: "",
    body: "",
    body_footer: "",
    body_plain_text: "",
    from: fromEmail,
    to: [],
    cc: userData?.email,
    bcc: [],
    scheduled_date: "",
    scheduled_time: "",
    campaign_sent_status: "",
    tags: [],
    comments: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const header_email_content = EmailHeader(logo, demosAlias, coursesAlias);
    const footer_email_content = EmailFooter(logo, title);
    const mapped_body_data = EmailBodyContent(
      [selectedItemDetails],
      formData.message || null,
      demosAlias,
      coursesAlias
    );
    const defaultTemplateBody = `${header_email_content} ${mapped_body_data} ${footer_email_content}`;

    const { message, ...requiredData } = formData;
    const data = {
      ...requiredData,
      emaillists: [selectedItemDetails?.emaillistid ?? ""],
      body: defaultTemplateBody,
      to: [...registeredEmails, adminEmail, supportEmail],
      cc: [formData?.cc],
      from: fromEmail,
    };

    axios
      .post(`${Actions.BASE_URL}campaigns`, data, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        successCampaignAlert("campaign");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong. Please Try again later.", {
          autoClose: 3000,
          hideProgressBar: true,
          draggable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const successCampaignAlert = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white py-4 px-2 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center mt-2 text-capitalize">
              Campaign Sent to all registrants.
            </h5>
            <button
              className="btn add-button mt-2"
              onClick={() => {
                onHide();
                onClose();
              }}
            >
              Done
            </button>
          </div>
        );
      },
    });
  };

  const handleFieldChange = (fieldName, fieldValue) => {
    const newFormData = {
      ...formData,
      [fieldName]: fieldValue,
    };
    setFormData(newFormData);
  };

  useEffect(() => {
    // const date = new Date();
    const newFormData = {
      ...formData,
      // scheduled_date: `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)}`,
      // scheduled_time: `${(`0${date.getHours()}`).slice(-2)}:${(`0${date.getMinutes()}`).slice(-2)}:${(`0${date.getSeconds()}`).slice(-2)
      //     }`,
      subject: `Invitations for ${selectedItemDetails.title || ""}`,
      cc: userData?.email || "",
    };
    setFormData(newFormData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, selectedItemDetails]);

  return (
    <Modal
      onHide={onHide}
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {loading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="h6 theme-text text-capitalize"
        >
          Send Campaign To Registrants
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="mx-0" onSubmit={(e) => handleSubmit(e)}>
          <Form.Group className="form-floating mb-3">
            <Form.Control
              type="email"
              className="italicInput"
              value={formData?.cc}
              id="cc"
              required
              onChange={(e) => handleFieldChange("cc", e.target.value)}
              placeholder="Enter Event Title"
              name="cc"
            />
            <Form.Label className="formLabel" htmlFor="cc">
              Enter From Email Address
            </Form.Label>
          </Form.Group>

          <Form.Group className="form-floating mb-3">
            <Form.Control
              type="text"
              value={formData?.subject}
              id="subject"
              onChange={(e) => handleFieldChange("subject", e.target.value)}
              placeholder="Enter Event Title"
              name="subject"
            />
            <Form.Label className="formLabel" htmlFor="subject">
              Enter Your Subject
            </Form.Label>
          </Form.Group>

          <Form.Group className="form-floating mb-3">
            <Form.Control
              as={"textarea"}
              rows={8}
              type="message"
              id="message"
              value={formData?.message}
              required
              onChange={(e) => handleFieldChange("message", e.target.value)}
              placeholder="Enter Event Title"
              name="message"
            />
            <Form.Label className="formLabel" htmlFor="message">
              Enter Your Message
            </Form.Label>
          </Form.Group>
          <div className="btn-wrap d-flex align-items-center justify-content-end">
            <Button type="submit" className="add-button text-center">
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export default RegistrantsCampaignModal;
