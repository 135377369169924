import React from "react";
import NavBar from "../components/headers/navbar";
import AdminNavBar from "../components/headers/adminnavbar";
import useUserData from "../components/useUserDetails/useUserDetails";
import Footer from "../components/footer/Footer";
import { Outlet } from "react-router-dom";
import UnAuthNavBar from "../components/headers/navbarUnAuth";
import SubscriptionModal from "../components/subscriptionModal/subscriptionModal.jsx";
import SubDomainRouter from "../components/subDomainRouter/subDomainRouter";

const RootLayout = () => {
  const userData = useUserData();
  const role = userData.role;
  const isValidUser =
    localStorage.getItem("isValidUser") === true ||
    localStorage.getItem("isValidUser") === "true";

  const footer_html = SubDomainRouter("footer_html");

  return (
    <>
      <div className="mainmenu">
        {["admin", "siteadmin", "superadmin"].includes(role) ? (
          <AdminNavBar />
        ) : isValidUser ? (
          <NavBar />
        ) : (
          <UnAuthNavBar />
        )}
      </div>

      <main
        style={{
          minHeight: "54.5vh",
        }}
      >
        <Outlet />
      </main>

      {!footer_html?.trim() || footer_html?.trim() === "footer_html" ? (
        <Footer />
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: footer_html,
          }}
        />
      )}

      {/* SubscriptionModal */}
      <SubscriptionModal />
    </>
  );
};

export default RootLayout;
