import { ErrorRounded } from "@mui/icons-material";
import React, { useRef, useState } from "react";
import { Button, Col, Form, Toast } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";

const VideoUploader = ({ state, onVideoChange, inputIdPrefix, fileCategory }) => {
  const [videos, setVideos] = useState([{ field_name: "", url: "", file_content: "", file_extension: "", uploadOption: "file" }]);
  const [showToast, setShowToast] = useState(false);
  const inputRef = useRef(null);

  const handleVideoChange = (event, index) => {
    const file = event.target?.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        inputRef.current.value = "";
        setShowToast(true);
        return;
      }
      const fileExtension = getFileExtension(file.name);
      getBase64(file).then((base64) => {
        const newVideo = {
          documentType: fileCategory,
          field_name: videos[index].field_name,
          file_content: base64,
          file_extension: fileExtension,
          url: videos[index].url,
          uploadOption: videos[index].uploadOption,
        };
        setVideos((prevVideos) => {
          const updatedVideos = [...prevVideos];
          updatedVideos[index] = newVideo;
          onVideoChange(updatedVideos);
          return updatedVideos;
        });
      });
    }
  };

  const getFileExtension = (fileName) => fileName.split(".").pop().toLowerCase();

  const onFieldNameChange = (index, value) => {
    setVideos((prevVideos) => {
      const updatedVideos = [...prevVideos];
      updatedVideos[index].field_name = value;
      onVideoChange(updatedVideos);
      return updatedVideos;
    });
  };

  const onURLChange = (index, value) => {
    setVideos((prevVideos) => {
      const updatedVideos = [...prevVideos];
      updatedVideos[index].url = value;
      onVideoChange(updatedVideos);
      return updatedVideos;
    });
  };

  const setUploadOptionForVideo = (index, value) => {
    setVideos((prevVideos) => {
      const updatedVideos = [...prevVideos];
      updatedVideos[index].uploadOption = value;
      onVideoChange(updatedVideos);
      return updatedVideos;
    });
  };

  const addVideoField = () => {
    setVideos((prevVideos) => [...prevVideos, { field_name: "", url: "", file_content: "", file_extension: "", uploadOption: "file" }]);
  };

  const removeVideoField = (index) => {
    setVideos((prevVideos) => {
      const updatedVideos = prevVideos.filter((_, i) => index !== i);
      onVideoChange(updatedVideos);
      return updatedVideos;
    });
  };

  const getBase64 = (file) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
  });



  const isEmbedUrl = (url) => {
    // Example check for YouTube embed URL
    return url?.includes("youtube.com/embed") || url?.includes("vimeo.com");
  };



  return (
    <Col md={12} className="dynamicField">
      <Button className="clickable add-button mb-3 ms-auto d-flex" onClick={addVideoField}>
        <span className="addBttn d-block">+</span>
      </Button>

      {videos.map((video, index) => (
        <div className="d-flex gap-3 align-items-start border-bottom mb-4" key={index}>

          <div className="d-flex flex-column flex-wrap gap-2 w-100 mb-3">
            {state?.videos && state?.videos[index]?.file_extension && state?.videos && state?.videos[index]?.file_content ? (
              <video
                src={`data:video/${state?.videos && state?.videos[index]?.file_extension};base64,${state?.videos && state?.videos[index]?.file_content}`}
                className="img-thumbnail rounded-2 w-100"
                width="100%"
                controls
                loading="lazy"
              ></video>
            ) : state?.videos && state?.videos[index]?.url ? (
              isEmbedUrl(state?.videos && state?.videos[index]?.url) ?
                <iframe
                  src={state?.videos && state?.videos[index]?.url}
                  title={(state?.videos && state?.videos[index]?.field_name) || "Video"}
                  loading="lazy"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="d-block w-100 h-100 rounded-1 border-secondary border-3 border"
                  style={{ aspectRatio: '16/9' }} // Maintain aspect ratio
                /> :
                <video
                  src={state?.videos && state?.videos[index]?.url}
                  loading="lazy"
                  className="uploaded-video-edit img-thumbnail rounded-2 w-100"
                  width="100%"
                  height="100%"
                  controls
                ></video>
            ) : null}


            <Form.Group className="form-floating select w-md-fit">
              <select
                id={`upload-option-${index}`}
                className="form-control form-select pe-md-4"
                required
                value={video.uploadOption}
                onChange={(e) => setUploadOptionForVideo(index, e.target.value)}
              >
                <option value="file">Upload Video File</option>
                <option value="url">Add Video URL</option>
              </select>
            </Form.Group>

            <Form.Group className="form-floating position-relative">
              <Form.Control
                type="text"
                className="uploadlabel docUploader w-100"
                placeholder="title"
                id={`${inputIdPrefix}-label-${index}`}
                value={video.field_name}
                onChange={(e) => onFieldNameChange(index, e.target.value)}
              />
              <Form.Label htmlFor={`${inputIdPrefix}-label-${index}`}>
                Add your {fileCategory} title
              </Form.Label>
            </Form.Group>

            {video.uploadOption === "file" && (
              <Form.Group className="flex-grow-1">
                <Form.Control
                  ref={inputRef}
                  type="file"
                  className="uploadFile docUploader w-100 mt-1"
                  id={`${inputIdPrefix}-file-${index}`}
                  accept="video/*"
                  onChange={(e) => handleVideoChange(e, index)}
                />
                <Form.Label htmlFor={`${inputIdPrefix}-file-${index}`} className="theme-text-2">
                  *Upload Your {fileCategory} (Up to 5MB)
                </Form.Label>
              </Form.Group>
            )}

            {video.uploadOption === "url" && (
              <Form.Group className="form-floating position-relative">
                <Form.Control
                  type="text"
                  className="uploadlabel docUploader w-100"
                  placeholder="Link"
                  id={`${inputIdPrefix}-label-link-${index}`}
                  value={video.url}
                  onChange={(e) => onURLChange(index, e.target.value)}
                />
                <Form.Label htmlFor={`${inputIdPrefix}-label-link-${index}`}>
                  Add A {fileCategory} URL
                </Form.Label>
              </Form.Group>
            )}
          </div>

          <Button className="clickable actionDltBtn btn border-danger" onClick={() => removeVideoField(index)}>
            <span className="addBttn d-block"><Trash /></span>
          </Button>
        </div>
      ))}

      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        autohide
        delay={3500}
        style={{ position: "absolute", top: "100%", right: 0, zIndex: "99" }}
      >
        <Toast.Header closeButton={false}>
          <strong className="mr-auto text-danger"><ErrorRounded />&nbsp;Error</strong>
        </Toast.Header>
        <Toast.Body>File size exceeds 5MB limit. Please choose a smaller video.</Toast.Body>
      </Toast>
    </Col>
  );
};

export default VideoUploader;
