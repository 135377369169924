import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../components/API/API";

// Create an async thunk for fetching data
export const fetchData = createAsyncThunk("host_setting/fetchData", async () => {
  const data = await API("get", 'configs/hostsettings');


  return data && data

});

const initialState = {
  value: null,
  status: "idle", // or "loading", "succeeded", "failed"
};

export const hostSettingSlice = createSlice({
  name: "hostSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.value = action.payload;
      })
      .addCase(fetchData.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectConfig = (state) => state.hostSettings.value;

export default hostSettingSlice.reducer;
