import { configureStore } from "@reduxjs/toolkit";
import hostSettingsReducer, {
  fetchData as fetchHostSettingsSliceData,
} from "./hostSettingsSlice";
import userReducer, { fetchUserData } from "./userSlice/userSlice";

export const store = configureStore({
  reducer: {
    hostSettings: hostSettingsReducer, 
    user: userReducer,
  },
});

// Dispatch the fetchData actions when the store is configured
store.dispatch(fetchUserData()); 
store.dispatch(fetchHostSettingsSliceData());
