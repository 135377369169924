import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";

const DynamicRelationFieldSet = ({
  onFieldChange,
  fieldLabels,
  selectOptions,
  fieldState,
}) => {
  const idInputRef = useRef(null);
  const [fields, setFields] = useState([{ type: "", value: "https://www." }]);

  // Initialize fields based on fieldState prop
  useEffect(() => {
    if (fieldState) {
      setFields(fieldState);
    }
  }, [fieldState]);

  const addField = () => {
    setFields((prevFields) => [
      ...prevFields,
      { type: "", value: "https://www." },
    ]);
  };

  const handleFieldChange = (index, newField) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.map((field, i) =>
        i === index ? { ...field, ...newField } : field
      );
      onFieldChange(updatedFields);
      return updatedFields;
    });
  };

  const onFieldRemove = (index) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.filter((_, i) => i !== index);
      onFieldChange(updatedFields);
      return updatedFields;
    });
  };

  return (
    <Col md={12} className="dynamicField">
      <Button
        className="clickable add-button mb-3 ms-auto d-flex"
        onClick={addField}
      >
        <span className="addBttn d-block">+</span>
      </Button>
      {fields.map((field, index) => (
        <div key={index} className="d-flex gap-3 w-100 align-items-center mb-3">
          <div className="flex-grow-1">
            <div className="form-floating formfloating">
              <Form.Select
                value={field.type}
                onChange={(e) =>
                  handleFieldChange(index, { type: e.target.value })
                }
                className="form-control form-select fromcontrol text-capitalize"
                id={`entityRelation_Type_${index}`}
                name={`entityRelation_Type_${index}`}
              >
                <option value="">Select Type</option>
                {selectOptions.map((option) => (
                  <option
                    className="text-capitalize"
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
          <div className="flex-grow-1">
            <div className="form-floating">
              <Form.Control
                ref={idInputRef}
                type="text"
                placeholder={fieldLabels.valueInput}
                value={field.value}
                onChange={(e) =>
                  handleFieldChange(index, { value: e.target.value })
                }
                className="form-control italicInput"
                name={`entityRelation_id_${index}`}
                id={`entityRelation_id_${index}`}
              />
              <label
                className="form-label"
                htmlFor={`entityRelation_id_${index}`}
              >
                {fieldLabels.valueInput}
              </label>
            </div>
          </div>
          <Button
            className="clickable add-button"
            onClick={() => onFieldRemove(index)}
          >
            <span className="addBttn d-block">-</span>
          </Button>
        </div>
      ))}
    </Col>
  );
};

export default DynamicRelationFieldSet;
