import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { mainTimeZones } from "../../constants/extras";
import DurationSelect from "../durationSelect/durationSelect";
import "./scheduleDateModal.css";

const ScheduleDateModalCourse = ({
  entityType,
  entity,
  show,
  setShow,
  state,
  setState,
  onHide,
}) => {
  const location = useLocation();
  const isEditing = location?.pathname?.includes("edit");

  const [eventStartDate, setEventStartDate] = useState(new Date());
  const [eventStartTime, setStartTime] = useState(new Date());
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);

  const [requestMade, setRequestMade] = useState(false);
  const [hasURL, setHasURL] = useState(false);
  const [activeTile, setActiveTile] = useState(null);

  const [isValid, setIsValid] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isURL, setIsURL] = useState(false);

  const [toastVisible, setToastVisible] = useState(false);

  useEffect(() => {
    if (state?.start_date && state?.start_time) {
      setEventStartDate(
        new Date(`${state?.start_date}T${state?.start_time}:00`)
      );
      setEventStartDate(
        new Date(`${state?.start_date}T${state?.start_time}:00`)
      );
    }
  }, [state?.start_date, state?.start_time]);

  // handle field change
  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...state,
      [fieldName]: fieldValue,
    };
    setState(newState);
  };

  useEffect(() => {
    // Set the default timezone to the user's current timezone
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimeZone({ label: userTimezone, value: userTimezone });

    handleFieldChange("timezone", { label: userTimezone, value: userTimezone });
  }, []);

  const timeZones = moment.tz
    .names()
    .filter((tz) => mainTimeZones.includes(tz))
    ?.map((tz) => ({
      label: tz,
      value: tz,
    }));

  const handleDateChange = (newValue) => {
    setEventStartDate(newValue);
    handleFieldChange("start_date", moment(newValue).format("YYYY/MM/DD"));
  };

  const handleTimeChange = (time) => {
    setStartTime(time);
    handleFieldChange("start_time", moment(time).format("HH:mm"));
  };

  const handleTimeZoneChange = (selectedOption) => {
    setSelectedTimeZone(selectedOption);
    handleFieldChange("timezone", selectedOption);
  };

  useEffect(() => {
    handleFieldChange("timezone", selectedTimeZone);
  }, [eventStartDate, eventStartTime, selectedTimeZone]);

  useEffect(() => {
    // made request when has date in state
    if (state?.start_date) {
      setRequestMade(true);
    } else {
      setRequestMade(false);
    }
    // made request when has date in state
    if (state?.register_url) {
      setHasURL(true);
    } else {
      setHasURL(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.start_date]);

  const handleSubmit = () => {
    if (hasURL) {
      if (state?.register_url && isURL) {
        setIsValid(true);
        setIsChanged(false);
        handleCloseModal();
      } else {
        setIsValid(false);
        setIsChanged(true);
      }
    }
  };

  useEffect(() => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    const isValidURL = urlPattern.test(state?.register_url);
    setIsURL(isValidURL);
  }, [state?.register_url, isURL, isValid]);

  const handleCloseModal = () => {
    toast.warning(
      "Event Date & Time cannot be changed once added. Please make Sure they are accurate.",
      { autoClose: 6000 }
    );
    onHide();
  };

  useEffect(() => {
    if (!(show && isEditing && !toastVisible)) {
      return;
    }
    setToastVisible(true);
    toast.warning("Event Date & Time cannot be changed", {
      autoClose: 2000,
    });
    setTimeout(() => {
      setToastVisible(false);
      onHide();
    }, 2000);
  }, [show, isEditing, toastVisible, onHide]);

  return (
    <Modal
      onHide={onHide}
      show={isEditing ? false : show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-capitalize h5"
        >
          Schedule your {entity}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mx-0">
          <Col md={12}>
            <Form.Group className="d-flex gap-2 align-items-end mb-3">
              <Form.Check
                id="meetingURL"
                className="custom-checkbox ps-0 theme-text-2 fw-medium"
                onChange={() => setHasURL(!hasURL)}
                checked={hasURL}
                label="I have my own meeting URL"
              />
            </Form.Group>
          </Col>
        </Row>
        {hasURL ? (
          <>
            <Row className="justify-content-center mx-0">
              <Col md={12}>
                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating">
                  <Form.Control
                    id="register_url"
                    name="register_url"
                    type="text"
                    placeholder="register Url"
                    value={state.register_url}
                    className={
                      (!isURL || !isValid) && isChanged ? "border-danger" : ""
                    }
                    onChange={(e) =>
                      handleFieldChange("register_url", e.target.value)
                    }
                  />
                  <Form.Label className="form-label" htmlFor="register_url">
                    Register Link For Users
                  </Form.Label>
                  {(!isURL || !isValid) && isChanged && (
                    <small className="text-end d-block text-danger">
                      Enter a valid register link
                    </small>
                  )}
                </Form.Group>
                <small className="text-start d-block text-dark fw-bold">
                  <span className="text-danger">*</span>Enter your Meeting URL
                  first
                </small>
              </Col>
            </Row>
            <Row className="position-relative py-3">
              {!isValid && !state.register_url && (
                <div className="semi-bg position-absolute w-100 h-100 start-0 top-0 overlayCalender" />
              )}
              <Col lg={6} className="position-relative">
                <Calendar
                  onChange={handleDateChange}
                  value={state?.start_date || eventStartDate}
                  className="mx-auto"
                  prevLabel={<ChevronLeft />}
                  tileClassName={({ date }) => {
                    return activeTile && activeTile.getDate() === date.getDate()
                      ? "active"
                      : "";
                  }}
                  onClickDay={setActiveTile}
                  nextLabel={<ChevronRight />}
                  minDate={new Date()}
                />

                <Form.Group className="form-floating my-3 mx-auto">
                  <Select
                    className="timezone form-control z-3"
                    required
                    id="timezone"
                    options={timeZones}
                    value={state?.timezone || selectedTimeZone}
                    onChange={handleTimeZoneChange}
                    placeholder="Select your time zone"
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group
                  className="form-floating mx-auto mb-3"
                  key={`${state?.start_date}T${state?.start_time}:00`}
                >
                  <ReactDatePicker
                    className="form-control form-select w-100"
                    selected={eventStartTime}
                    onChange={handleTimeChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={5}
                    dateFormat="h:mm aa"
                  />
                </Form.Group>
                <Form.Group className="form-floating mx-auto mb-3">
                  <DurationSelect
                    isCourse={true}
                    field={state}
                    handleFieldChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        ) : (
          <Row className="justify-content-center mx-0">
            <Col lg={6} className="position-relative">
              <Calendar
                onChange={handleDateChange}
                value={state?.start_date || eventStartDate}
                className="mx-auto"
                prevLabel={<ChevronLeft />}
                tileClassName={({ date }) => {
                  return activeTile && activeTile.getDate() === date.getDate()
                    ? "active"
                    : "";
                }}
                onClickDay={setActiveTile}
                nextLabel={<ChevronRight />}
                minDate={new Date()}
              />

              <Form.Group className="form-floating my-3 mx-auto">
                <Select
                  className="timezone form-control z-3"
                  required
                  id="timezone"
                  options={timeZones}
                  value={state?.timezone || selectedTimeZone}
                  onChange={handleTimeZoneChange}
                  placeholder="Select your time zone"
                />
              </Form.Group>
              {requestMade && (
                <>
                  <Form.Group
                    className="form-floating mx-auto mb-3"
                    key={`${state?.start_date}T${state?.start_time}:00`}
                  >
                    <ReactDatePicker
                      className="form-control form-select w-100"
                      selected={eventStartTime}
                      onChange={handleTimeChange}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={5}
                      dateFormat="h:mm aa"
                    />
                  </Form.Group>
                  <Form.Group className="form-floating mx-auto mb-3">
                    <DurationSelect
                      isCourse={true}
                      field={state}
                      handleFieldChange={handleFieldChange}
                    />
                  </Form.Group>
                </>
              )}
            </Col>
          </Row>
        )}
        {hasURL ? (
          <Button
            className="btn add-button float-end mt-3 px-4"
            onClick={handleSubmit}
          >
            Done
          </Button>
        ) : (
          <Button
            className="btn add-button float-end mt-3 px-4"
            onClick={handleCloseModal}
          >
            Done
          </Button>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ScheduleDateModalCourse;
