import React, { useEffect, useState } from "react";
import {
  Accordion,
  Card,
  Col,
  Container,
  Image,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { PlayCircle } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import API from "../../components/API/API";
import UTCLocalTimeConverter from "../../components/UTCLocalTimeConverter/UTCLocalTimeConverter";
import ConvertDateFormat from "../../components/convertDateFormat/convertDateFormat";
import UploadedDocuments from "../../components/documents/documents";
import ImageGallery from "../../components/gallery/gallery";
import CustomLoader from "../../components/loader/loader";
import StarRating from "../../components/starRating/starRating";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import VideoGallery from "../../components/videoGallery/videoGallery";
import withRouter from "../../constants/withRouter";

import CourseIMG from "../../img/course.png";
import USER from "../../img/user.png";
import ItemInfo from "./itemInfo";

function CohortDetails(props) {
  const entities = "items";
  const entityType = "cohort";

  const siteName = SubDomainRouter("title");

  const [loading, setLoading] = useState(true);
  const [entityData, setEntityData] = useState({});

  const populateList = async (id) => {
    try {
      const response = await API("get", entities + "/" + entityType + "/" + id);
      setEntityData(response);
    } catch (error) {
      console.log("There was an error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    populateList(props.params.id);
  }, [props.params.id]);

  return (
    <>
      <Helmet>
        <title>
          {entityData?.data?.title ? `${entityData?.data?.title}` : "Details"} |
          {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      <Container fluid="lg" className="my-5 pt-5">
        {loading ? (
          <CustomLoader />
        ) : (
          <div className="demosViewMoreSection demoProfile overflow-hidden mx-auto">
            <div className="coverImg p-2 position-relative w-100">
              <Image
                src={
                  entityData?.data?.hero_image_url ||
                  entityData?.data?.listing_image_url ||
                  "/assets/images/SWIPERIMG3-min.jpg"
                }
                width="100%"
                alt=""
              />
            </div>

            <Row className="profileHeader align-items-center position-relative py-2 mx-4">
              <Col lg={2} className="mr-3">
                <Image
                  src={
                    entityData?.data?.listing_image_url ||
                    entityData?.data?.hero_image_url ||
                    CourseIMG
                  }
                  alt="..."
                  className="profileImg"
                />
              </Col>
              <Col md={6} className="mt-lg-0 mt-5 pt-lg-0 pt-2 ps-4">
                <h4 className="trainerName mb-2">
                  {entityData?.data?.title}
                  <br />
                  <span className="industry">
                    <UTCLocalTimeConverter
                      showDate
                      utcDateTime={entityData?.data?.start_date_time}
                    />
                  </span>
                </h4>
                {entityData?.data?.star_rating_type && (
                  <StarRating rating={entityData?.data?.star_rating_type} />
                )}
              </Col>
              <Col
                lg={4}
                md={6}
                className="d-flex align-items-center justify-content-center gap-3"
              >
                <Link
                  to={`/enquiries/items_${entityType}/${entityData?.id}/add`}
                  className="add-button btn w-fit px-3 d-block"
                >
                  Enquiry
                </Link>
                {entityData?.data?.subcategorylevel1?.toLowerCase() ===
                  "paid" && (
                  <Link
                    to={`/pay?currency=${entityData?.data?.currency}&amount=${entityData?.data?.amount}&email=${entityData?.data?.userId}&entityType=${entityData.type}&entityId=${entityData.id}`}
                    className="add-button btn w-fit px-3 d-block"
                  >
                    Pay
                  </Link>
                )}
              </Col>
            </Row>

            <Tabs
              defaultActiveKey="details"
              id="demo-details"
              className="mx-4 mt-2"
              fill
            >
              <Tab eventKey="details" title="Details" className="m-4 mt-0">
                <ItemInfo entityData={entityData?.data} />
              </Tab>
              <Tab
                eventKey="speaker"
                title="Speaker Details"
                className="m-4 mt-0"
              >
                <Card className="border-top-0 rounded-top-0 FAQs">
                  <Card.Body>
                    <Row>
                      {entityData?.data?.speakers?.length > 0 &&
                      entityData?.data?.speakers[0].question !== "" ? (
                        entityData?.data?.speakers.map((item, index) => (
                          <Col sm={12} key={index} className="mt-2 my-3">
                            <Card className="profileCard">
                              <div className="contUser d-flex align-items-end gap-2">
                                <div className="userImage">
                                  <Card.Img
                                    src={item?.profile_link || USER}
                                    alt="Load Error"
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = USER;
                                    }}
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="d-flex align-items-center justify-content-between gap-1">
                                  <div className="det-cont flex-grow-1">
                                    <p className="username mt-2 mb-0">
                                      {item.first_name}&nbsp;
                                      {item.last_name}
                                    </p>
                                    {item.post && (
                                      <p className="info specialization mb-1 mt-0">
                                        {item.post}
                                      </p>
                                    )}
                                    {item?.star_rating && (
                                      <StarRating rating={item?.star_rating} />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <Card.Body className="text-start flex-column d-flex justify-content-between rounded-3 shadow-none border-secondary-subtle border">
                                <Row className="content mt-2">
                                  <Col md={3}>
                                    <Card.Text className="mb-0 fw-bold info">
                                      Phone
                                    </Card.Text>
                                  </Col>
                                  <Col md={9}>
                                    <Card.Text className="text-muted mb-0 info">
                                      <span>
                                        {item.Phone || "Not Available"}
                                      </span>
                                    </Card.Text>
                                  </Col>
                                </Row>
                                <hr className="my-2" />
                                <Row className="content">
                                  <Col md={3}>
                                    <Card.Text className="mb-0 fw-bold info">
                                      Whatsapp
                                    </Card.Text>
                                  </Col>
                                  <Col md={9}>
                                    <Card.Text className="text-muted mb-0 info">
                                      <span>
                                        {item.whatsapp || "Not Available"}
                                      </span>
                                    </Card.Text>
                                  </Col>
                                </Row>
                                <hr className="my-2" />
                                <Row className="content">
                                  <Col md={3}>
                                    <Card.Text className="mb-0 fw-bold info">
                                      BIO
                                    </Card.Text>
                                  </Col>
                                  <Col md={9}>
                                    <Card.Text className="text-muted mb-0 info">
                                      <span>{item.bio || "Not Available"}</span>
                                    </Card.Text>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))
                      ) : (
                        <p>No FAQs Available</p>
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="Feedbacks" title="Feedbacks" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0 FAQs">
                  <Card.Body>
                    <h5 className="theme-text-2 mt-2 w-100">Feedbacks:</h5>
                    <hr />
                    <Row>
                      {entityData?.data?.textFeedbacks?.length > 0 &&
                      entityData?.data?.textFeedbacks[0].feedback !== "" ? (
                        entityData?.data?.textFeedbacks.map((item, index) => (
                          <Col lg={6} key={index} className="mt-2 my-3">
                            <Card className="profileCard">
                              <div className="contUser d-flex align-items-end gap-2">
                                <div className="userImage">
                                  <Card.Img
                                    src={item?.profiprofilele_link || USER}
                                    alt="Load Error"
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = USER;
                                    }}
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="d-flex align-items-center justify-content-between gap-1">
                                  <div className="det-cont flex-grow-1">
                                    <p className="username mt-2 mb-0">
                                      {item.user_name}
                                    </p>
                                    {item.user_post && (
                                      <p className="info specialization mb-1 mt-0">
                                        {item.user_post}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <Card.Body className="text-start flex-column d-flex justify-content-between rounded-3 shadow-none border-secondary-subtle border">
                                <Card.Text className="text-muted mb-0 info pt-3">
                                  <span>
                                    ~ {item.feedback || "Not Available"}
                                  </span>
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))
                      ) : (
                        <p>No FAQs Available</p>
                      )}
                    </Row>
                  </Card.Body>

                  <Card.Body>
                    <h5 className="theme-text-2 mt-2 w-100">
                      Video feedbacks:
                    </h5>
                    <hr />
                    <div className="d-flex align-items-center gap-3 flex-wrap">
                      {entityData?.data?.videoFeedbacks?.length > 0 &&
                      entityData?.data?.videoFeedbacks[0].video_url ? (
                        entityData?.data?.videoFeedbacks?.map((item, index) => (
                          <Card.Text
                            className="text-muted mb-0 d-flex align-items-center gap-2 p-2 border-secondary-subtle border rounded-3"
                            jey={index}
                          >
                            <Link
                              to={item?.video_url}
                              target="_blank"
                              className="btn-link theme-text-2 btn d-flex align-items-center gap-1 text-decoration-none p-0"
                            >
                              <PlayCircle /> Watch Video
                            </Link>
                          </Card.Text>
                        ))
                      ) : (
                        <p>No Videos Found</p>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Tab>

              {/* Attachments */}
              <Tab
                eventKey="Attachments"
                title="Attachments"
                className="m-4 mt-0"
              >
                <Tabs
                  defaultActiveKey="gallery"
                  id="attachment-details"
                  className="mt-2"
                  fill
                >
                  <Tab eventKey="gallery" title="Gallery">
                    <Card className="border-top-0 rounded-top-0">
                      <Card.Body>
                        <ImageGallery
                          imageData={entityData?.data?.attachments?.images}
                        />
                      </Card.Body>
                    </Card>
                  </Tab>
                  <Tab eventKey="videos" title="Videos">
                    <Card className="border-top-0 rounded-top-0">
                      <Card.Body>
                        <VideoGallery
                          videoData={entityData?.data?.attachments?.videos}
                        />
                      </Card.Body>
                    </Card>
                  </Tab>
                  <Tab eventKey="docs" title="Docs">
                    <Card className="border-top-0 rounded-top-0">
                      <Card.Body>
                        <UploadedDocuments
                          docData={entityData?.data?.attachments?.documents}
                        />
                      </Card.Body>
                    </Card>
                  </Tab>
                </Tabs>
              </Tab>
              <Tab eventKey="faqs" title="FAQs" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0 FAQs">
                  <Card.Body>
                    <h5 className="about-headings text-wrap-balance theme-text-2 mb-3">
                      Frequently Asked Questions (FAQs)
                    </h5>
                    {entityData?.data?.FAQs?.length > 0 &&
                    entityData?.data?.FAQs[0].question !== "" ? (
                      <Accordion defaultActiveKey={["0"]} alwaysOpen>
                        {entityData?.data?.FAQs?.map((item, index) => (
                          <Accordion.Item
                            eventKey={`${index}`}
                            key={index}
                            className="border-0 mb-3"
                          >
                            <Accordion.Header>
                              {item?.question} <span></span>
                            </Accordion.Header>
                            <Accordion.Body>{item?.answer}</Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    ) : (
                      <p>No FAQs Available</p>
                    )}
                  </Card.Body>
                </Card>
              </Tab>
            </Tabs>
          </div>
        )}
      </Container>
    </>
  );
}
export default withRouter(CohortDetails);
