import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import Select from "react-select";
import { WhatsappIcon } from "react-share";
import * as Actions from "../../constants/baseUrl";
import CampaignRelations from "../dynamicRelationFieldSet/campaignRelations";
import CustomLoader from "../loader/loader";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import TokenDetails from "../useUserDetails/useTokenDetails";
import { toast } from "react-toastify";
import { getCookie } from "../../utils/cookies";

const WhatsAppCampaign = () => {
  const myToken = getCookie("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;
  const title = SubDomainRouter("title");

  const [loading, setLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [step, setStep] = useState(1);
  const [whatsappLists, setWhatsappLists] = useState([]);
  const [selectedItemDetails, setSelectedItemDetails] = useState([]);
  const [relationData, setRelationData] = useState(
    [{ type: "", value: "" }] || []
  );
  const [formData, setFormData] = useState({
    campaign_name: "Whatsapp campaign",
    description: "My Campaign Description",
    userId: myUserId,
    type: "campaign",
    whatsapplists: [],
    entityRelation: [],
    subject: `${title} whatsapp campaign starts`,
  });
  const [selectedWhatsappList, setSelectedWhatsappList] = useState([]);
  const [whatsappContacts, setWhatsappContacts] = useState([]);
  const [checkedShow, setCheckedShow] = useState(false);

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...formData,
      [fieldName]: fieldValue,
    };

    setFormData(newState);
  };

  // Get Whatsapplists
  const getWhatsapplists = () => {
    axios
      .get(`${Actions.BASE_URL}whatsappLists/users/${myUserId}`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setWhatsappLists(res.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong. Please Try again later.", {
          autoClose: 3000,
          hideProgressBar: true,
          draggable: true,
        });
      });
  };

  const handleWhatsappListSelection = (selectedListName) => {
    setSelectedWhatsappList(selectedListName);
  };

  const getContactsForList = async (listID) => {
    setLoading(true);
    try {
      await axios
        .get(`${Actions.BASE_URL}contacts/${myUserId}/whatsapplist/${listID}`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // Extract contacts from the response and add unique contacts to the array
          const contactsForList = response.data;
          contactsForList.forEach((contact) => {
            setWhatsappContacts((prevContacts) => {
              // Check for existing contact based on ID
              const existingContact = prevContacts.find(
                (c) => c.id === contact.id
              );

              // Only add the contact if it doesn't exist in the array
              if (!existingContact) {
                return [...prevContacts, contact];
              }

              return prevContacts;
            });
          });
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.error(error.response);
    }
  };

  const fetchContactsForAllLists = async () => {
    // Clear existing contacts before fetching for new lists
    setWhatsappContacts([]);

    // Iterate through each selected WhatsApp list
    for (const listID of selectedWhatsappList) {
      await getContactsForList(listID);
    }
  };

  useEffect(() => {
    fetchContactsForAllLists();
  }, [selectedWhatsappList]);

  // Steps
  const handleNext = async () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = () => {
    const data = {
      ...formData,
      entityRelation: relationData,
      whatsapplists: [...selectedWhatsappList],
    };

    axios
      .post(`${Actions.BASE_URL}campaigns`, data, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setLoadingProgress(false);
      })
      .catch((error) => {
        setLoadingProgress(false);
        console.log(error.response);
      });
  };

  // Call Req fun initially
  useEffect(() => {
    getWhatsapplists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");

  const currentDomain = window.location.hostname;

  const itemsDataTemp = `${
    formData?.body_plain_text ? `*${formData?.body_plain_text}*\n` : ""
  }
${selectedItemDetails
  ?.map(
    (detail) =>
      `
      _*${detail?.title || detail?.company_name}*_ \n
      ${detail?.description && `${detail?.description}\n`} 
      https://${currentDomain}/${detail?.type}s/details/${detail?.id} \n`
  )
  .join("")}`;

  return (
    <Container fluid="lg" className="formMain create p-0 demosEditSection">
      {loadingProgress ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}
      <div className="demosAddFormHeader text-center">
        <h2 className="addFormHeading theme-text-2">Whatsapp Campaign</h2>
        <div className="demosEditHeader text-center my-3 mb-lg-5">
          <ul className="demosEditList">
            <li>
              <button
                className={`stepstriggers ${step === 1 ? "active" : null}`}
                onClick={() => setStep(1)}
              >
                <span>1 </span>
              </button>
            </li>
            <li>
              <button
                className={`stepstriggers ${step === 2 ? "active" : null}`}
                onClick={() => setStep(2)}
              >
                <span>2</span>
              </button>
            </li>
            <li>
              <button
                className={`stepstriggers ${step === 3 ? "active" : null}`}
                onClick={() => setStep(3)}
                disabled={selectedWhatsappList?.length === 0}
              >
                <span>3</span>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {/* Step 1 */}
        <Row className={`mx-0 ${step === 1 ? "d-block" : "d-none"}`}>
          <Col md={12} className="d-flex justify-content-between gap-3">
            <h5 className="theme-text flex-grow-1">
              Select relevant course(s) or demo(s)
            </h5>
            <Button
              className="clickable add-button mb-2 ms-auto d-flex"
              onClick={() => {
                const newField = { type: "", value: "" };
                setRelationData([...relationData, newField]);
              }}
            >
              <span className="addBttn d-block">+</span>
            </Button>
          </Col>
          <hr />
          <Col md={12}>
            {relationData?.map((field, index) => (
              <CampaignRelations
                key={index}
                fields={relationData}
                field={field}
                index={index}
                onEntityDetailsChange={(selectedItems) => {
                  setSelectedItemDetails(selectedItems);
                }}
                onFieldChange={(newFields) => {
                  setRelationData(newFields);
                }}
                fieldLabels={{
                  valueInput: "Select An Entity",
                }}
                selectOptions={[
                  { value: "demo", label: demosAlias },
                  { value: "course", label: coursesAlias },
                ]}
              />
            ))}
          </Col>
          <Col md={12} className="mt-3">
            <Button
              type="button"
              onClick={handleNext}
              className="next demosEditBtn w-auto"
            >
              Next
            </Button>
          </Col>
        </Row>
        {/* step2 */}
        <Row className={`mx-0 ${step === 2 ? "d-block" : "d-none"}`}>
          <Col md={12}>
            <h5 className="theme-text mb-2">
              Select Whatsapp Lists & Add Message
            </h5>
          </Col>
          <hr />

          <Col md={12}>
            <small className="text-end d-block text-dark">
              required<span className="text-danger">*</span>
            </small>
            <Form.Group className="form-floating mb-3">
              <Select
                className="text-capitalize form-control select-email"
                required
                isMulti
                placeholder="Select an existing email list"
                options={whatsappLists?.map((email) => ({
                  value: email.id,
                  label: email.list_name,
                }))}
                onChange={(selectedOptions) =>
                  handleWhatsappListSelection(
                    selectedOptions
                      ? selectedOptions?.map((option) => option.value)
                      : []
                  )
                }
              />
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group className="form-floating mb-3">
              <Form.Control
                as={"textarea"}
                rows={"4"}
                placeholder="body_plain_text"
                className="form-control max-h-auto"
                name="body_plain_text"
                value={formData?.body_plain_text}
                id="body_plain_text"
                onChange={(e) =>
                  handleFieldChange("body_plain_text", e.target.value)
                }
              />
              <Form.Label className="form-label" htmlFor="body_plain_text">
                Your Message
              </Form.Label>
            </Form.Group>
          </Col>
          <Col md={12} className="mt-3">
            <Button
              type="button"
              className="previous-button demosEditBtn"
              onClick={handlePrevious}
            >
              Previous
            </Button>
            <Button
              type="submit"
              disabled={selectedWhatsappList?.length === 0}
              onClick={handleNext}
              className="next demosEditBtn"
            >
              Next
            </Button>
          </Col>
        </Row>
        {step === 3 && (
          <Row className="mx-0">
            <Col
              md={12}
              className=" d-flex align-items-center justify-content-between flex-wrap"
            >
              <h5 className="theme-text mb-2">Send Message To Your Contacts</h5>
              <Form.Group>
                <Form.Check
                  label="Show Contacts"
                  name="showContacts"
                  type="checkbox"
                  id="showContacts"
                  className="ps-0 custom-checkbox fw-medium"
                  checked={checkedShow}
                  onClick={() => setCheckedShow(!checkedShow)}
                />
              </Form.Group>
            </Col>
            <hr />
            {}
            {loading ? (
              <CustomLoader />
            ) : (
              <>
                {whatsappContacts?.length > 0 ? (
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th className="bg-theme bg-opacity-50 text-light">
                          First Name
                        </th>
                        <th className="bg-theme bg-opacity-50 text-light">
                          Last Name
                        </th>
                        <th className="bg-theme bg-opacity-50 text-light">
                          Whatsapp
                        </th>
                        <th className="bg-theme bg-opacity-50 text-light text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {whatsappContacts?.map((contact, index) => (
                        <tr key={index}>
                          <td>{contact.first_name}</td>
                          <td>{contact.last_name}</td>
                          <td>
                            {" "}
                            {contact?.whatsapp
                              ? checkedShow
                                ? contact?.whatsapp
                                : "Available"
                              : "Not Available"}
                          </td>
                          <td className="d-flex align-items-center justify-content-center gap-3">
                            {/* <WhatsappShareButton
                              className="w-auto d-flex align-items-center"
                              title={itemsDataTemp}
                              url={`https://${currentDomain}`}
                              separator={`\n`}
                            >
                              <WhatsappIcon size={22} round={true} />
                              &nbsp;Send
                            </WhatsappShareButton> */}
                            <a
                              target="_blank"
                              href={`https://wa.me/${contact.whatsapp}?text=${itemsDataTemp}`}
                              rel="noreferrer"
                              className="text-decoration-none d-flex align-items-center text-dark"
                            >
                              <WhatsappIcon size={22} round={true} />
                              &nbsp;Send
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>⚠An error occurred!</p>
                )}
              </>
            )}
            <Col md={12} className="mt-3">
              <Button
                type="button"
                className="previous-button demosEditBtn w-auto"
                onClick={handlePrevious}
              >
                Previous
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </Container>
  );
};

export default WhatsAppCampaign;
