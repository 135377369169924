export const AdminMenuDataMainSite = [
  {
    title: "My Dashboard",
    Url: "/dashboard/my-events",
  },
  {
    title: "My Contacts",
    Url: "/my-contacts/all-contacts",
  },
  {
    title: "My Campaigns",
    Url: "campaigns-dashboard/email-campaign",
  },
  {
    title: "Profile",
    Url: "/my-profile",
  },
  {
    title: "Contact",
    Url: "/contact",
  },
  // {
  //   title: "Coordinator Dashboard",
  //   Url: "/coordinator-dashboard/my-trainers",
  // },
  // {
  //   title: "Pay",
  //   Url: "/pay",
  // },
  // {
  //   title: "Projects",
  //   Url: "/projects",
  // },
  // {
  //   title: "Teams",
  //   Url: "/teams",
  // },
  // {
  //   title: "Subscribers",
  //   Url: "/subscribers",
  // },
  // {
  //   title: "Mentors",
  //   Url: "/mentors",
  // },
  // {
  //   title: "BigBrothers",
  //   Url: "/bigbrothers",
  // },
  // {
  //   title: "Videos",
  //   Url: "/videos",
  // },
  // {
  //   title: "Trainer Videos",
  //   Url: "/trainervideos",
  // },
];
