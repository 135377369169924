import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import API from "../../../components/API/API";
import CustomLoader from "../../../components/loader/loader";
import SubDomainRouter from "../../../components/subDomainRouter/subDomainRouter";
import withRouter from "../../../constants/withRouter";

function PageDetails(props) {
  const entities = "items";
  const entityType = "page";

  const siteName = SubDomainRouter("title");

  const [loading, setLoading] = useState(true);
  const [entityData, setEntityData] = useState({});

  const populateList = async (id) => {
    try {
      const response = await API("get", `${entities}/${entityType}/${id}`);
      setEntityData(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    populateList(props.params.id);
  }, [props.params.id]);

  return (
    <>
      <Helmet>
        <title>
          {entityData?.data?.page_title
            ? `${entityData?.data?.page_title}`
            : "Page"}{" "}
          |{siteName ? siteName : window.location.host}
        </title>
        {/* styles */}
        {entityData?.data?.styleLinks?.length > 0 &&
        entityData?.data?.styleLinks[0]?.style_link !== ""
          ? entityData?.data?.styleLinks.map((item, index) =>
              item?.style_link.startsWith("<link") ? (
                item.style_link
              ) : (
                <link
                  rel="stylesheet"
                  href={item?.style_link}
                  data-priority="high"
                  key={index}
                />
              )
            )
          : ""}

        {entityData?.data?.custom_css?.startsWith("<style") ? (
          entityData?.data?.custom_css
        ) : entityData?.data?.custom_css ? (
          <style data-priority="high">{entityData?.data?.custom_css}</style>
        ) : (
          ""
        )}

        {/* script */}
        {entityData?.data?.scriptLinks?.length > 0 &&
        entityData?.data?.scriptLinks[0]?.script_link !== ""
          ? entityData?.data?.scriptLinks.map((item, index) =>
              item?.script_link?.startsWith("<script") ? (
                item.script_link
              ) : (
                <script
                  src={item?.script_link}
                  type="text/javascript"
                  key={index}
                />
              )
            )
          : ""}

        {entityData?.data?.custom_js?.startsWith("<script") ? (
          entityData?.data?.custom_js
        ) : entityData?.data?.custom_js ? (
          <script type="text/javascript">{entityData?.data?.custom_js}</script>
        ) : (
          ""
        )}

        {entityData?.data?.useDefaultLayout ? (
          ""
        ) : (
          <style data-priority="high">
            {`.footerSection,.mainmenu{display: none;}`}
          </style>
        )}
      </Helmet>

      {loading ? (
        <CustomLoader />
      ) : (
        <div
          className={entityData?.data?.useDefaultLayout ? "mt-5 pt-4" : ""}
          dangerouslySetInnerHTML={{
            __html: entityData?.data?.html_content || "Nothing to show",
          }}
        />
      )}
    </>
  );
}
export default withRouter(PageDetails);
