import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import { Plus, Trash } from "react-bootstrap-icons";
import { BiCopy } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import API from "../../components/API/API";
import BannerUploaderEdit from "../../components/bannerUploaderEdit/bannerUploaderEdit";
import DynamicRelationFieldEdit from "../../components/dynamicRelationFieldSet/dynamicRelationFieldEdit";
import CustomLoader from "../../components/loader/loader";
import CustomerAddPopup from "../../components/startupPopup/customerAddPopup";
import MemberAddPopup from "../../components/startupPopup/memberAddPopup";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import AddTag from "../../components/tagInput/tagInput";
import TextFormatter from "../../components/textFormat/textFormat";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import {
  companyStage,
  countries,
  currencies,
  industries,
  years,
} from "../../constants/extras";
import withRouter from "../../constants/withRouter";
import UploadFile from "../../helper/UploadFile";
import placehold from "../../img/2942004.png";
import userImg from "../../img/user.png";
import "./startup.css";

const AddEditStartups = (props) => {
  const item_id = props.params.id;

  const startupAlias = SubDomainRouter("startupAlias");
  const entityType = 'startup'
  const entities = "items";
  const navigate = useNavigate();

  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;
  // states
  const [loading, setLoading] = useState(true);
  const [reqLoading, setReqLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  const [socialLinks, setSocialLinks] = useState([]);

  // team
  const [memberAddModalShow, setMemberAddModalShow] = useState(false);
  const [selectedMemberData, setSelectedMemberData] = useState({});
  // customer
  const [customerAddModalShow, setCustomerAddModalShow] = useState(false);
  const [selectedCustomerData, setSelectedCustomerData] = useState({});
  // investor
  const [investorAddModalShow, setInvestorAddModalShow] = useState(false);
  const [selectedInvestorData, setSelectedInvestorData] = useState({});

  // check values
  const [hasValue, setHasValue] = useState(false);
  const [isValid, setIsValid] = useState(false);

  // * ==========================================================================
  const populateList = async (id) => {
    if (!id) {
      setLoading(false);
      return;
    }
    setLoading(true);

    //
    try {
      const response = await API("get", entities + `/${entityType}/` + id);
      setFormData(response?.data);
      setSocialLinks(response?.data?.social_links || [{ type: "", value: "" }]);
    } catch (error) {
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    populateList(item_id);
  }, [item_id]);
  // ========================

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...formData,
      [fieldName]: fieldValue,
    };
    setFormData(newState);
  };

  // Checking field values

  const step1Fields = [
    "company_name",
    "registered_company_name",
    "year_of_establishment",
    "website",
    "country",
    "state",
    "city",
    "company_stage",
    "industry",
  ];
  const step2Fields = [
    "sector",
    "description",
    "entity_type",
    "team_size",
    "principle_currency",
  ];

  const areFieldsFilled = (fieldNames, formData) => {
    return fieldNames?.every((fieldName) =>
      formData[fieldName] ? formData[fieldName] : ""
    );
  };

  const isStep1FieldsFilled = areFieldsFilled(step1Fields, formData);
  const isStep2FieldsFilled = areFieldsFilled(step2Fields, formData);

  const handleNext = () => {
    const isStep1FieldsFilled = step1Fields.every((field) => formData[field]);

    if (isStep1FieldsFilled) {
      setStep((prevStep) => prevStep + 1);
      setHasValue(false);
      setIsValid(true);
    } else if (step === 2 && isStep2FieldsFilled) {
      setStep((prevStep) => prevStep + 1);
      setHasValue(false);
      setIsValid(true);
    } else {
      setHasValue(true);
      setIsValid(true);
      window.scrollTo(0, 0);
    }
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  // * ==========================================================================

  const handleSubmit = async (event) => {
    setReqLoading(true);

    const reqData = {
      userId: myUserId,
      type: entityType,
      host: window.location.host,
      header: {
        host: window.location.host,
        userId: myUserId,
      },
      data: {
        userId: myUserId,
        social_links: socialLinks,
        ...formData,
      },
    };
    try {
      //
      if (item_id) {
        await API("put", entities + `/${entityType}/` + item_id, reqData);
        navigate(`/${entityType}/items`);
      } else {
        const response = await API("post", entities, reqData);
        navigate(`/share/items_${entityType}/${response.id}/`);
      }
      //
    } catch (error) {
      console.log(error.response);
    } finally {
      setReqLoading(false);
    }
  };

  // * ==========================================================================

  const deletePerson = (id, group) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [group]: prevFormData[group].filter((member) => member.id !== id),
    }));
  };

  const replicatePerson = (group, id) => {
    const personToReplicate = formData[group].find(
      (person) => person.id === id
    );

    const newPerson = {
      ...personToReplicate,
      id: uuidv4(), // Generating new UUID for replicated person
    };

    setFormData((prevState) => ({
      ...prevState,
      [group]: [...prevState[group], newPerson],
    }));
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="demosAddFormSection demosEditSection">
          {reqLoading ? (
            <>
              <span className="register-loader"> </span>
              <span className="spinnerOverlay" />
            </>
          ) : null}

          <Container fluid="lg" className="formMain create my-3">
            <div className="demosAddFormHeader text-center">
              <h2 className="addFormHeading text-capitalize">
                {item_id ? "Edit" : "Create"}&nbsp;
                {startupAlias ? startupAlias : "Startup"}
              </h2>
              <div className="demosEditHeader text-center mb-3 mb-lg-5">
                <h5> {item_id ? "Edit" : "Add"} Details Below:</h5>
                <ul className="demosEditList">
                  <li>
                    <button
                      className={
                        step === 1 ? "active stepstriggers" : "stepstriggers"
                      }
                      onClick={() => setStep(1)}
                    >
                      <span>1</span>
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        step === 2 ? "active stepstriggers" : "stepstriggers"
                      }
                      onClick={() => setStep(2)}
                      disabled={!isStep1FieldsFilled}
                    >
                      <span>2</span>
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        step === 3 ? "active stepstriggers" : "stepstriggers"
                      }
                      onClick={() => setStep(3)}
                      disabled={!isStep1FieldsFilled || !isStep2FieldsFilled}
                    >
                      <span>3</span>
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        step === 4 ? "active stepstriggers" : "stepstriggers"
                      }
                      onClick={() => setStep(4)}
                      disabled={!isStep1FieldsFilled || !isStep2FieldsFilled}
                    >
                      <span>4</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            {/*  */}

            <Form
              id="add-demo-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="form-section addFormSection event-detail">
                {step === 1 && (
                  <fieldset className="wizard-fieldset demosAddForm position-relative overflow-hidden">
                    <Row className="mx-0 position-relative">
                      <Col md={12} className="position-relative">
                        {formData?.hero_image_url ||
                          formData.hero_image_content ? (
                          <Button
                            className="deleteBtn p-1 btn-danger text-white bg-danger h-auto w-auto position-absolute bottom-0 end-0 mb-5 me-4 z-3"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                hero_image_url: "",
                                hero_image_content: "",
                              });
                            }}
                          >
                            <Trash />
                          </Button>
                        ) : null}
                        <BannerUploaderEdit
                          className="item-hero-img"
                          key={formData?.hero_image_url}
                          label="Big Banner"
                          accept="image/png, image/jpg, image/jpeg"
                          imageType="hero_image"
                          placehold={
                            formData.hero_image_url ||
                            "https://placehold.co/1024x200"
                          }
                          state={formData}
                          setState={(data) => {
                            UploadFile(
                              formData.userId,
                              "hero_image",
                              data?.hero_image_content,
                              data?.hero_image_extension,
                              setReqLoading
                            ).then((url) => {
                              setFormData({
                                ...formData,
                                hero_image_url: url,
                                hero_image_content: "",
                              });
                            });
                          }}
                          imageURL={formData?.hero_image_url}
                          imageContent={formData?.hero_image_content}
                          imageExtension={formData?.hero_image_extension}
                        />
                      </Col>

                      <Col
                        lg={5}
                        md={6}
                        className="list-img-wrap position-relative"
                      >
                        {formData?.listing_image_url ||
                          formData.listing_image_content ? (
                          <Button
                            className="deleteBtn p-1 btn-danger text-white bg-danger h-auto w-auto position-absolute bottom-0 end-0 mb-4 me-4 z-3"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                listing_image_url: "",
                                listing_image_content: "",
                              });
                            }}
                          >
                            <Trash />
                          </Button>
                        ) : null}
                        <BannerUploaderEdit
                          className="item-list-img"
                          key={formData?.listing_image_url}
                          label="Small Banner"
                          accept="image/png, image/jpg, image/jpeg"
                          imageType="listing_image"
                          placehold={
                            formData.listing_image_url ||
                            "https://placehold.co/280x180"
                          }
                          state={formData}
                          setState={(data) => {
                            UploadFile(
                              formData.userId,
                              "listing_image",
                              data?.listing_image_content,
                              data?.listing_image_extension,
                              setReqLoading
                            ).then((url) => {
                              setFormData({
                                ...formData,
                                listing_image_url: url,
                                listing_image_content: "",
                              });
                            });
                          }}
                          imageURL={formData?.listing_image_url}
                          imageContent={formData?.listing_image_content}
                          imageExtension={formData?.listing_image_extension}
                        />
                      </Col>
                    </Row>
                    <Row className="mx-0">
                      <Col md={12}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="text"
                            className={`italicInput ${isValid && hasValue && !formData.company_name
                              ? "border-danger"
                              : ""
                              }`}
                            id="company_name"
                            required
                            value={formData?.company_name}
                            placeholder="Enter Event Title"
                            name="company_name"
                            autoFocus
                            onChange={(e) =>
                              handleFieldChange("company_name", e.target.value)
                            }
                          />
                          <Form.Label
                            className="formLabel"
                            htmlFor="company_name"
                          >
                            Company Name
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="text"
                            className={
                              isValid &&
                                hasValue &&
                                !formData.registered_company_name
                                ? "border-danger"
                                : ""
                            }
                            id="registered_company_name"
                            required
                            value={formData?.registered_company_name}
                            placeholder="registered_company_name"
                            name="registered_company_name"
                            onChange={(e) =>
                              handleFieldChange(
                                "registered_company_name",
                                e.target.value
                              )
                            }
                          />
                          <Form.Label
                            className="formLabel"
                            htmlFor="registered_company_name"
                          >
                            Registered Company Name
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Select
                            id="year_of_establishment"
                            className={`form-control text-capitalize ${isValid &&
                              hasValue &&
                              !formData.year_of_establishment
                              ? "border-danger"
                              : ""
                              }`}
                            required
                            value={formData?.year_of_establishment}
                            name="year_of_establishment"
                            multiple=""
                            onChange={(e) =>
                              handleFieldChange(
                                "year_of_establishment",
                                e.target.value
                              )
                            }
                          >
                            <option value="">Year of Establishment</option>
                            {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="url"
                            className={
                              isValid && hasValue && !formData.website
                                ? "border-danger"
                                : ""
                            }
                            id="website"
                            required
                            value={formData?.website}
                            placeholder="website"
                            name="website"
                            onChange={(e) =>
                              handleFieldChange("website", e.target.value)
                            }
                          />
                          <Form.Label className="formLabel" htmlFor="website">
                            Website
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Select
                            id="country"
                            className={`form-control text-capitalize ${isValid && hasValue && !formData.country
                              ? "border-danger"
                              : ""
                              }`}
                            required
                            value={formData?.country}
                            name="country"
                            multiple=""
                            onChange={(e) =>
                              handleFieldChange("country", e.target.value)
                            }
                          >
                            <option value="">country</option>
                            {countries.map((country) => (
                              <option key={country} value={country}>
                                {country}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="text"
                            className={
                              isValid && hasValue && !formData.state
                                ? "border-danger"
                                : ""
                            }
                            id="state"
                            required
                            value={formData?.state}
                            placeholder="state"
                            name="state"
                            onChange={(e) =>
                              handleFieldChange("state", e.target.value)
                            }
                          />
                          <Form.Label className="formLabel" htmlFor="state">
                            State
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="text"
                            className={
                              isValid && hasValue && !formData.city
                                ? "border-danger"
                                : ""
                            }
                            id="city"
                            required
                            value={formData?.city}
                            placeholder="city"
                            name="city"
                            onChange={(e) =>
                              handleFieldChange("city", e.target.value)
                            }
                          />
                          <Form.Label className="formLabel" htmlFor="city">
                            City
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Select
                            id="company_stage"
                            className={`form-control text-capitalize ${isValid && hasValue && !formData.company_stage
                              ? "border-danger"
                              : ""
                              }`}
                            required
                            value={formData?.company_stage}
                            name="company_stage"
                            multiple=""
                            onChange={(e) =>
                              handleFieldChange("company_stage", e.target.value)
                            }
                          >
                            <option value="">Company Stage</option>
                            {companyStage.map((stage) => (
                              <option key={stage} value={stage}>
                                {stage}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Select
                            id="industry"
                            className={`form-control text-capitalize ${isValid && hasValue && !formData.industry
                              ? "border-danger"
                              : ""
                              }`}
                            required
                            value={formData?.industry}
                            name="industry"
                            multiple=""
                            onChange={(e) =>
                              handleFieldChange("industry", e.target.value)
                            }
                          >
                            <option value="">Industry</option>
                            {industries.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col md={12} className="mt-3">
                        <Link
                          to={`/${entityType}/items`}
                          className="btn prev demosEditBtn "
                          onClick={handleNext}
                        >
                          Discard
                        </Link>
                        <Button
                          type="button"
                          className="next demosEditBtn "
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </fieldset>
                )}

                {step === 2 && (
                  <fieldset className="wizard-fieldset demosAddForm">
                    <Row className="mx-0">
                      <Col md={12}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="text"
                            className={
                              isValid && hasValue && !formData.sector
                                ? "border-danger"
                                : ""
                            }
                            id="sector"
                            required
                            value={formData?.sector}
                            placeholder="sector"
                            name="sector"
                            autoFocus
                            onChange={(e) =>
                              handleFieldChange("sector", e.target.value)
                            }
                          />
                          <Form.Label className="formLabel" htmlFor="sector">
                            Sector
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            as={"textarea"}
                            rows={4}
                            className={
                              isValid && hasValue && !formData.description
                                ? "border-danger"
                                : ""
                            }
                            id="description"
                            required
                            value={formData?.description}
                            placeholder="description"
                            name="description"
                            onChange={(e) =>
                              handleFieldChange("description", e.target.value)
                            }
                          />
                          <Form.Label
                            className="formLabel"
                            htmlFor="description"
                          >
                            Description
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Select
                            id="entity_type"
                            className={`form-control text-capitalize ${isValid && hasValue && !formData.entity_type
                              ? "border-danger"
                              : ""
                              }`}
                            required
                            value={formData?.entity_type}
                            name="entity_type"
                            multiple=""
                            onChange={(e) =>
                              handleFieldChange("entity_type", e.target.value)
                            }
                          >
                            <option value="">Business Entity Type</option>
                            <option value="private">Private</option>
                            <option value="public">Public</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            type="number"
                            id="team_size"
                            name="team_size"
                            value={formData?.team_size}
                            placeholder="team size"
                            className="form-control input-md italicInput"
                            onChange={(e) =>
                              handleFieldChange("team_size", e.target.value)
                            }
                          />
                          <Form.Label
                            className="form-label"
                            htmlFor="team_size"
                          >
                            Team Size
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <Form.Group className="form-floating mb-3">
                          <Form.Select
                            id="principle_currency"
                            className={`form-control text-capitalize ${isValid &&
                              hasValue &&
                              !formData.principle_currency
                              ? "border-danger"
                              : ""
                              }`}
                            required
                            value={formData?.principle_currency}
                            name="principle_currency"
                            multiple=""
                            onChange={(e) =>
                              handleFieldChange(
                                "principle_currency",
                                e.target.value
                              )
                            }
                          >
                            <option value="">Principe Currency</option>
                            {currencies.map((currency) => (
                              <option key={currency} value={currency}>
                                {currency}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <AddTag
                          label={"Keywords"}
                          addedTags={formData.keywords}
                          setAddedTags={(tags) => {
                            handleFieldChange("keywords", tags);
                          }}
                        />
                      </Col>
                      <Col md={12}>
                        <Form.Group className="form-floating mb-3">
                          <Form.Control
                            id="video_url"
                            name="video_url"
                            value={formData?.video_url}
                            type="url"
                            placeholder="Video Url"
                            className="form-control input-md italicInput"
                            onChange={(e) =>
                              handleFieldChange("video_url", e.target.value)
                            }
                          />
                          <Form.Label
                            className="form-label"
                            htmlFor="video_url"
                          >
                            Video Url <small>(optional)</small>
                          </Form.Label>
                        </Form.Group>
                      </Col>

                      <hr />
                      <Col
                        md={12}
                        className="d-flex justify-content-between gap-3"
                      >
                        <h6 className="theme-text-2 flex-grow-1">
                          Connect your social accounts
                        </h6>
                        <Button
                          className="clickable add-button mb-3 ms-auto d-flex"
                          onClick={() => {
                            const newField = { type: "", value: "" };
                            setSocialLinks([...socialLinks, newField]);
                          }}
                        >
                          <span className="addBttn d-block">+</span>
                        </Button>
                      </Col>
                      <Col md={12}>
                        {socialLinks?.map((field, index) => (
                          <DynamicRelationFieldEdit
                            key={index}
                            fields={socialLinks}
                            field={field}
                            index={index}
                            onFieldChange={(newFields) => {
                              setSocialLinks(newFields);
                              handleFieldChange("social_links", newFields);
                            }}
                            fieldLabels={{
                              valueInput: "Social Link",
                            }}
                            selectOptions={[
                              { value: "facebook", label: "Facebook" },
                              { value: "instagram", label: "Instagram" },
                              { value: "twitter", label: "Twitter" },
                              { value: "linkedIn", label: "LinkedIn" },
                              { value: "website", label: "Website" },
                              { value: "others", label: "Others" },
                            ]}
                          />
                        ))}
                      </Col>

                      <Col md={12} className="mt-3">
                        <Button
                          type="button"
                          className="previous-button demosEditBtn"
                          onClick={handlePrevious}
                        >
                          Previous
                        </Button>
                        <Button
                          type="button"
                          className="next demosEditBtn "
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </fieldset>
                )}

                {step === 3 && (
                  <fieldset className="wizard-fieldset demosAddForm position-relative overflow-hidden">
                    <Row className="mx-0 position-relative">
                      <Col md={12}>
                        <h6 className="theme-text-2">
                          Management and Founding Team
                        </h6>
                        <hr />
                      </Col>
                      <Col
                        md={12}
                        className="mt-3 d-flex gap-2 flex-wrap gap-2 mb-3"
                      >
                        {formData?.members?.map((member, index) => (
                          <div
                            className="member-profile-chip position-relative"
                            key={index}
                          >
                            <img
                              src={
                                member?.profile_image_url
                                  ? member?.profile_image_url
                                  : member?.hero_image_extension &&
                                    member?.hero_image_content
                                    ? `data:image/${member?.hero_image_extension};base64,${member?.hero_image_content}`
                                    : userImg
                              }
                              loading="lazy"
                              alt=""
                              className="member-thumb rounded-circle object-fit-cover"
                              width="120"
                              height="120"
                            />

                            <h6>{member?.name}</h6>

                            <div className="d-flex gap-2 justify-content-center">
                              <Button
                                className="text-white bg-theme border-0 p-2"
                                onClick={() =>
                                  replicatePerson("members", member?.id)
                                }
                              >
                                <BiCopy />
                              </Button>

                              <Button
                                className="add-button py-1 small"
                                onClick={() => {
                                  setSelectedMemberData(member);
                                  setMemberAddModalShow(true);
                                }}
                              >
                                Edit Profile
                              </Button>
                              <Button
                                className="text-white bg-danger border-0 p-2"
                                onClick={() =>
                                  deletePerson(member?.id, "members")
                                }
                              >
                                <Trash />
                              </Button>
                            </div>
                          </div>
                        ))}

                        <Button
                          className="p-0 bg-transparent add-member-btn"
                          onClick={() => setMemberAddModalShow(true)}
                        >
                          <Plus />
                          <span className="fs-6"> Add new team member</span>
                        </Button>
                      </Col>
                      <Accordion defaultActiveKey={"2"}>
                        {/*  */}
                        <Accordion.Item eventKey="0" className="border-0 my-3">
                          <Accordion.Header className="theme-text-2 border-bottom">
                            <h6 className="theme-text-2 fw-normal">
                              OPPORTUNITY
                            </h6>
                          </Accordion.Header>
                          <Accordion.Body className="px-0">
                            <Form.Group className="form-floating my-3">
                              <Form.Control
                                as={"textarea"}
                                rows={4}
                                id="problem_description"
                                value={formData?.problem_description}
                                placeholder="problem_description"
                                name="problem_description"
                                onChange={(e) =>
                                  handleFieldChange(
                                    "problem_description",
                                    e.target.value
                                  )
                                }
                              />
                              <Form.Label
                                className="formLabel"
                                htmlFor="problem_description"
                              >
                                Provide a brief description of the problem
                                you’re trying to solve
                              </Form.Label>
                            </Form.Group>
                            <Form.Group className="form-floating mb-3">
                              <Form.Control
                                as={"textarea"}
                                rows={4}
                                id="problem_solution"
                                value={formData?.problem_solution}
                                placeholder="problem_solution"
                                name="problem_solution"
                                onChange={(e) =>
                                  handleFieldChange(
                                    "problem_solution",
                                    e.target.value
                                  )
                                }
                              />
                              <Form.Label
                                className="formLabel"
                                htmlFor="problem_solution"
                              >
                                Provide a brief description of your solution to
                                the problem described above
                              </Form.Label>
                            </Form.Group>
                          </Accordion.Body>
                        </Accordion.Item>
                        {/*  */}
                        <Accordion.Item eventKey="1" className="border-0 my-3">
                          <Accordion.Header className="theme-text-2 border-bottom">
                            <h6 className="theme-text-2 fw-normal">
                              GO TO MARKET
                            </h6>
                          </Accordion.Header>
                          <Accordion.Body className="px-0">
                            <Col md={12} className="my-3">
                              <AddTag
                                label={"Type sales channel and hit enter"}
                                addedTags={formData?.sales_channels}
                                setAddedTags={(sales_channel) =>
                                  handleFieldChange(
                                    "sales_channels",
                                    sales_channel
                                  )
                                }
                              />
                            </Col>
                            <Col md={12} className="my-3">
                              <AddTag
                                label={"Type marketing activity and hit enter"}
                                addedTags={formData?.marketing_activities}
                                setAddedTags={(marketing_activity) =>
                                  handleFieldChange(
                                    "marketing_activities",
                                    marketing_activity
                                  )
                                }
                              />
                            </Col>
                          </Accordion.Body>
                        </Accordion.Item>
                        {/*  */}
                        <Accordion.Item eventKey="2" className="border-0 my-3">
                          <Accordion.Header className="theme-text-2 border-bottom">
                            <h6 className="theme-text-2 fw-normal">
                              KEY CUSTOMERS
                            </h6>
                          </Accordion.Header>
                          <Accordion.Body className="px-0">
                            <div className="mt-3 d-flex gap-2 flex-wrap gap-2 mb-3">
                              {formData?.customers?.map((customer, index) => (
                                <div
                                  className="member-profile-chip"
                                  key={index}
                                >
                                  <img
                                    src={
                                      customer?.profile_image_url
                                        ? customer?.profile_image_url
                                        : customer?.hero_image_extension &&
                                          customer?.hero_image_content
                                          ? `data:image/${customer?.hero_image_extension};base64,${customer?.hero_image_content}`
                                          : userImg
                                    }
                                    loading="lazy"
                                    alt=""
                                    className="member-thumb rounded-circle object-fit-cover"
                                    width="120"
                                    height="120"
                                  />

                                  <h6>{customer?.name}</h6>

                                  <div className="d-flex gap-2 justify-content-center">
                                    <Button
                                      className="text-white bg-theme border-0 p-2"
                                      onClick={() =>
                                        replicatePerson(
                                          "customers",
                                          customer?.id
                                        )
                                      }
                                    >
                                      <BiCopy />
                                    </Button>

                                    <Button
                                      className="add-button py-1 small"
                                      onClick={() => {
                                        setSelectedCustomerData(customer);
                                        setCustomerAddModalShow(true);
                                      }}
                                    >
                                      Edit Profile
                                    </Button>

                                    <Button
                                      className="text-white bg-danger border-0 p-2"
                                      onClick={() =>
                                        deletePerson(customer?.id, "customers")
                                      }
                                    >
                                      <Trash />
                                    </Button>
                                  </div>
                                </div>
                              ))}

                              <Button
                                className="p-0 bg-transparent add-member-btn"
                                onClick={() => setCustomerAddModalShow(true)}
                              >
                                <Plus />
                                <span className="fs-6"> Add Key Customer</span>
                              </Button>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        {/*  */}
                        <Accordion.Item eventKey="2" className="border-0 my-3">
                          <Accordion.Header className="theme-text-2 border-bottom">
                            <h6 className="theme-text-2 fw-normal">
                              INVESTORS
                            </h6>
                          </Accordion.Header>
                          <Accordion.Body className="px-0">
                            <div className="mt-3 d-flex gap-2 flex-wrap gap-2 mb-3">
                              {formData?.investors?.map((investor, index) => (
                                <div
                                  className="member-profile-chip"
                                  key={index}
                                >
                                  <img
                                    src={
                                      investor?.profile_image_url
                                        ? investor?.profile_image_url
                                        : investor?.hero_image_extension &&
                                          investor?.hero_image_content
                                          ? `data:image/${investor?.hero_image_extension};base64,${investor?.hero_image_content}`
                                          : userImg
                                    }
                                    loading="lazy"
                                    alt=""
                                    className="member-thumb rounded-circle object-fit-cover"
                                    width="120"
                                    height="120"
                                  />

                                  <h6>{investor?.name}</h6>

                                  <div className="d-flex gap-2 justify-content-center">
                                    <Button
                                      className="text-white bg-theme border-0 p-2"
                                      onClick={() =>
                                        replicatePerson(
                                          "investors",
                                          investor?.id
                                        )
                                      }
                                    >
                                      <BiCopy />
                                    </Button>

                                    <Button
                                      className="add-button py-1 small"
                                      onClick={() => {
                                        setSelectedInvestorData(investor);
                                        setInvestorAddModalShow(true);
                                      }}
                                    >
                                      Edit Profile
                                    </Button>

                                    <Button
                                      className="text-white bg-danger border-0 p-2"
                                      onClick={() =>
                                        deletePerson(investor?.id, "investors")
                                      }
                                    >
                                      <Trash />
                                    </Button>
                                  </div>
                                </div>
                              ))}

                              <Button
                                className="p-0 bg-transparent add-member-btn"
                                onClick={() => setInvestorAddModalShow(true)}
                              >
                                <Plus />
                                <span className="fs-6">Add Investor</span>
                              </Button>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <Col md={12} className="mt-3">
                        <Button
                          type="button"
                          className="previous-button demosEditBtn"
                          onClick={handlePrevious}
                        >
                          Previous
                        </Button>

                        <Button
                          type="button"
                          className="next demosEditBtn "
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </fieldset>
                )}
                {step === 4 && (
                  <fieldset className="wizard-fieldset demosAddForm position-relative overflow-hidden">
                    <h4 className="theme-text-2 text-capitalize">Pitch Deck</h4>
                    <Card className="p-3 my-4">
                      <Card.Body>
                        <Row className="mb-lg-3">
                          <Col lg={3} className="position-relative min-h-full">
                            <Card>
                              <Image
                                className="modal-profile-img position-static rounded"
                                src={Form?.profile_image_url || placehold}
                                alt="image"
                                width={"100%"}
                              />
                            </Card>
                          </Col>
                          <Col lg={9} className="min-h-full">
                            <Row>
                              <Col md={3}>
                                <Card.Text className="mb-0 fw-bold text-capitalize">
                                  Company Name
                                </Card.Text>
                              </Col>
                              <Col md={9}>
                                <Card.Text className="text-muted mb-0 text-capitalize">
                                  {formData?.company_name ?? "Not Available"}
                                </Card.Text>
                              </Col>
                            </Row>
                            <hr />
                            <Row>
                              <Col md={3}>
                                <Card.Text className="mb-0 fw-bold text-capitalize">
                                  Website
                                </Card.Text>
                              </Col>
                              <Col md={9}>
                                <Card.Text className="text-muted mb-0 d-flex align-items-center gap-2">
                                  <a
                                    target="_blank"
                                    href={`${formData.website}`}
                                    rel="noreferrer"
                                    className="text-muted text-truncate d-inline-block w-100 text-overflow-desc-xs"
                                  >
                                    {formData.website}
                                  </a>
                                </Card.Text>
                              </Col>
                            </Row>
                            <hr />
                            <Row>
                              <Col md={3}>
                                <Card.Text className="mb-0 fw-bold text-capitalize">
                                  industry
                                </Card.Text>
                              </Col>
                              <Col md={9}>
                                <Card.Text className="text-muted mb-0">
                                  {formData?.industry ?? "Not Available"}
                                </Card.Text>
                              </Col>
                            </Row>
                            <hr />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            <Card.Text className="mb-0 fw-bold text-capitalize">
                              Description
                            </Card.Text>
                          </Col>
                          <Col md={9}>
                            <Card.Text className="text-muted mb-0">
                              {formData?.description ? (
                                <TextFormatter text={formData?.description} />
                              ) : (
                                "Not Available"
                              )}
                            </Card.Text>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={3}>
                            <Card.Text className="mb-0 fw-bold text-capitalize">
                              company stage
                            </Card.Text>
                          </Col>
                          <Col md={9}>
                            <Card.Text className="text-muted mb-0">
                              {formData?.company_stage ?? "Not Available"}
                            </Card.Text>
                          </Col>
                        </Row>
                        <hr />

                        <Row>
                          <Col md={3}>
                            <Card.Text className="mb-0 fw-bold text-capitalize">
                              sector
                            </Card.Text>
                          </Col>
                          <Col md={9}>
                            <Card.Text className="text-muted mb-0">
                              {formData?.sector ?? "Not Available"}
                            </Card.Text>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          {formData?.members?.length > 0 && (
                            <Col lg={6}>
                              <Card.Text className="mb-0 fw-bold text-capitalize">
                                members:
                              </Card.Text>
                              <ul>
                                {formData?.members?.map((member) => (
                                  <li>{member?.name}</li>
                                ))}
                              </ul>
                            </Col>
                          )}
                          {formData?.customers?.length > 0 && (
                            <Col lg={6}>
                              <Card.Text className="mb-0 fw-bold text-capitalize">
                                Customers:
                              </Card.Text>
                              <ul>
                                {formData?.customers?.map((customer) => (
                                  <li>{customer?.name}</li>
                                ))}
                              </ul>
                            </Col>
                          )}
                          {formData?.investors?.length > 0 && (
                            <Col lg={6}>
                              <Card.Text className="mb-0 fw-bold text-capitalize">
                                Investors:
                              </Card.Text>
                              <ul>
                                {formData?.investors?.map((investor) => (
                                  <li>{investor?.name}</li>
                                ))}
                              </ul>
                            </Col>
                          )}
                        </Row>
                      </Card.Body>
                    </Card>

                    <div className="mt-3">
                      <Button
                        type="button"
                        className="previous-button demosEditBtn"
                        onClick={handlePrevious}
                      >
                        Previous
                      </Button>

                      <Button type="submit" className="next demosEditBtn">
                        Submit
                      </Button>
                    </div>
                  </fieldset>
                )}

                {/* TODO: add  more data later */}
              </div>
            </Form>
          </Container>
        </div>
      )}

      <MemberAddPopup
        show={memberAddModalShow}
        setMemberData={setSelectedMemberData}
        setshow={setMemberAddModalShow}
        memberData={selectedMemberData}
        state={formData}
        setState={setFormData}
        setReqLoading={setReqLoading}
        onHide={() => {
          setMemberAddModalShow(false);
        }}
      />
      <CustomerAddPopup
        fieldAlias={"customer"}
        fieldsAlias={"customers"}
        customerData={selectedCustomerData}
        setCustomerData={setSelectedCustomerData}
        show={customerAddModalShow}
        setshow={setCustomerAddModalShow}
        state={formData}
        setReqLoading={setReqLoading}
        setState={setFormData}
        onHide={() => {
          setCustomerAddModalShow(false);
        }}
      />
      <CustomerAddPopup
        fieldAlias={"investor"}
        fieldsAlias={"investors"}
        customerData={selectedInvestorData}
        setCustomerData={setSelectedInvestorData}
        show={investorAddModalShow}
        setshow={setInvestorAddModalShow}
        state={formData}
        setReqLoading={setReqLoading}
        setState={setFormData}
        onHide={() => {
          setInvestorAddModalShow(false);
        }}
      />
    </>
  );
};

export default withRouter(AddEditStartups);
