import axios from "axios";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as Actions from "../../constants/baseUrl";
import TokenDetails from "../useUserDetails/useTokenDetails";
import { confirmAlert } from "react-confirm-alert";
import { Check2Circle } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { getCookie } from "../../utils/cookies";

const RegistrantsEmailListModal = ({
  show,
  selectedItemDetails,
  registeredEmails,
  onHide,
}) => {
  const myToken = getCookie("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    userId: myUserId,
    type: "emaillist",
    list_name: "",
    list_description: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`${Actions.BASE_URL}emaillists`, formData, {
        headers: {
          Authorization: `Bearer ${myToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const listId = res.data.id;

        const data = registeredEmails.map((registrant) => ({
          userId: myUserId,
          type: "contact",
          lists: [listId],
          entityRelation: [{ emaillist: listId }],
          first_name: registrant.first_name,
          last_name: registrant.last_name,
          email: registrant.email,
        }));

        axios
          .post(
            `${Actions.BASE_URL}contacts/${myUserId}/${listId}/multiple`,
            data,
            {
              headers: {
                Authorization: `Bearer ${myToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            successCampaignAlert();
          })
          .catch((error) => {
            toast.error(
              "something went wrong while adding contacts to email list",
              {
                autoClose: 3000,
                hideProgressBar: true,
                draggable: true,
              }
            );
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        toast.error("something went wrong while creating email list", {
          autoClose: 3000,
          hideProgressBar: true,
          draggable: true,
        });
        setLoading(false);
      });
  };

  const successCampaignAlert = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white py-4 px-2 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h6 className="theme-text-2 text-center mt-2 text-capitalize text-wrap-balance">
              All registrants are successfully added to Email List: <br />{" "}
              <span className="fw-bold">{formData.list_name}</span>
            </h6>
            <button
              className="btn add-button mt-2"
              onClick={() => {
                onHide();
                onClose();
              }}
            >
              Done
            </button>
          </div>
        );
      },
    });
  };

  const handleFieldChange = (fieldName, fieldValue) => {
    const newFormData = {
      ...formData,
      [fieldName]: fieldValue,
    };
    setFormData(newFormData);
  };

  useEffect(() => {
    const currentDate = new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(new Date());
    const newFormData = {
      ...formData,
      list_name: `${selectedItemDetails.title} -  ${currentDate}`,
      list_description: `Registrants' email list created on  ${currentDate}, for the ${
        selectedItemDetails.type
      }: ${selectedItemDetails.title || ""}`,
    };
    setFormData(newFormData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemDetails]);

  return (
    <Modal
      onHide={onHide}
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {loading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay" />
        </>
      ) : null}
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="h6 theme-text text-capitalize"
        >
          Create Email List For Registrants
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="mx-0" onSubmit={(e) => handleSubmit(e)}>
          <Form.Group className="form-floating mb-3">
            <Form.Control
              type="text"
              className="italicInput"
              value={formData?.list_name}
              id="list_name"
              required
              onChange={(e) => handleFieldChange("list_name", e.target.value)}
              placeholder="Enter Event Title"
              name="list_name"
            />
            <Form.Label className="formLabel" htmlFor="list_name">
              Enter your List Name
            </Form.Label>
          </Form.Group>

          <Form.Group className="form-floating mb-3">
            <Form.Control
              as={"textarea"}
              style={{ height: "auto" }}
              rows={8}
              value={formData?.list_description}
              id="list_description"
              onChange={(e) =>
                handleFieldChange("list_description", e.target.value)
              }
              placeholder="Enter Event Title"
              name="list_description"
            />
            <Form.Label className="formLabel" htmlFor="list_description">
              Enter your List Description
            </Form.Label>
          </Form.Group>

          <div className="btn-wrap d-flex align-items-center justify-content-end">
            <Button type="submit" className="add-button text-center">
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export default RegistrantsEmailListModal;
